import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Body2, Button, LinkButton } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { InputField } from '../../components/common/forms';
import { ButtonContainer, Form, FormContainer, formContainerMobileStyles } from '../layoutComponents';
import { Routes } from '../../constants/routes';
import FormError from '../../components/common/forms/FormError';
import { ResetPasswordFormState, resetPasswordSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components/macro';
import { DeviceSize } from '../../components/basic/DeviceSize';
import { sendResetPasswordCode } from '../../apis_kenno';
import { H2 } from '../../components/basic/Text';

const ResetPasswordFormContainer = styled(FormContainer)`
  width: 517px;
  margin: 50px 0 200px 0;

  @media ${DeviceSize.mobileL} {
    ${formContainerMobileStyles}
  }
`;

const ForgotPasswordStep: React.FC<{ onResetCodeSent: (email: string) => void }> = ({ onResetCodeSent }) => {
  const translationPrefix = 'signin';
  const t = useMessageGetter(translationPrefix);
  const [isBusy, setIsBusy] = useState(false);
  const [resetFailed, setResetFailed] = useState(false);

  const formMethods = useForm<ResetPasswordFormState>({ resolver: zodResolver(resetPasswordSchema) });
  const onSubmit: SubmitHandler<ResetPasswordFormState> = async (data) => {
    setResetFailed(false);
    setIsBusy(true);
    try {
      await sendResetPasswordCode(data);
      onResetCodeSent(data.username);
    } catch (error) {
      setResetFailed(true);
      setIsBusy(false);
      console.error('Failed to send email for resetting password', error);
    }
  };

  return (
    <ResetPasswordFormContainer>
      <FormProvider {...formMethods}>
        <H2>{t('forgotPassword')}</H2>
        <Body2>{t('forgotPasswordInfo')}</Body2>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputField fieldPath="username" translationPrefix={translationPrefix} type="email" required dark />
          {resetFailed && <FormError message={t('generalError')} />}
          <ButtonContainer>
            <Button type="submit" id="submit" primary disabled={isBusy}>
              {t('send.button')}
            </Button>
            <LinkButton to={Routes.SIGN_IN}>{t('cancel')}</LinkButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </ResetPasswordFormContainer>
  );
};

export default ForgotPasswordStep;
