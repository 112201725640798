import { EVENTS, ACTIONS } from './appActions';
import { AppState } from './appContext';
import { LOAD_STATES } from './common';

export function appReducer(state: AppState, [type, payload]: [EVENTS | ACTIONS, any?]): AppState {
  switch (type) {
    case ACTIONS.SHOW_NOTIFICATION:
      return {
        ...state,
        notifications: [payload, ...state.notifications],
      };
    case ACTIONS.HIDE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.message !== payload),
      };
    case ACTIONS.LOAD_MENU:
      return {
        ...state,
        menu: { ...state.menu, loadState: LOAD_STATES.BUSY },
      };
    case EVENTS.LOAD_MENU_SUCCESS:
      return {
        ...state,
        menu: { ...state.menu, ...payload, loadState: LOAD_STATES.READY },
      };
    case EVENTS.LOAD_MENU_FAILURE:
      return {
        ...state,
        menu: { ...state.menu, loadState: LOAD_STATES.ERROR },
      };

    case ACTIONS.SET_LANGUAGE:
      return {
        ...state,
        language: payload,
      };

    default:
      console.log('Unknown event', type);
      return state;
  }
}
