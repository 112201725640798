import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const baseFontStyle = css`
  font-family: ${(props) => props.theme.text.primaryFont};
  color: ${(props) => props.theme.text.fontColor};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-feature-settings: 'ss01';
`;

export const Label1 = styled.p`
  ${baseFontStyle};
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
`;

export const Label2 = styled.p`
  ${baseFontStyle};
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
`;

export const Body2 = styled.p`
  ${baseFontStyle};
  font-size: 16px;
  line-height: 150%;
`;

export const Body3 = styled.p`
  ${baseFontStyle};
  font-size: 14px;
  line-height: 150%;
  margin: 0;
`;

export const Body4 = styled.p`
  ${baseFontStyle};
  font-size: 12px;
  line-height: 150%;
  margin: 0;
`;

export const BasicText = styled.span`
  font-family: ${(props) => props.theme.text.primaryFont};
  color: ${(props) => props.theme.text.fontColor};
  font-size: 16px;
  line-height: 150%;
`;

export const H6 = styled.h6`
  ${baseFontStyle};
  font-family: ${(props) => props.theme.text.secondaryFont};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
`;

const textButtonStyles = css`
  background: none;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  font-size: 16px;
  font-family: ${(props) => props.theme.text.primaryFont};
`;

export const TextButton = styled.button`
  ${textButtonStyles}
`;

export const TextLink = styled(Link)`
  ${textButtonStyles}
  color: ${(props) => props.theme.text.fontColor};
`;

export const TextLinkExternal = styled.a`
  ${textButtonStyles}
  color: ${(props) => props.theme.text.fontColor};
`;
