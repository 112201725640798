import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../../components/basic/DeviceSize';
import { FlexColumn } from '../../components/basic/Flex';

type CardGridProps = {
  maxHeight?: React.CSSProperties['maxHeight'];
};

const Grid = styled(FlexColumn)<{ maxHeight: React.CSSProperties['maxHeight'] }>`
  flex-wrap: wrap;
  max-height: ${(props) => props.maxHeight};
  width: 100%;
  gap: 20px;

  > div {
    width: calc(33% - 9px);
    margin-bottom: 12px;
  }
  @media ${DeviceSize.desktopM} {
    max-height: 1800px;

    > div {
      max-height: calc(${(props) => props.maxHeight} * 1.3);
      width: calc(50% - 18px);
    }
  }

  @media ${DeviceSize.desktopS} {
    max-height: initial;

    > div {
      width: 100%;
    }
  }
`;

const CardGrid: React.FunctionComponent<CardGridProps> = ({ maxHeight = '600px', children }) => {
  return <Grid maxHeight={maxHeight}>{children}</Grid>;
};

export default CardGrid;
