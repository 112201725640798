const TENANCY = '/vuokrani';
const PAYMENT_INFO = '/maksutiedot';
const ADMIN = '/hallinta';
const LIVING = '/asuminen';
const RESIDENT_ACTIVITY = '/asukastoiminta';

export const Routes = {
  HOME: '/',
  SIGN_IN: '/kirjaudu',
  RESET_PASSWORD: '/unohtunut-salasana',
  SIGN_UP: '/rekisteroidy',
  //BILLING: `/${PAYMENT_INFO}`, // TODO: find out why this redirects to old login page but the one below works
  BILLING: `/maksutiedot`,
  FEEDBACK: '/palaute',
  APARTMENT_CHANGE_APPROVAL: '/vaihtoilmoitukset/:id/:secret',
  APARTMENT_SWAP: '/asuminen/asunnonvaihto/vaihto',
  PROFILE: '/omat-tiedot',
  SEARCH: '/haku/:query',
  Tenancy: {
    PAYMENT_SUCCESS: `${TENANCY}${PAYMENT_INFO}/onnistui`,
    PAYMENT_ERROR: `${TENANCY}${PAYMENT_INFO}/virhe`,
  },
  Administrative: {
    HOME: ADMIN,
  },

  ResidentActivity: {
    MEETING_MINUTES: `${LIVING}${RESIDENT_ACTIVITY}/kokouspoytakirja`,
    ACTION_PLAN: `${LIVING}${RESIDENT_ACTIVITY}/toimintasuunnitelma`,
    ACTION_REPORT: `${LIVING}${RESIDENT_ACTIVITY}/toimintakertomus`,
    EXPENSE_REPORT: `${LIVING}${RESIDENT_ACTIVITY}/kuluselvitys`,
    TRAVEL_EXPENSE_REPORT: `${LIVING}${RESIDENT_ACTIVITY}/matkalasku`,
    EVENT_INVITE: `${LIVING}${RESIDENT_ACTIVITY}/tapahtumakutsu`,
  },
};
