import React, { FunctionComponent } from 'react';
import { theme } from '../../theme';
import styled from 'styled-components/macro';
import { DeviceSize } from '../basic/DeviceSize';
import { FlexColumn, FlexRow } from '../basic/Flex';

export type CardProps = {
  id?: string;
  title: string;
  Pictogram?: React.ComponentType;
  background?: React.CSSProperties['background'];
  contentPadding?: React.CSSProperties['padding'];
  mainButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
};

const MainContainer = styled(FlexColumn)<{ background: React.CSSProperties['background'] }>`
  border-radius: 35px;
  font-family: ${(props) => props.theme.text.thirdFont};
  background: ${(props) => props.background};
  overflow: hidden;
  justify-content: space-between;
`;

const TopRow = styled(FlexRow)`
  justify-content: space-between;
  padding: 24px 24px 0;
`;

const TitleContainer = styled.div`
  svg {
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
  }
`;

const Title = styled.h4`
  font-weight: 400;
  font-size: 21px;
  display: flex;
  align-items: center;
  margin: 0;
  width: 50%;

  @media ${DeviceSize.mobileL} {
    font-size: 18px;
  }
`;

const CenterContainer = styled.div<{ padding: React.CSSProperties['padding'] }>`
  padding: ${(props) => props.padding};
  flex-grow: 1;

  > * {
    // Inline elements leave some space below them for the font descender.
    // This vertical-align gets rids of that space, allowing content such as images to use the full space.
    vertical-align: top;
    max-width: 100%;
    max-height: 100%;
  }
`;

const BottomRow = styled(FlexRow)`
  align-items: center;
  padding: 0 24px 24px;
`;

/**
 * Card component. Provides a container with a background, title, optional buttons at the top and bottom and arbitrary
 * content in the center.
 *
 * Example use for full-size image card with title and button on top right:
    <Card
      title="Building map"
      background={theme().colors.strawberry}
      contentPadding="16px 0 0"
    >
      <img src={mapFile} alt="" style={{ height: '200px', width: '100%', objectFit: 'cover' }} />
    </Card>
    *
 * Example use for card with buttons at the top and bottom:
    <Card
      title="Messages"
      background={theme().colors.darkSnow}
    >
      <MessageList />
    </Card>
 *
 */
const Card: FunctionComponent<CardProps> = ({
  id,
  children,
  background = theme().colors.darkSnow,
  Pictogram,
  title,
  contentPadding = '16px 24px',
  mainButton,
  secondaryButton,
}) => {
  return (
    <MainContainer id={id} background={background}>
      <TopRow>
        <TitleContainer>
          {Pictogram && <Pictogram />}
          <Title>{title}</Title>
        </TitleContainer>
        {mainButton}
      </TopRow>
      <CenterContainer padding={contentPadding}>{children}</CenterContainer>
      {secondaryButton && <BottomRow>{secondaryButton}</BottomRow>}
    </MainContainer>
  );
};

export default Card;
