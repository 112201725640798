import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from './basic/DeviceSize';
import { defaultBuilding } from '../assets';
import { useMessageGetter } from 'react-message-context';
import { useKennoTenantState } from '../context/kennoTenantContext';
import { LOAD_STATES } from '../context/common';
import Loader from './basic/Loader';

const Liftup: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { apartment } = useKennoTenantState();
  const t = useMessageGetter('housing.altText');

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageAltText, setImageAltText] = useState<string>('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    // Don't show anything if we're still waiting for apartment data
    if (apartment.loadState === LOAD_STATES.BUSY) {
      setImageSrc(null);
      setIsImageLoaded(false);
      return;
    }

    const apartmentImages = apartment.data?.images ?? [];
    if (apartmentImages.length === 0) {
      // Load is complete but there are no images. Fall back to default image and no alt text
      setImageSrc(defaultBuilding);
      setImageAltText('');
      setIsImageLoaded(true);
      return;
    }

    // Load is commplete and images were found. The first one in Kenno is always the main one, "pääkuva".
    const mainImage = apartmentImages[0];
    // The image may be highres and take a moment to load. Preload it and render after completion.
    const img = new Image();
    img.src = mainImage;
    img.onload = () => {
      setImageSrc(mainImage);
      setImageAltText(t('tenantBuilding'));
      setIsImageLoaded(true);
    };
  }, [apartment.loadState, apartment.data, t]);

  return (
    <LiftupContainer>
      <ContentContainer>{children}</ContentContainer>
      {!isImageLoaded ? <Loader /> : imageSrc && <ImageNode src={imageSrc} alt={imageAltText} />}
    </LiftupContainer>
  );
};

const LiftupContainer = styled.div`
  position: relative;
  width: 100%;
  height: 440px;
  border-radius: 32px;
  background-color: white;
  display: flex;
  overflow: hidden;

  @media ${DeviceSize.desktopS} {
    height: 870px;
    flex-direction: column-reverse;
  }

  @media ${DeviceSize.mobileL} {
    height: 620px;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  background-color: white;
  display: flex;
  padding: 32px;

  @media ${DeviceSize.desktopS} {
    width: 100%;
    height: 420px;
    padding: 16px;
  }

  @media ${DeviceSize.mobileL} {
    height: 370px;
    padding: 16px;
  }
`;

const ImageNode = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;

  @media ${DeviceSize.desktopS} {
    width: 100%;
    height: 450px;
  }

  @media ${DeviceSize.mobileL} {
    height: 250px;
  }
`;

export default Liftup;
