import { AuthenticatedRouteComponent } from '../AuthenticatedRoute';
import { Slugs } from './knownSlugs';
// import BuildingInfoPage from '../pages/BuildingInfoPage';
import AgreementPage from '../pages/AgreementPage';
// import ApartmentChangePage from '../pages/ApartmentChangePage';
// import HousingBookingsCalendar from '../pages/HousingBookingsCalendar';
import PaymentPage from '../pages/PaymentPage';
// import DebtPage from '../pages/DebtPage';
// import HousingManualsPage from '../pages/HousingManualsPage';
// import MessagesPage from '../pages/MessagesPage';
// import ApartmentNewApplicationPage from '../pages/ApartmentNewApplicationPage';
import BillingPage from '../pages/billing/BillingPage';
import ContactPage from '../pages/ContactPage';

/* The key is a WordPress page slug for a page containing React content.
  Every page visible in the menu has at least a placeholder page in WordPress.
  If a menu item fetched from WordPress has a slug present in this list, we map
  it to the corresponding React component instead of using general WordPressPage component. */
export const reactPageSlugs: { [key: string]: AuthenticatedRouteComponent } = {
  // Vuokrani
  [Slugs.AGREEMENT]: AgreementPage,
  [`${Slugs.AGREEMENT}-eng`]: AgreementPage,
  [Slugs.BILLING]: BillingPage,
  [`${Slugs.BILLING}-eng`]: BillingPage,
  [Slugs.PAYMENT]: PaymentPage,
  [`${Slugs.PAYMENT}-eng`]: PaymentPage,
  // [Slugs.DEBT]: DebtPage,
  // [`${Slugs.DEBT}-eng`]: DebtPage,
  // Varaukseni
  // [Slugs.BOOKING_CALENDAR]: HousingBookingsCalendar,
  // [`${Slugs.BOOKING_CALENDAR}-eng`]: HousingBookingsCalendar,
  // Taloni
  // [Slugs.BUILDING_INFO]: BuildingInfoPage,
  // [`${Slugs.BUILDING_INFO}-eng`]: BuildingInfoPage,
  // [Slugs.INSTRUCTIONS_DOCUMENTS]: HousingManualsPage,
  // [`${Slugs.INSTRUCTIONS_DOCUMENTS}-eng`]: HousingManualsPage,
  // Asuminen
  // [Slugs.FAULT_REPORT]: MessagesPage,
  // [`${Slugs.FAULT_REPORT}-eng`]: MessagesPage,
  // [Slugs.APARTMENT_CHANGE]: ApartmentChangePage,
  // [`${Slugs.APARTMENT_CHANGE}-eng`]: ApartmentChangePage,
  // [Slugs.NEW_APPLICATION]: ApartmentNewApplicationPage,
  // [`${Slugs.NEW_APPLICATION}-eng`]: ApartmentNewApplicationPage,
  // Ota yhteyttä
  [Slugs.CONTACT_INFO]: ContactPage,
  [`${Slugs.CONTACT_INFO}-eng`]: ContactPage,
};
