import React, { useEffect } from 'react';
import { theme } from '../../theme';
import Card from '../../components/common/Card';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { H6 } from '../../components/common/Text';
import { FlexColumn } from '../../components/basic/Flex';
import { LinkButton } from '../../components/common';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { Slugs } from '../../constants/knownSlugs';
import { useApp } from '../../context/appContext';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loadBuilding } from '../../context/kennoTenantActions';

const Divider = styled.div`
  border-bottom: 1px solid #000000;
  margin: 8px 0;
  width: 100%;
`;

const TextContainer = styled.div`
  margin: 8px 0 16px;
  p {
    font-family: ${(props) => props.theme.text.primaryFont};
    margin: 0;
  }
  a {
    color: ${(props) => props.theme.text.fontColor};
  }
`;

const ContactCard: React.FunctionComponent = () => {
  const t = useMessageGetter('');
  const tContact = useMessageGetter('home.grid.contact');
  const [{ building }, dispatch] = useKennoTenant();
  const [{ menu }] = useApp();

  useEffect(() => {
    loadBuilding(dispatch);
  }, [dispatch]);

  const emergencyNumber = building.maintenanceCompany?.emergencyNumber;

  return (
    <Card
      title={tContact('title')}
      background={theme().colors.apila}
      mainButton={
        <LinkButton
          size="small"
          background="transparent"
          to={getMenuItemPathBySlug(menu.items, Slugs.CONTACT_INFO)}
          iconRight
          icon="arrowForward"
        >
          {tContact('action.label')}
        </LinkButton>
      }
    >
      <FlexColumn>
        <H6>{tContact('customer_service')}</H6>
        <Divider />
        <TextContainer>
          <p>
            {t('housing.contacts.customerservicename')}{' '}
            <a
              href={`mailto: ${t('footer.housing.customerservice.email')}`}
              aria-label={t('housing.contacts.email.customerservice.aria')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.housing.customerservice.email')}
            </a>
          </p>
          <p>
            {t('housing.contacts.phonenumber')}&nbsp;
            <a
              href={`tel: ${t('footer.housing.customerservice.phone')}`}
              aria-label={t('housing.contacts.phone.customerservice.aria')}
            >
              {t('footer.housing.customerservice.phone')}
            </a>
          </p>
          <p>{tContact('customer_service_active', { hour_range: '9-15' })}</p>
        </TextContainer>
        <H6>{tContact('maintenance')}</H6>
        <Divider />
        <TextContainer>
          {/*             <p>{building.maintenanceCompany?.name}</p>
            <p>
              <a href={`mailto:${housing.maintenance.companyEmail}`}>{housing.maintenance.companyEmail}</a>
            </p>
            <p>
              {t('housing.contacts.phonenumber')}&nbsp;
              <a href={`tel:${housing.maintenance.companyPhone}`}>{housing.maintenance.companyPhone}</a>
            </p> */}
          {emergencyNumber && (
            <p>
              <a href={`tel: ${emergencyNumber}`} aria-label={t('housing.contacts.emergencyphone.maintenance.aria')}>
                {`${t('housing.contacts.emergency')}: ${t('housing.contacts.phonenumber')} ${emergencyNumber}`}
              </a>
            </p>
          )}
        </TextContainer>
        <H6>{tContact('manager')}</H6>
        <Divider />
        <TextContainer>
          <p>{t('housing.contacts.descriptionAbove')}</p>
          {/*             <p>{housing.manager.personName}</p>
            <p>
              <a href={`mailto:${housing.manager.personEmail}`}>{housing.manager.personEmail}</a>
            </p> */}
        </TextContainer>
      </FlexColumn>
    </Card>
  );
};

export default ContactCard;
