import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { FileIcon, QuestionIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { DropdownOptionValue } from '../../../components/common/Dropdown';
import {
  FileInput,
  FileInputValue,
  FormActions,
  InputField,
  TextAreaElement,
  TimeDropdown,
} from '../../../components/common/forms';
import { scrollToTop } from '../../../utils';
import { FormPageContainer } from '../FormPageContainer';
import { Column, ContentBlock, Form, FormRow, GridContainer, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { InfoPopup } from './InfoPopup';
import { schema, TravelExpenseReportFormState } from './schema';
import { TravelExpenseReportPreview } from './TravelExpenseReportPreview';

const ContactInfoContent = styled(ContentBlock)``;
const EventContent = styled(ContentBlock)``;
const ExpenseReportsContent = styled(ContentBlock)``;

const OwnCarInfoText = styled.span`
  display: inline-flex;
  text-decoration: underline;
  cursor: pointer;
  gap: 6px;
`;

export const TravelExpenseReport = ({ history }: RouteComponentProps) => {
  const formMethods = useForm<TravelExpenseReportFormState>({
    resolver: zodResolver(schema),
  });

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isOwnCarInfoPopupOpen, setIsOwnCarInfoPopupOpen] = useState<boolean>(false);

  const t = useMessageGetter('forms.travelExpenseReport');

  const {
    fields: publicExpenseFields,
    append: publicExpenseAppend,
    remove: publicExpenseRemove,
  } = useFieldArray({
    control: formMethods.control,
    name: 'publicExpenseReports',
  });

  const handleAddNewPublicTransportationExpense = () => {
    publicExpenseAppend({
      amount: 0.0,
      additionalInformation: '',
      vehicle: '',
      // This typecasting is a workaround for typescript error stating that receipt can't be undefined
      // This works differently than useForm's defaultValues in which the values can be null or undefined.
      // Unfortunately append function is strictly following the schema in which the values are required.
      // Discussion about the flaw here: https://github.com/orgs/react-hook-form/discussions/10211
      date: undefined as unknown as string,
      time: undefined as unknown as string,
      receipt: undefined as unknown as FileList,
    });
  };

  const {
    fields: ownCarExpenseFields,
    append: ownCarExpenseAppend,
    remove: ownCarExpenseRemove,
  } = useFieldArray({
    control: formMethods.control,
    name: 'ownCarExpenseReports',
  });

  const publicExpenseReports = formMethods.watch('publicExpenseReports');

  const handleAddNewOwnTransportationExpense = () => {
    ownCarExpenseAppend({
      additionalInformation: '',
      numberOfPeople: 0,
      totalKm: 0,
      // This typecasting is a workaround for typescript error stating that receipt can't be undefined
      // This works differently than useForm's defaultValues in which the values can be null or undefined.
      // Unfortunately append function is strictly following the schema in which the values are required.
      // Discussion about the flaw here: https://github.com/orgs/react-hook-form/discussions/10211
      date: undefined as unknown as string,
      time: undefined as unknown as string,
    });
  };

  const handleEmptyForm = () => {
    formMethods.reset();
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
    scrollToTop();
  };

  const handleOnClickOwnCarInfo = () => {
    setIsOwnCarInfoPopupOpen(true);
  };

  const handleCloseOwnCarInfo = () => {
    setIsOwnCarInfoPopupOpen(false);
  };

  const handleSave = () => {
    console.log('TODO: save');
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleSubmit = (e: any) => {
    console.log('TODO: onSubmit', e);
  };

  const translationPrefix = 'forms.travelExpenseReport';

  return (
    <FormPageContainer<TravelExpenseReportFormState> history={history} formMethods={formMethods}>
      {previewOpen ? (
        <TravelExpenseReportPreview closePreview={handleClosePreview} />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <H2>{t('title')}</H2>
          <span>{t('description')}</span>
          <ContactInfoContent>
            <H3>{t('contactInfo.title')}</H3>
            <div>
              <FormRow>
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.name"
                  type="text"
                  required
                />
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.ssn"
                  type="text"
                  required
                />
              </FormRow>
              <InputField<TravelExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="contactInfo.address"
                type="text"
                required
              />
              <FormRow>
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.zip"
                  type="text"
                  required
                />
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.city"
                  type="text"
                  required
                />
              </FormRow>
              <FormRow>
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.phone"
                  type="text"
                  required
                />
                <InputField<TravelExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.email"
                  type="text"
                  required
                />
              </FormRow>
              <InputField<TravelExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="contactInfo.iban"
                type="text"
                required
              />
            </div>
          </ContactInfoContent>
          <EventContent>
            <H3>{t('event.title')}</H3>
            <div>
              <InputField<TravelExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="event.name"
                type="text"
                required
              />
              <InputField<TravelExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="event.location"
                type="text"
                required
              />
              <InputField<TravelExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="event.travelRoute"
                type="text"
                required
              />
            </div>
          </EventContent>
          <ExpenseReportsContent>
            <H3>{t('reports.title')}</H3>
            <span>{t('reports.description')}</span>
            <div>
              {publicExpenseFields.map((field, index) => {
                const fileName = publicExpenseReports?.[index]?.receipt?.[0].name;
                return (
                  <div key={field.id} style={{ marginBottom: '1rem' }}>
                    <GridContainer columnGap="20px" columns={2} mdColumns={1}>
                      <InputField<TravelExpenseReportFormState, string>
                        fieldPath={`publicExpenseReports.${index}.date`}
                        translationPrefix={translationPrefix}
                        type="date"
                        required
                      />
                      <InputField<TravelExpenseReportFormState, DropdownOptionValue>
                        translationPrefix={translationPrefix}
                        fieldPath={`publicExpenseReports.${index}.time`}
                        inputElementRenderer={(props) => <TimeDropdown {...props} />}
                      />
                    </GridContainer>
                    <GridContainer columnGap="20px" columns={5} mdColumns={1}>
                      <Column span={4} mdSpan={1}>
                        <InputField<TravelExpenseReportFormState, string>
                          translationPrefix={translationPrefix}
                          fieldPath={`publicExpenseReports.${index}.vehicle`}
                          type="text"
                          required
                        />
                      </Column>
                      <Column>
                        <InputField<TravelExpenseReportFormState, string>
                          translationPrefix={translationPrefix}
                          fieldPath={`publicExpenseReports.${index}.amount`}
                          required
                          type="number"
                          step="0.01"
                        />
                      </Column>
                    </GridContainer>
                    <FormRow>
                      <InputField<TravelExpenseReportFormState, FileInputValue>
                        translationPrefix={translationPrefix}
                        hideLabel
                        fieldPath={`publicExpenseReports.${index}.receipt`}
                        inputElementRenderer={(props) => (
                          <FormRow>
                            <FileInput onChange={props.onChange} label={t('addReceipt')} />
                            {fileName && (
                              <Row style={{ alignItems: 'center' }}>
                                <FileIcon style={{ flexShrink: 0 }} />
                                <TextBlock>{fileName}</TextBlock>
                              </Row>
                            )}
                          </FormRow>
                        )}
                      />
                    </FormRow>
                    <FormRow style={{ marginBottom: '10px' }}>{t('publicExpenseReports.receiptInfo')}</FormRow>
                    <InputField<TravelExpenseReportFormState, string>
                      translationPrefix={translationPrefix}
                      fieldPath={`publicExpenseReports.${index}.additionalInformation`}
                      inputElementRenderer={(props) => <TextAreaElement {...props} />}
                    />

                    {!!publicExpenseFields.length && (
                      <Button style={{ marginTop: '24px' }} primary onClick={() => publicExpenseRemove(index)}>
                        {t('reports.remove')}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
            <FormRow>
              <Button type="button" icon="plus" onClick={handleAddNewPublicTransportationExpense}>
                {t('reports.addPublicTransportationExpense')}
              </Button>
            </FormRow>

            {ownCarExpenseFields.map((field, index) => {
              return (
                <div key={field.id} style={{ marginBottom: '1rem' }}>
                  <GridContainer columnGap="20px" columns={2} mdColumns={1}>
                    <InputField<TravelExpenseReportFormState, string>
                      fieldPath={`ownCarExpenseReports.${index}.date`}
                      translationPrefix={translationPrefix}
                      type="date"
                      required
                    />
                    <InputField<TravelExpenseReportFormState, DropdownOptionValue>
                      translationPrefix={translationPrefix}
                      fieldPath={`ownCarExpenseReports.${index}.time`}
                      inputElementRenderer={(props) => <TimeDropdown {...props} />}
                    />
                  </GridContainer>
                  <GridContainer columnGap="20px" columns={3} mdColumns={3}>
                    <Column>
                      <InputField<TravelExpenseReportFormState, string>
                        translationPrefix={translationPrefix}
                        fieldPath={`ownCarExpenseReports.${index}.numberOfPeople`}
                        type="number"
                        required
                      />
                    </Column>
                    <Column>
                      <InputField<TravelExpenseReportFormState, string>
                        translationPrefix={translationPrefix}
                        fieldPath={`ownCarExpenseReports.${index}.totalKm`}
                        required
                        type="number"
                        step="0.01"
                      />
                    </Column>
                  </GridContainer>
                  <FormRow style={{ marginBottom: '10px' }}>
                    <span>
                      {t('ownCarExpenseReports.compensationInfo')}{' '}
                      <a href="https://vero.fi/henkiloasiakkaat/vahennykset/kilometrikorvaus-ja-paivaraha/">vero.fi</a>.
                    </span>
                  </FormRow>
                  <InputField<TravelExpenseReportFormState, string>
                    translationPrefix={translationPrefix}
                    fieldPath={`ownCarExpenseReports.${index}.additionalInformation`}
                    inputElementRenderer={(props) => <TextAreaElement {...props} />}
                  />
                  <Button style={{ marginTop: '24px' }} primary onClick={() => ownCarExpenseRemove(index)}>
                    {t('reports.remove')}
                  </Button>
                </div>
              );
            })}

            <FormRow style={{ alignItems: 'center', position: 'relative' }}>
              <Button type="button" icon="plus" onClick={handleAddNewOwnTransportationExpense}>
                {t('reports.addOwnTransportationExpense')}
              </Button>
              <OwnCarInfoText onClick={handleOnClickOwnCarInfo}>
                <QuestionIcon />
                {t('info')}
              </OwnCarInfoText>
              {isOwnCarInfoPopupOpen && (
                <InfoPopup
                  subject={t('ownCarInfoPopup.subject')}
                  description={t('ownCarInfoPopup.description')}
                  onClose={handleCloseOwnCarInfo}
                  visible={isOwnCarInfoPopupOpen}
                  width="403px"
                  bottom="55px"
                  right="0px"
                />
              )}
            </FormRow>
          </ExpenseReportsContent>
          <FormActions
            handleEmptyForm={handleEmptyForm}
            handleOpenPreview={handleOpenPreview}
            handleSave={handleSave}
            actionButtonType="send"
          />
        </Form>
      )}
    </FormPageContainer>
  );
};
