// React
import React, { Fragment, useEffect, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import ReactGA from 'react-ga';
//import dayjs from 'dayjs';

// Components
import { baseFontStyle, H1, BasicParagraph, BasicTextLegacy } from '../../components/basic/Text';
import Loader from '../../components/basic/Loader';
import { BillingPeriod, BillRow, PaymentRow } from '../../components/billing/BillingPeriod';
import { ButtonLegacy, InternalLinkButton } from '../../components/button/ButtonLegacy';
import { SimpleSelect } from '../../components/input/Input';
import PaddedContent from '../../components/housing/PaddedContent';
//import AgreementInfo from '../../components/basic/AgreementInfo';
import { DeviceSize } from '../../components/basic/DeviceSize';

// Context
import { NotificationType, useAppDispatch, useAppState } from '../../context/appContext';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { KENNO_TENANT_ACTIONS, loadBillingHistory } from '../../context/kennoTenantActions';

// Constants
//import { AgreementTypes } from '../../constants/agreementTypes';

import { whitePrintIcon } from '../../assets';
import { LOAD_STATES } from '../../context/common';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { Slugs } from '../../constants/knownSlugs';
import { showNotification } from '../../context/appActions';
import { MaintenanceNote } from '../../components/FinancialInfoMaintenanceNote';
import { shouldHideFinancialInfo } from '../../utils';

const BillingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// const AgreementSection = styled.div`
//   display: none;
//   margin-bottom: 20px;
//   border-top: 1px solid ${(props) => props.theme.colors.charcoal};
//   padding-top: 20px;

//   @media print {
//     display: block;
//   }
// `;

const ArrowIcon = styled.span`
  position: relative;
  margin-left: 10px;
  top: 1px;
`;

const Content = styled(PaddedContent)`
  #printlabel {
    margin-bottom: 30px;
  }
`;

const ButtonContent = styled(PaddedContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -60px;

  @media ${DeviceSize.desktopS} {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > * {
    margin: 0 10px 0 0 !important;
  }

  @media ${DeviceSize.desktopS} {
    flex-direction: column;

    > * {
      margin: 0 0 10px 0 !important;
    }
  }
`;

const Controls = styled(PaddedContent)`
  margin-bottom: 40px;

  @media ${DeviceSize.desktopM} {
    margin: 0;
    order: 2;
  }

  > .parkinglotbtn {
  }

  @media print {
    display: none;
  }
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;

  @media ${DeviceSize.desktopM} {
    flex-direction: column;
    width: 100%;
  }
`;

const PaymentButton = styled(InternalLinkButton)`
  margin-left: 10px;

  @media ${DeviceSize.desktopM} {
    margin: 12px 0 0 0;
    width: 100%;
  }
`;

const FormRow = styled(PaddedContent)`
  display: flex;
  flex: 1;

  > * {
    margin: 0 20px 0 0;
  }

  @media ${DeviceSize.desktopM} {
    flex-direction: column;

    > * {
      margin: 0;
    }
  }
`;

const FilterDateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.mint};
  padding-bottom: 40px;
`;

const FilterButtonContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const FilterButton = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.coal};
  box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  border-radius: 24px;
  padding: 12px 24px;
  margin: 4px 4px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: ${(props) => props.theme.text.primaryFont};
  font-weight: 600;

  :hover {
    background: ${(props) => props.theme.colors.mint};
    color: ${(props) => props.theme.colors.coal};
    cursor: pointer;
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }

  &.active {
    background-color: ${(props) => props.theme.colors.charcoal};
    color: ${(props) => props.theme.colors.white};
  }

  &.show-focus {
    outline: 2px solid Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

const TableContent = styled(PaddedContent)`
  padding-top: 0px;
`;

const FilterFormWrapper = styled.div`
  @media ${DeviceSize.desktopM} {
    order: 3;
  }
`;

const FilterWrapper = styled.div`
  padding-bottom: 40px;

  @media print {
    display: none;
  }
`;

const TableWrapper = styled.div`
  background: ${(props) => props.theme.colors.peppermintVeryLight};

  @media ${DeviceSize.mobileL} {
    background: ${(props) => props.theme.colors.white};
  }

  @media ${DeviceSize.desktopM} {
    order: 1;
  }

  @media print {
    background: none;
  }
`;

const ActionButton = styled(ButtonLegacy)`
  margin: auto 0;

  @media ${DeviceSize.desktopM} {
    width: 100%;
  }

  @media ${DeviceSize.mobileL} {
    margin-bottom: 10px;
  }
`;

const ButtonLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  + img {
    padding-left: 10px;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 17px;
  }
`;

const FormLabel = styled.div`
  margin-bottom: 12px;
  ${baseFontStyle};
  font-family: ${(props) => props.theme.text.secondaryFont};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 1.5;
  display: block;

  @media ${DeviceSize.desktopM} {
    font-family: ${(props) => props.theme.text.primaryFont};
    font-weight: 400;
    letter-spacing: initial;
    font-size: 17px;
    margin-bottom: 6px;
    margin-top: 26px;
  }
`;

// const PendingPaymentNoticeBox = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const PendingPaymentIcon = styled.img`
//   position: relative;
//   margin-right: 20px;
//   top: 2px;
// `;

const BillingHistory = () => {
  const t = useMessageGetter('billing');
  const msgHistory = useMessageGetter('billing.history');
  const msgCurrent = useMessageGetter('billing.current');

  const [{ billingHistory }, dispatch] = useKennoTenant();
  const { menu } = useAppState();
  const appDispatch = useAppDispatch();

  const [billFilter, setBillFilter] = useState('all');

  useEffect(() => {
    loadBillingHistory(dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (billingHistory.loadState === LOAD_STATES.ERROR) {
      showNotification(appDispatch, t('historyloadfailure'), NotificationType.ERROR);
    }
  }, [billingHistory.loadState]); // eslint-disable-line react-hooks/exhaustive-deps

  // Note: temporary implementation, only for Kenno launch, can be removed once
  // Y-Säätiö has confirmed financials in Kenno are correct
  if (shouldHideFinancialInfo()) {
    return (
      <PaddedContent>
        <H1>{t('title')}</H1>
        <MaintenanceNote translationKey="financial.generic" />
      </PaddedContent>
    );
  }

  function billingGAEvent(action: string, label: string = '/maksutiedot') {
    ReactGA.event({
      category: 'Billing',
      action,
      label,
    });
  }

  // const showDebtPage = () => {
  //   billingGAEvent('Show debt page', 'Warning banner/Billing page');
  // };

  function changeFilter(evt: string) {
    billingGAEvent('Change filter', evt);
    dispatch([KENNO_TENANT_ACTIONS.SET_BILLING_FILTER, evt]);

    setBillFilter(evt);
  }

  function changeRangeStart(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch([KENNO_TENANT_ACTIONS.SET_BILLING_RANGE_START, evt.target.value]);
  }

  function changeRangeEnd(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch([KENNO_TENANT_ACTIONS.SET_BILLING_RANGE_END, evt.target.value]);
  }

  // function printInvoice() {
  //   signAwsUrl(AWSEndpoints.BILLING_INVOICE, (signedUrl) => {
  //     window.open(signedUrl);
  //     billingGAEvent('Print invoice');
  //   });

  //   return false;
  // }

  // function formatPaymentDateTime(timestamp: string) {
  //   const lang = sessionStorage.getItem('currentLanguage');
  //   switch (lang) {
  //     case 'en':
  //       return dayjs(timestamp).tz('Europe/Helsinki').format('[the] Mo [of] MMMM YYYY [at] h:mm a');
  //     case 'fi':
  //     default:
  //       return dayjs(timestamp).tz('Europe/Helsinki').format('D.M.YYYY [klo] HH:mm');
  //   }
  // }

  // const separateParkingLotAgreement = user.attributes.agreements?.find(
  //   (agreement) => agreement.targetType === AgreementTypes.PARKING_LOT
  // );

  // TODO: Move to its own component?
  // const PendingPaymentNotice = ({ pendingPayment, processedPayment }: any) => {
  //   // TODO: Typing
  //   // If there is a pending payment with a timestamp later than any processed payment, show the disclaimer.
  //   if (
  //     !processedPayment ||
  //     // TODO: Measure from start of day rather than exact timestamp?
  //     (processedPayment && dayjs(pendingPayment.paidAt).isSameOrAfter(dayjs(processedPayment.paidDate).endOf('day')))
  //   ) {
  //     return (
  //       <PendingPaymentNoticeBox>
  //         <PendingPaymentIcon src={warningIcon} />
  //         <BasicParagraph>
  //           {t('payment.recent', {
  //             time: formatPaymentDateTime(pendingPayment.paidAt),
  //             amount: (pendingPayment.amount / 100).toFixed(2),
  //           })}
  //         </BasicParagraph>
  //       </PendingPaymentNoticeBox>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  const printPage = () => {
    window.print();
    billingGAEvent('Print page');
  };

  let history =
    billingHistory.loadState !== LOAD_STATES.BUSY &&
    Array.isArray(billingHistory.items) &&
    billingHistory.items.length &&
    billingHistory.items;
  const filteredHistory = [];

  const monthOptions = !history
    ? null
    : history.map((report, num) => {
        const key = report.year * 12 + report.month;
        return (
          <option key={key} value={key}>
            {t('months')[report.month - 1]() + ' ' + report.year}
          </option>
        );
      });

  if (history) {
    const [start, end] = [billingHistory.rangeStart || 0, billingHistory.rangeEnd || 0].sort();

    for (let report of history) {
      const key = report.year * 12 + report.month;

      if (key >= start && key <= end) {
        const sum = report.sumBefore;
        report = { ...report, paid: [...report.paid], billed: [...report.billed], isCurrent: report.isCurrent };

        if (billingHistory.filter === 'all') {
          if (sum === null) {
            report.paid.unshift({
              amountEuros: null,
              paidDate: '',
              payerName: t('history.prevUnknown'),
            });
          }

          if (sum < 0 && sum !== null) {
            report.billed.unshift({
              amountEuros: -sum,
              dueDate: '',
              reasonName: t('history.prevRemaining'),
            });
          } else if (sum !== null) {
            report.paid.unshift({
              amountEuros: sum,
              paidDate: '',
              payerName: t('history.prevAdvance'),
            });
          }
        }

        filteredHistory.push(report);
      }
    }
  }

  return (
    <BillingContentWrapper>
      <Content>
        {/* TODO: return these after ported to Kenno data
        <AgreementSection>
          <AgreementInfo />
        </AgreementSection> */}
        <H1>{t('title')}</H1>
        <BasicParagraph>{t('description')}</BasicParagraph>
        <BasicTextLegacy>{t('description2')}</BasicTextLegacy>
        <BasicParagraph id="printlabel">{t('print.description')}</BasicParagraph>
      </Content>
      <Controls>
        <Buttons>
          {/* TODO: return this after printing is enabled in the new backend
          <Button primary iconRight icon="print" onClick={printInvoice}>
            {t('print.title')}
          </Button> */}
          {/* {separateParkingLotAgreement && (
            <ActionButton
              onClick={(e) => {
                // eslint-disable-next-line max-len
                const billingInvoiceAgreement = 
                  `${AWSEndpoints.BILLING_INVOICE}?agreement-id=${separateParkingLotAgreement.id}`;

                signAwsUrl(billingInvoiceAgreement, (signedUrl) => {
                  window.open(signedUrl);
                  billingGAEvent('Print parking lot invoice');
                });

                return false;
              }}
            >
              <ButtonLabel>{t('print.parkingLot')}</ButtonLabel>
              <img src={whitePrintIcon} alt="" />
            </ActionButton>
          )} */}
          {/* {debts.amountEuros && debts.amountEuros > 0 && !debts.inDistraint && (
            <LinkButton to={getMenuItemPathBySlug(menu.items, Slugs.DEBT)} onClick={showDebtPage}>
              {t('itemized')}
            </LinkButton>
          )} */}
        </Buttons>
      </Controls>
      <FilterFormWrapper>
        <FilterWrapper>
          <FilterDateContainer>
            <FormRow>
              <Container>
                <FormLabel>{t('filter.startdate')}</FormLabel>
                <SimpleSelect name="startmonth" onChange={changeRangeStart} value={'' + billingHistory.rangeStart}>
                  {monthOptions}
                </SimpleSelect>
              </Container>
              <Container>
                <FormLabel>{t('filter.enddate')}</FormLabel>
                <SimpleSelect name="endmonth" onChange={changeRangeEnd} value={'' + billingHistory.rangeEnd}>
                  {monthOptions}
                </SimpleSelect>
              </Container>
            </FormRow>
          </FilterDateContainer>
          <FilterButtonContainer>
            <FormRow>
              <FilterButton
                className={`all ${billFilter === 'all' ? 'active' : ''}`}
                type="button"
                name="show"
                onClick={() => changeFilter('all')}
                value={t('filter.showall')}
              />
              <FilterButton
                className={`billed ${billFilter === 'billed' ? 'active' : ''}`}
                type="button"
                name="show"
                onClick={() => changeFilter('billed')}
                value={t('filter.showbilled')}
              />
              <FilterButton
                className={`paid ${billFilter === 'paid' ? 'active' : ''}`}
                type="button"
                name="show"
                onClick={() => changeFilter('paid')}
                value={t('filter.showpaid')}
              />
            </FormRow>
          </FilterButtonContainer>
        </FilterWrapper>
      </FilterFormWrapper>
      <TableWrapper>
        <TableContent>
          {!history ? (
            Array.isArray(billingHistory.items) ? null : (
              <Loader />
            )
          ) : (
            filteredHistory.map((report, num) => (
              <Fragment key={`billingperiod-${num}`}>
                <BillingPeriod
                  key={num}
                  t={msgHistory}
                  t2={report.isCurrent ? msgCurrent : msgHistory}
                  startDate={report.startDate}
                  endDate={report.endDate}
                  sum={report.sumAfter}
                  isCurrent={report.isCurrent}
                  filter={billingHistory.filter}
                >
                  {report.billed.map((bill, num) => (
                    <BillRow
                      key={num}
                      reasonName={bill.reasonName}
                      amountEuros={bill.amountEuros}
                      dueDate={bill.dueDate}
                    />
                  ))}
                  {report.paid.map((payment, num) => (
                    <PaymentRow
                      key={num}
                      payerName={payment.payerName}
                      amountEuros={payment.amountEuros}
                      paidDate={payment.paidDate}
                    />
                  ))}
                </BillingPeriod>
                {/* {report.isCurrent &&
                  recentPayments.items?.map((pending) => (
                    <PendingPaymentNotice
                      pendingPayment={pending}
                      // Latest processed payment, probably:
                      processedPayment={report.paid.length > 0 ? report.paid[report.paid.length - 1] : null}
                    />
                  ))} */}
              </Fragment>
            ))
          )}
        </TableContent>
        <ButtonContent>
          <ControlsRow>
            <ActionButton onClick={printPage}>
              <ButtonLabel>
                <span>{t('print.page')}</span>
              </ButtonLabel>
              <img src={whitePrintIcon} alt="" />
            </ActionButton>

            <PaymentButton to={getMenuItemPathBySlug(menu.items, Slugs.PAYMENT)}>
              <ButtonLabel>
                <span>{t('gotopayment')}</span>
              </ButtonLabel>
              <ArrowIcon>&#10140;</ArrowIcon>
            </PaymentButton>
          </ControlsRow>
        </ButtonContent>
      </TableWrapper>
    </BillingContentWrapper>
  );
};

export default BillingHistory;
