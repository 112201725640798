import { z } from 'zod';

export const schema = z.object({
  phone: z.string().min(1),
  email: z.string().min(1).email(),
  subject: z.string().min(1),
  content: z.string().min(1),
  language: z.string().min(2),
  category: z.string().min(1),
  subcategory: z.string().min(1),
});

export type ContactFormState = z.infer<typeof schema>;
