// Page slugs in our WordPress
export const Slugs = {
  TENANCY: 'vuokrani',
  BOOKINGS: 'varaukseni',
  MY_BUILDING: 'taloni',
  LIVING: 'asuminen',
  CONTACT: 'ota-yhteytta',
  AGREEMENT: 'vuokrasopimus',
  BILLING: 'maksutiedot',
  PAYMENT: 'maksaminen',
  DEBT: 'velkaerittely',
  BOOKING_CALENDAR: 'varauskalenteri',
  BUILDING_INFO: 'taloni-tiedot',
  FAULT_REPORT: 'vikailmoitus',
  APARTMENT_CHANGE: 'asunnonvaihto',
  NEW_APPLICATION: 'uusi-hakemus',
  CONTACT_INFO: 'yhteystiedot',
  NOTICES: 'tiedotteet',
  GUIDES_DOCUMENTS: 'ohjeet-dokumentit',
  SAFETY: 'turvallisuus-ja-pelastautuminen',
  SUSTAINABILITY: 'vastuullisuus',
  COMMUNITY_ACTIVITIES: 'asukastoiminta',
  COMMITTEE: 'asukastoimikunta',
  CHAIRMAN: 'puheenjohtaja',
  EVENTS_FOR_ALL: 'asukastapahtumat',
  BENEFITS: 'asukasedut',
  EVENTS: 'talon-tapahtumat',
  FEEDBACK: 'palaute',
  RULES: 'jarjestyssaannot',
  WORK: 'toita-asukkaille',
  INFLUENCERS: 'vaikuttajaryhma',
  WATER_METER: 'vesimittari',
};

// Content block slugs in our WordPress
export const BlockSlugs = {
  DNA: 'dna',
  ELISA: 'elisa',
  DNA_ETHERNET: 'dna-ethernet',
  DNA_MODEM: 'dna-cabelmodem',
  NO_INTERNET: 'no-internet',
  SAUNA: 'sauna',
  SAUNA_CALENDAR: 'sauna-kalenteri',
  NO_SAUNA: 'ei-saunaa',
  LAUNDRY: 'talopesula',
  LAUNDRY_CALENDAR: 'talopesula-kalenteri',
  NO_LAUNDRY: 'ei-talopesulaa',
  PARKING: 'pysakointi',
  STORAGE: 'varastointi',
  EXPIRED_AGREEMENT: 'etusivu-poismuuttava',
  ONE4ALL: 'one4all',
};

export const footerLinkSlugs: { [key: string]: string[] } = {
  [Slugs.TENANCY]: [Slugs.BILLING, Slugs.PAYMENT, Slugs.AGREEMENT],
  [Slugs.MY_BUILDING]: [Slugs.SAFETY],
  [Slugs.LIVING]: [Slugs.COMMUNITY_ACTIVITIES, Slugs.COMMITTEE, Slugs.EVENTS_FOR_ALL],
  [Slugs.CONTACT]: [Slugs.CONTACT_INFO, Slugs.SUSTAINABILITY],
};
