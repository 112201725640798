import React from 'react';
import { getIcon, HEX_OPACITY_50 } from './Button';
import { scrollToTop } from '../../../utils';
import styled, { css } from 'styled-components/macro';
import { LinkButtonProps, StyledLink } from './LinkButton';
import { DeviceSize } from '../../basic/DeviceSize';

const StyledLinkButtonWithChevron = styled(StyledLink)<{ color: string | undefined }>`
  width: 308px;
  justify-content: space-between;

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      border: 2px solid ${props.color};
      &:hover {
        background-color: ${props.color}${HEX_OPACITY_50};
      }
    `}

  @media ${DeviceSize.desktopM} {
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
  }
  div > svg {
    margin-right: 10px;
  }
`;

/**
 * Link component which uses partly the same styles as LinkButton component.
 * Icon possibility, which always renders the predefined icon on the left side of the button. Chevron icon is
 * rendered on the right side of the button.
 * Color props can be given to define custom background color, otherwise primary button colors are applied.
 * Size, primary and iconRight props are fixed and prevented from being overwritten.
 *
 * Example use:
 * <LinkButtonWithChevron icon='plus' to='/path' color='#ffffff'>Button text here</LinkButtonWithChevron>
 */
export const LinkButtonWithChevron: React.FC<
  Omit<LinkButtonProps, 'size' | 'primary' | 'iconRight'> & { color?: string | undefined }
> = ({ icon, to, color, children, ...props }) => {
  const isPrimary = color ? false : true;
  const renderedIcon = getIcon(icon, isPrimary);
  const chevronIcon = getIcon('chevronRight', isPrimary);

  return (
    <StyledLinkButtonWithChevron
      to={to}
      color={color}
      onClick={scrollToTop}
      {...props}
      size="large"
      primary={isPrimary}
      iconRight={false}
    >
      <div>
        {renderedIcon}
        {children}
      </div>
      {chevronIcon}
    </StyledLinkButtonWithChevron>
  );
};
