import React, { useEffect, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import Card from '../../components/common/Card';
import axios from 'axios';
import { FlexColumn, FlexRow } from '../../components/basic/Flex';
import styled from 'styled-components/macro';
import Loader from '../../components/basic/Loader';
import dayjs, { Dayjs } from 'dayjs';
import { Body4 } from '../../components/common/Text';
import { AnchorButton } from '../../components/common';
import { htmlDecode } from '../../utils';

type NewsPost = {
  date: Dayjs;
  title: string;
  url: string;
};

type ArticleRowProps = {
  post: NewsPost;
};
const ArticleContainer = styled(FlexColumn)`
  border-radius: 16px;
  background: ${(props) => props.theme.colors.lightYellow};
  padding: 16px;
  gap: 8px;

  a {
    color: inherit;
  }
`;

const ArrowButton = styled(AnchorButton)`
  padding: 8px 6px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const ArticleRow: React.FC<ArticleRowProps> = ({ post }) => {
  const t = useMessageGetter('home.grid.news');
  return (
    <ArticleContainer>
      <FlexRow alignItems="center" justifyContent="space-between">
        <Body4>{post.date.format('DD.MM.YYYY')}</Body4>
        <ArrowButton href={post.url} aria-label={t('readmore')} size="small" icon="arrowForward" />
      </FlexRow>
      <a href={post.url}>{post.title}</a>
    </ArticleContainer>
  );
};

const M2NewsCard = () => {
  const t = useMessageGetter('home.grid.news');
  const m2koditWpUrl = 'https://m2kodit.fi/wp-json/wp/v2';
  const [posts, setPosts] = useState<Array<NewsPost>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(`${m2koditWpUrl}/posts?per_page=3`, {
        timeout: 30000,
      });

      setPosts(
        res.data.map((post: any) => ({
          date: dayjs(post.date),
          title: htmlDecode(post.title.rendered),
          url: post.link,
        }))
      );
    };

    try {
      setIsLoading(true);
      fetchPosts();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Card
      title={t('title')}
      mainButton={
        <AnchorButton
          size="small"
          background="transparent"
          href={'https://m2kodit.fi/uutinen/'}
          iconRight
          icon="arrowForward"
        >
          {t('action.label')}
        </AnchorButton>
      }
    >
      {isLoading || posts.length === 0 ? (
        <Loader minHeight="350px" />
      ) : (
        <FlexColumn gap="8px">
          {posts.map((post) => (
            <ArticleRow key={post.url} post={post} />
          ))}
        </FlexColumn>
      )}
    </Card>
  );
};

export default M2NewsCard;
