import React from 'react';
import { DeviceSize } from '../basic/DeviceSize';
import styled from 'styled-components/macro';
import { blackLogo } from '../../assets';
import { NavLink } from 'react-router-dom';

interface HomeLogoProps {
  margin?: string;
}

const HomeLogoImage = styled(NavLink)<{ margin?: string }>`
  z-index: 1;

  img {
    width: 150px;

    @media print {
      height: 50px;
    }

    @media ${DeviceSize.desktopS} {
      width: 64px;
    }
  }

  @media ${DeviceSize.desktopS} {
    display: flex;
    margin: 0;
    position: unset;
    top: 0;
    left: 0;
    order: 0;
  }

  @media print {
    position: absolute;
    margin: 0 0 0 0;
    left: unset;
    right: 0;
  }
`;

const HomeLogo = ({ margin }: HomeLogoProps) => (
  <HomeLogoImage activeClassName="active" exact to="/" margin={margin} id="homelogo">
    <img src={blackLogo} alt="M2 logo" aria-label="Siirry kotisivulle" />
  </HomeLogoImage>
);
export default HomeLogo;
