import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../basic/DeviceSize';
import { Body2 } from '../common';
import PaddedContent from '../housing/PaddedContent';

interface BulletinProps {
  title?: string;
  body: string;
  theme: string;
}

const Bulletin: React.FunctionComponent<BulletinProps> = ({ body, theme }) => {
  //const [open, setOpen] = useState(true);

  const bulletinType = theme || 'notice';

  const extractParagraphText = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return Array.from(doc.querySelectorAll('p'))
      .map((p) => p.textContent?.trim())
      .filter(Boolean)
      .join('\n');
  };

  //let bulletinIcon;
  //let chevronIcon;
  //let crossIcon;
  /* switch (bulletinType) {
    case 'alert':
      bulletinIcon = bulletinAlert;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
      break;
    case 'notice':
      bulletinIcon = bulletinNotice;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
      break;
    case 'uuras-info':
      bulletinIcon = bulletinNoticeBlack;
      chevronIcon = chevronDownBlack;
      crossIcon = blackCross;
      break;
    default:
      bulletinIcon = bulletinAlert;
      chevronIcon = chevronDown;
      crossIcon = whiteCross;
  }
 */
  return (
    <BulletinContainer bulletinType={bulletinType}>
      {/*       <BulletinHeader onClick={() => setOpen(!open)}>
         <BulletinIcon alt="Ilmoitus" src={bulletinIcon} /> 
                 <BulletinTitle>{title}</BulletinTitle>
        <BulletinToggle
          onClick={() => setOpen(!open)}
          alt="Piilota/näytä ilmoitus"
          src={open ? crossIcon : chevronIcon}
        /> 
      </BulletinHeader> */}
      <PaddedContent>
        <BulletinBody>{extractParagraphText(body)}</BulletinBody>
      </PaddedContent>
      {/*  {open && <BulletinBody dangerouslySetInnerHTML={{ __html: body! }}></BulletinBody>} */}
    </BulletinContainer>
  );
};

const BulletinContainer = styled.div<{ bulletinType: string }>`
  position: relative;

  button {
    height: 40px;
    width: 40px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  ${(props) =>
    props.bulletinType === 'notice' &&
    `
    background-color: ${props.theme.colors.apila};

    h2 {
      color: ${props.theme.colors.m2White};
      text-shadow: 1px 1px 2px ${props.theme.colors.shadow};
    }

    p {
      color: ${props.theme.colors.charcoal};
    }
  `}

  ${(props) =>
    props.bulletinType === 'alert' &&
    `
    background-color: ${props.theme.colors.strawberry};
    color: ${props.theme.colors.charcoal};

    p {
      color: ${props.theme.colors.charcoal};
    }
  `}

  ${(props) =>
    props.bulletinType === 'uuras-info' &&
    `
    border: 1px solid white;
    background-color: ${props.theme.colors.lemon};
    color: ${props.theme.colors.coal};
    border: 2px solid ${props.theme.colors.white};

    h2, p {
      color: ${props.theme.colors.coal};
    }
  `}
`;

/* const BulletinHeader = styled.div`
  display: flex;
`; */

/* const BulletinIcon = styled.img`
  position: relative;
  margin-right: 20px;
  top: 2px;
`;

const BulletinToggle = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

const BulletinTitle = styled(H2)`
  display: inline;
  margin: 0 10px;
  font-size: 24px;
  color: ${theme().colors.charcoal};

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
  }
`; */

const BulletinBody = styled(Body2)`
  color: ${(props) => props.theme.colors.m2White};

  .wp-block-media-text__media {
    img {
      width: 100%;
      max-width: 320px;
      height: auto;
      border-radius: 12px;

      @media ${DeviceSize.desktopS} {
        margin: 0 auto;
        display: block;
      }
    }
  }

  .wp-block-media-text__content {
    padding: 0 4%;
  }

  h2.wp-block-post-title {
    display: none; // "temporary" workaround to hide duplicate post titles
  }
`;

export default Bulletin;
