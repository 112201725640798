import React from 'react';
import styled from 'styled-components/macro';
import { buttonStyles, CommonButtonProps, getIcon } from './Button';

export const StyledAnchor = styled.a`
  ${buttonStyles}
`;

export type AnchorButtonProps = CommonButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Anchor component which uses the same styles as project's Button component. Icon possibility, which can
 * render the predefined icon to the left side of the button, unless `iconRight` flag is given.
 * `primary` flag can be given to revert the colors.
 * Size can be defined with prop `size`. Default size is large.
 *
 * Example use:
 * <AnchorButton icon='plus' primary iconRight href='https://m2kodit.fi'>Button text here</AnchorButton>
 */
export const AnchorButton: React.FC<AnchorButtonProps> = ({
  icon,
  primary,
  iconRight,
  size = 'large',
  href,
  children,
  ...props
}) => {
  const renderedIcon = getIcon(icon, primary);

  return (
    <StyledAnchor href={href} primary={primary} iconRight={iconRight} size={size} {...props}>
      {renderedIcon}
      {children}
    </StyledAnchor>
  );
};
