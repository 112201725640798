import { z } from 'zod';

const contactInfoSchema = z.object({
  name: z.string().min(1),
  ssn: z.string().min(1),
  address: z.string().min(1),
  zip: z.string().min(1),
  city: z.string().min(1),
  phone: z.string().min(1),
  email: z.string().email(),
  iban: z.string().min(1),
});

const eventSchema = z.object({
  name: z.string().min(1),
  location: z.string().min(1),
  travelRoute: z.string().min(1),
});

const publicExpenseReport = z.object({
  date: z.string().min(1),
  time: z.string().min(1),
  vehicle: z.string().min(1),
  amount: z.number(),
  receipt: z.instanceof(FileList),
  additionalInformation: z.string(),
});

const ownCarExpenseReport = z.object({
  date: z.string().min(1),
  time: z.string().min(1),
  numberOfPeople: z.number().min(1),
  totalKm: z.number().min(1),
  additionalInformation: z.string(),
});

export const schema = z.object({
  contactInfo: contactInfoSchema,
  event: eventSchema,
  publicExpenseReports: z.array(publicExpenseReport).optional(),
  ownCarExpenseReports: z.array(ownCarExpenseReport).optional(),
});

export type TravelExpenseReportFormState = z.infer<typeof schema>;
