import React from 'react';
import { Body2 } from './common';
import { useMessageGetter } from 'react-message-context';

type Props = {
  translationKey: string;
};

export const MaintenanceNote: React.FC<Props> = ({ translationKey }) => {
  const t = useMessageGetter('maintenance');
  return <Body2>{t(translationKey)}</Body2>;
};
