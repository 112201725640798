import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from './theme';
import AppRouter from './AppRouter';
import { AppStateProvider } from './context/appContext';
import { KennoTenantStateProvider } from './context/kennoTenantContext';

const App: FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme()}>
      <AppStateProvider>
        <KennoTenantStateProvider>
          <AppRouter />
        </KennoTenantStateProvider>
      </AppStateProvider>
    </ThemeProvider>
  );
};

export default App;
