import styled, { css } from 'styled-components/macro';
import { theme } from '../theme';
import { DeviceSize } from '../components/basic/DeviceSize';
import { FormRow } from './forms/layoutComponents';
import { FlexColumn } from '../components/basic/Flex';

export const formContainerMobileStyles = css`
  width: 90%;
  padding: 24px;
  margin: 0 0 20px 0;
`;

export const FormContainer = styled(FlexColumn)`
  width: 625px;
  min-height: 200px;
  align-self: center;
  background-color: ${theme().colors.apila};
  border-radius: 24px;
  padding: 32px 40px;
  z-index: 1;
  margin: 30px 0 70px 0;

  @media ${DeviceSize.mobileL} {
    ${formContainerMobileStyles}
  }
`;

export const Form = styled.form`
  margin-top: 30px;
`;

export const ButtonContainer = styled(FormRow)`
  align-items: center;
  margin-top: 10px;

  @media ${DeviceSize.mobileL} {
    text-align: center;
    gap: 14px;
    button,
    a {
      width: 100%;
      height: 48px;
    }
  }
`;

export const LinkContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${DeviceSize.desktopM} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  @media ${DeviceSize.mobileL} {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
