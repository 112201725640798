import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { FileIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { FormPreviewActions } from '../../../components/common/forms/FormPreviewActions';
import { GridContainer, PreviewContentBlock, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { TravelExpenseReportFormState } from './schema';
import { formatISODate } from '../../../utils';
import { useFormattedClockTime } from '../../../hooks';

const PreviewContent = styled(PreviewContentBlock)`
  gap: 40px;
`;

const EventDetailsContainer = styled(PreviewContentBlock)``;
const TravelExpensesContainer = styled(PreviewContentBlock)``;

const ExpenseReportContainer = styled(GridContainer)`
  padding-bottom: 20px;
  gap: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`;

const Column = styled.div<{ span?: number }>`
  display: flex;
  flex-direction: column;
  grid-column: span ${({ span }) => span ?? 1};
`;

const FileLink = styled.a`
  display: inline-flex;
  text-decoration: underline;
  color: black;
`;

type Props = { closePreview: () => void };

export const TravelExpenseReportPreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<TravelExpenseReportFormState>();
  const tForms = useMessageGetter('forms');
  const { formatClockTime } = useFormattedClockTime();
  const t = useMessageGetter('forms.travelExpenseReport');

  const formData = watch();

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {tForms('closePreview')}
        </Button>
      </Row>
      <H2>{t('title')}</H2>
      <H3>{t('contactInfo.title')}</H3>
      <GridContainer rowGap="24px">
        <Column>
          <span>{t('contactInfo.name')}</span>
          <span>{formData.contactInfo.name}</span>
        </Column>
        <Column>
          <span>{t('contactInfo.ssn')}</span>
          <span>{formData.contactInfo.ssn}</span>
        </Column>
        <Column span={2}>
          <span>{t('contactInfo.address')}</span>
          <span>{formData.contactInfo.address}</span>
          <span>
            {formData.contactInfo.zip} {formData.contactInfo.city}
          </span>
        </Column>
        <Column>
          <span>{t('contactInfo.phone')}</span>
          <span>{formData.contactInfo.phone}</span>
        </Column>
        <Column>
          <span>{t('contactInfo.email')}</span>
          <span>{formData.contactInfo.email}</span>
        </Column>
        <Column span={2}>
          <span>{t('contactInfo.iban')}</span>
          <span>{formData.contactInfo.iban}</span>
        </Column>
      </GridContainer>

      <EventDetailsContainer>
        <H3>{t('event.title')}</H3>
        <Column>
          <span>{t('event.name')}</span>
          <span>{formData.event.name}</span>
        </Column>
        <Column>
          <span>{t('event.location')}</span>
          <span>{formData.event.location}</span>
        </Column>
        <Column>
          <span>{t('event.travelRoute')}</span>
          <span>{formData.event.travelRoute}</span>
        </Column>
      </EventDetailsContainer>
      <TravelExpensesContainer>
        <H3>{t('reports.title')}</H3>
        {formData.publicExpenseReports?.map((report, index) => {
          const formattedDate = report.date ? formatISODate(report.date, true) : '';
          const formattedTime = formatClockTime(report.time);
          return (
            <ExpenseReportContainer key={`${report.date}${report.time}${index}`} columns={5}>
              <Column span={4}>
                <span>{report.vehicle}</span>
                <span>{`${formattedDate} ${formattedTime}`}</span>
              </Column>
              <Column>
                <span>{t('publicExpenseReports.amount')}</span>
                <span>{report.amount.toFixed(2)}</span>
              </Column>
              <Column span={5}>
                <TextBlock>{report.additionalInformation}</TextBlock>
              </Column>
              {report.receipt && (
                <Column span={5}>
                  <FileLink href={URL.createObjectURL(report?.receipt[0])} download>
                    <Row>
                      <FileIcon style={{ flexShrink: 0 }} />
                      <TextBlock>{report.receipt[0].name}</TextBlock>
                    </Row>
                  </FileLink>
                </Column>
              )}
            </ExpenseReportContainer>
          );
        })}
        {formData.ownCarExpenseReports?.map((report, index) => {
          const formattedDate = report.date ? formatISODate(report.date, true) : '';
          const formattedTime = formatClockTime(report.time);
          return (
            <ExpenseReportContainer key={`${report.date}${report.time}${index}`} columns={5}>
              <Column span={4}>
                <span>{t('ownCarExpenseReports.ownCar')}</span>
                <span>{`${formattedDate} ${formattedTime}`}</span>
              </Column>
              <Column>
                <span>Km</span>
                <span>{report.totalKm}</span>
              </Column>
              <Column span={5}>
                <TextBlock>{report.additionalInformation}</TextBlock>
              </Column>
            </ExpenseReportContainer>
          );
        })}
      </TravelExpensesContainer>
      <FormPreviewActions handleEdit={closePreview} handleSubmit={() => console.log('wip')} actionButtonType="send" />
    </PreviewContent>
  );
};
