import React, { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { ControllerFieldState, FieldValues, get, Noop, Path, useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled, { css, StyledComponent } from 'styled-components/macro';
import { form2Style } from '../../basic/Text';
import { ControlledElement, InputElementRenderProps } from './ControlledElement';
import { theme } from '../../../theme';
import { ErrorOutlineIcon } from '../../../assets';

// TODO: styles in this file are partly copypasted and uses legacy styles as well, but
// Need to go through them later.

const Container = styled.div<{ width?: string; maxWidth?: string; margin?: string; styles?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth};
  margin: ${(props) => props.margin};
  ${(props) => props.styles};
`;

const RequiredIndicator = styled.span`
  color: red;
`;
const Label = styled.label`
  font-family: ${(props) => props.theme.text.primaryFont};
  margin-bottom: 8px;
  min-height: 22px;
  letter-spacing: 0.8px;
  padding: 3px 22px 3px 0px;
  display: block;
`;

// TODO: Not all the styles are correct
const inputStyle = css<{ hasError?: boolean; disabled?: boolean }>`
  ${form2Style}
  border: 2px solid ${(props) => (props.hasError ? props.theme.colors.error : props.theme.colors.black)};
  background-color: ${(props) => (props.disabled ? props.theme.colors.almostWhite : props.theme.colors.darkSnow)};
  border-radius: 26px;
  width: 100%;
  padding: 0 18px;

  // Currently there is some legacy hacks going on in frontend/src/index.tsx
  // which are affecting the focus and therefore this designed double
  // outline is not working correctly. (works when tabbed, but breaks when clicked)
  // Probably should remove all the 'hide-focus' hacks from the index file.
  // If the hacks are removed, we can uncomment this
  // &:focus-visible {
  //   outline: 2px #f9f9f9 solid;
  //   outline-offset: 0;
  //   box-shadow: 0 0 0 4px #193146;
  // }
`;

const InputElement: StyledComponent<any, any> = styled.input`
  ${inputStyle}
  height: ${(props) => (props.type === 'textarea' ? '200px' : '50px;')};
  :disabled {
    border: none;
    border-color: transparent;
  }
`;

const ValidationErrorMessage = styled.div<{ dark?: boolean; hasError?: boolean }>`
  min-height: 24px;
  font-size: 0.8em;
  display: flex;
  padding: ${({ dark, hasError }) => (dark && hasError ? '3px 4px' : '3px 22px')};
  color: ${({ dark }) => (dark ? theme().colors.charcoal : theme().colors.error)};

  ${({ dark, hasError }) =>
    dark &&
    hasError &&
    css`
      background-color: white;
      margin: 4px 15px 6px;
      border-radius: 4px;
      border: 1px solid ${theme().colors.error};
    `}

  svg {
    width: 12px;
    height: 12px;
    margin: 2px 10px 0 0;
    path {
      fill: ${theme().colors.error};
    }
  }
`;

interface CommonInputProps<FormState extends FieldValues> {
  fieldPath: Path<FormState>;
  // Typing translationPrefix is not possible because our translation keys are flat "dot notation" strings
  // (e.g., "forms.memo.chairperson") instead of an object structure.
  // To make this type-safe, we would need to convert the keys into an object format.
  translationPrefix: string;
  overrideLabel?: string;
  useDefaultValidationErrorMessages?: boolean;
  className?: string;
  width?: string;
  inputClassName?: string;
  hideLabel?: boolean;
  required?: boolean;
  dark?: boolean;
}

export interface FormInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  onBlur?: Noop;
  fieldState?: ControllerFieldState;
  disabled?: boolean;
}

interface ControlledInputProps<T> {
  inputElementRenderer: (props: InputElementRenderProps<T>) => React.ReactElement;
  type?: never;
}
interface HTMLInputProps extends InputHTMLAttributes<Element> {
  inputElementRenderer?: never;
  type: HTMLInputTypeAttribute;
}

type Props<FormState extends FieldValues, T> = CommonInputProps<FormState> & (ControlledInputProps<T> | HTMLInputProps);

/**
 * InputField requires the FormState as parameter and also the type of the changing
 * value. Flag `dark` applies different styles for error message when the InputField is placed on
 * a dark background.
 *
 * Example usage:
 *   <InputField<FooBarFormState, DropdownOptionValue>
 *     translationPrefix={translationPrefix}
 *     fieldPath={`foo.bar`}
 *     inputElementRenderer={(props) => <FooBarDropdown {...props} />}
 *   />
 *
 * The label comes from the translationPrefix + fieldpath by default, but
 * if you wish to override this default setting, you can use overrideLabel. This is
 * mainly useful if you have an input that needs to have its label changed dynamically, but
 * uses the same schema variable.
 */
export const InputField = <FormState extends FieldValues, T>({
  className = '',
  inputClassName = '',
  fieldPath,
  translationPrefix,
  overrideLabel,
  useDefaultValidationErrorMessages = false,
  hideLabel,
  type,
  required,
  dark,
  inputElementRenderer,
  width,
  ...inputHTMLAttributes
}: Props<FormState, T>): React.ReactElement => {
  const id = `${translationPrefix}.${fieldPath}`;
  const t = useMessageGetter('');
  const errorMessageTranslator = useMessageGetter('validation');
  const {
    register,
    formState: { errors },
  } = useFormContext<FormState>();

  const error = get(errors, fieldPath);

  // When using react-hook-form useFieldArrays, we need to strip the index part
  // from the fieldPath fro the translation, so e.g. foo.3.bar becomes foo.bar
  const formattedFieldPath = fieldPath.replace(/\.\d+/, '');
  const fullTranslationPath = `${translationPrefix}.${formattedFieldPath}`;

  const label = overrideLabel ? overrideLabel : t(fullTranslationPath);

  const errorMessage =
    error?.message && (useDefaultValidationErrorMessages ? error.message : errorMessageTranslator(fullTranslationPath));
  const isControlledElement = !!inputElementRenderer;

  const preventEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Container width={width}>
      {!hideLabel && (
        <Label htmlFor={id}>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </Label>
      )}
      {isControlledElement ? (
        <ControlledElement
          inputElementRenderer={inputElementRenderer}
          hasError={!!error}
          fieldPath={fieldPath}
          id={id}
        />
      ) : (
        <InputElement
          onKeyDown={preventEnter}
          id={id}
          type={type}
          {...register(fieldPath, { valueAsNumber: type === 'number' })}
          {...inputHTMLAttributes}
          hasError={!!error}
        />
      )}

      {
        <ValidationErrorMessage dark={dark} hasError={!!error}>
          {dark && !!error && <ErrorOutlineIcon />}
          {errorMessage}
        </ValidationErrorMessage>
      }
    </Container>
  );
};
