import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { kennoTenantReducer } from './kennoTenantReducer';
import { Dispatcher, loadUser } from './kennoTenantActions';
import { LOAD_STATES } from './common';
import { BillingMonth } from '../types/tampuuri';

interface KennoProfileAttributes {
  uuid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  cognitoId?: string;
}

export interface KennoUserState {
  isLoggedIn: boolean;
  isLoaded: boolean;
  attributes?: KennoProfileAttributes;
}

export interface BillingHistory {
  loadState?: LOAD_STATES | false;
  items?: BillingMonth[];
  filter: 'all' | 'billed' | 'paid';
  rangeStart?: number;
  rangeEnd?: number;
}

interface BillingItem {
  targetId: number;
  nameOnBill: string;
  dueDate: string;
  expected: string;
}

export interface CurrentBill {
  loadState?: LOAD_STATES | false;
  totalRent?: number;
  difference?: number;
  debt?: number;
  dueDate?: Date | null;
  items?: BillingItem[];
}

interface CurrentTargetsAttributes {
  monthlyRent?: number | null;
  monthlyWaterAdvance?: number | null;
}

export interface CurrentTargets {
  loadState?: LOAD_STATES | false;
  data?: CurrentTargetsAttributes;
}

interface Address {
  id?: number;
  countryName?: string;
  countryCode?: string;
  streetAddressLine1: string;
  streetAddressLine2: string | null;
  city: string;
  postalCode: string;
}

export interface Contract {
  loadState: LOAD_STATES | false;
  uuid?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  realPropertyName?: string;
  postalAddress?: Address;
  status?: string; // TODO: consider using an enum
  addressDetails?: {
    staircaseLetter: string;
    apartmentNumber: string;
  };
  contractType?: string; // TODO: consider using an enum
  propertyCompanyName?: string;
  referenceNumber?: string;
  propertyCompanyAddress?: Address;
  tenant?: {
    isPayer: boolean;
    isContractParty: boolean;
    tenantRole: string; // TODO: consider using an enum
    id: number;
  };
  language?: string;
  terminationDisallowedReason?: string; // TODO: consider using an enum
}

export interface ApartmentAttributes {
  rentableApartmentId: number;
  rentalApartmentId: number | null;
  rightOfOccupancyApartmentId: number | null;
  postalAddress: Address | null;
  floor: number | null;
  technicalTypeLabel: string | null;
  area: string | null;
  salesDescription: string | null;
  rentalApartmentStatus: RentalApartmentStatus | null;
  rightOfOccupancyApartmentStatus: RightOfOccupancyApartmentStatus | null;
  contractStatus: ContractStatus | null;
  visibleOnHomePage: boolean;
  images: Array<string>;
}

export interface Apartment {
  loadState?: LOAD_STATES | false;
  data?: ApartmentAttributes;
}

type RentalApartmentStatus =
  | 'active_contract'
  | 'active_offer'
  | 'attachments_received'
  | 'becoming_vacant'
  | 'contract_signature_pending'
  | 'interested_in_viewing'
  | 'offer_accepted'
  | 'rejected'
  | 'reserved'
  | 'vacant';

type RightOfOccupancyApartmentStatus =
  | 'active_contract'
  | 'becoming_vacant'
  | 'contract_created'
  | 'offer_round_finished'
  | 'offer_round_open'
  | 'reserved'
  | 'vacant';

type ContractStatus =
  | 'active'
  | 'cancelled'
  | 'draft'
  | 'expired'
  | 'signature_cancelled'
  | 'signature_expired'
  | 'signed'
  | 'terminated'
  | 'waiting_for_signature';

export interface Building {
  loadState: LOAD_STATES | false;
  maintenanceCompany?: {
    name: string;
    phoneNumber: string;
    emergencyNumber: string;
  };
  emergencyPlan?: {
    url: string;
  };
}

export interface KennoTenantState {
  user: KennoUserState;
  billingHistory: BillingHistory;
  currentBill: CurrentBill;
  currentTargets: CurrentTargets;
  contract: Contract;
  apartment: Apartment;
  building: Building;
}

export const initialState: KennoTenantState = {
  user: {
    isLoggedIn: false,
    isLoaded: false,
  },
  billingHistory: { filter: 'all', loadState: false },
  currentBill: { loadState: false },
  currentTargets: { loadState: false },
  contract: { loadState: false },
  apartment: { loadState: false },
  building: { loadState: false },
};

const KennoTenantStateContext = createContext({} as KennoTenantState);
const KennoTenantDispatchContext = createContext((() => {}) as Dispatcher);

function KennoTenantStateProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(kennoTenantReducer, initialState);
  useEffect(() => {
    loadUser(dispatch);
  }, [dispatch]);

  return (
    <KennoTenantStateContext.Provider value={state}>
      <KennoTenantDispatchContext.Provider value={dispatch}>{children}</KennoTenantDispatchContext.Provider>
    </KennoTenantStateContext.Provider>
  );
}

function useKennoTenantState() {
  const context = useContext(KennoTenantStateContext);
  if (context === undefined) {
    throw new Error('useKennoTenantState must be used within a KennoTenantStateProvider');
  }
  return context;
}

function useKennoTenantDispatch() {
  const context = useContext(KennoTenantDispatchContext);
  if (context === undefined) {
    throw new Error('useKennoTenantDispatch must be used within a KennoTenantStateProvider');
  }
  return context;
}

function useKennoTenant(): [KennoTenantState, Dispatcher] {
  return [useKennoTenantState(), useKennoTenantDispatch()];
}

export { KennoTenantStateProvider, useKennoTenantState, useKennoTenantDispatch, useKennoTenant };
