import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAppState } from '../context/appContext';
import { flattenMenuItems } from '../utils/wordpress';
import { useMessageGetter } from 'react-message-context';
import { theme } from '../theme';
import PaddedContent from './housing/PaddedContent';
import { removeLanguagePostfix } from '../pages/pageUtils';
import { DeviceSize } from './basic/DeviceSize';
import { Routes } from '../constants/routes';

/* Titles for pages that are not in navigation menu.
The key represents the pathname, and the value corresponds to the
translation property for that pathname. */
const fixedTitles: { [key: string]: string } = {
  'omat-tiedot': 'navigation.profileAlt.title',
  vaihto: 'change.swap.label',
  'uusi-hakemus': 'change.newapplication.label',
  haku: 'search.title',
  kokouspoytakirja: 'forms.meetingMinutes.title',
  toimintasuunnitelma: 'forms.actionPlan.title',
  toimintakertomus: 'forms.actionReport.title',
  kuluselvitys: 'forms.expenseReport.title',
  matkalasku: 'forms.travelExpenseReport.title',
  tapahtumakutsu: 'forms.eventInvite.title',
  kenno: 'kenno', // TODO: this is a temporary path, these will be removed when Kenno implementation is complete
};

const Breadcrumbs = () => {
  const location = useLocation();
  const translation = useMessageGetter('');
  const { menu } = useAppState();
  const menuItems = flattenMenuItems(menu.items);
  const isSearchPage = /^\/haku\/.*$/.test(location.pathname);

  if (location.pathname === Routes.Tenancy.PAYMENT_SUCCESS || location.pathname === Routes.Tenancy.PAYMENT_ERROR) {
    return null;
  }

  /* If page is in navigation menu, pathSegment is WordPress page slug.
  If page is Search page, remove dynamic section /:query from path. */
  const pathSegments = isSearchPage ? ['haku'] : location.pathname.split('/').filter((x) => x);

  return (
    <PaddedContent paddingTop="0" mobilePaddingTop="0">
      <nav>
        <BreadcrumbsList className="breadcrumbs">
          <BreadcrumbsItem>
            <Link to="/">{translation('navigation.home.title')}</Link>
          </BreadcrumbsItem>
          {menuItems.length &&
            pathSegments.map((pathSegment, index) => {
              // Construct the full path up to the current breadcrumb
              const to = `/${pathSegments.slice(0, index + 1).join('/')}`;
              const menuItem = menuItems.find((item) => removeLanguagePostfix(item.slug) === pathSegment);
              const isTopLevelItem = menuItem?.parentId === 0;

              return (
                <BreadcrumbsItem key={to} isTopLevelItem={isTopLevelItem}>
                  <Link to={to}>
                    {/* Use page title if found from menuItems, otherwise parse from pathname */}
                    {menuItem?.title || translation(fixedTitles[pathSegment])}
                  </Link>
                </BreadcrumbsItem>
              );
            })}
        </BreadcrumbsList>
      </nav>
    </PaddedContent>
  );
};

const BreadcrumbsList = styled.ul`
  display: flex;
  list-style-type: none;
  color: ${theme().colors.charcoal};
  font-family: ${theme().text.primaryFont};
  padding: 0;

  @media print {
    display: none;
  }
`;

const BreadcrumbsItem = styled.li<{ isTopLevelItem?: boolean }>`
  font-size: 16px;
  letter-spacing: 0.8px;
  z-index: 1;
  display: flex;
  align-items: start;

  @media ${DeviceSize.mobileL} {
    font-size: 12px;
    line-height: 18px;
  }

  & + &::before {
    width: 12px;
    height: 12px;
    background: url('/assets/chevron-right-coal.svg') no-repeat center center;
    background-size: contain;
    content: '';
    margin: 4px;
  }

  a {
    color: ${theme().colors.charcoal};
    text-decoration: underline;

    ${({ isTopLevelItem }) =>
      isTopLevelItem &&
      `
      pointer-events: none;
      text-decoration: none;
      cursor: default;
    `}
  }

  &:last-child {
    font-weight: bold;
    word-break: break-word;

    a {
      pointer-events: none;
      text-decoration: none;
      cursor: default;
    }
  }
`;

export default Breadcrumbs;
