import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { PasswordIsInvalidError, UsernameIsInvalidError } from './types/exceptions';
import { PaymentRequest } from './types/payment';

const instance = Axios.create();
const axios = setupCache(instance);

// M2 Kodit Backend endpoints
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export const REGISTER_INITIATE_ENDPOINT = backendUrl + '/register/initiate';
export const REGISTER_CREDENTIALS_ENDPOINT = backendUrl + '/register/credentials';
export const REGISTER_OTP_ENDPOINT = backendUrl + '/register/confirmation-code';
export const LOGIN_ENDPOINT = backendUrl + '/login';
export const LOGOUT_ENDPOINT = backendUrl + '/logout';
export const PROFILE_ENDPOINT = backendUrl + '/profile';
export const BILLING_HISTORY_ENDPOINT = backendUrl + '/billing/history';
export const CURRENT_BILL_ENDPOINT = backendUrl + '/billing/current';
export const CURRENT_TARGETS_ENDPOINT = backendUrl + '/billing/targets';
export const CURRENT_CONTRACT_ENDPOINT = backendUrl + '/contracts/current';
export const APARTMENT_ENDPOINT = backendUrl + '/apartment';
export const FORGOT_PASSWORD_CODE_ENDPOINT = backendUrl + '/forgot-password/code';
export const FORGOT_PASSWORD_RESET_ENDPOINT = backendUrl + '/forgot-password/reset';
export const BUILDING_ENDPOINT = backendUrl + '/building';
export const PAYMENT_ENDPOINT = backendUrl + '/payment';

export type LoginCredentials = {
  username: string;
  password: string;
};

export async function login(credentials: { username: string; password: string }) {
  await axios.post(LOGIN_ENDPOINT, credentials, {
    withCredentials: true,
    cache: false,
  });
}

export async function logout() {
  await axios.post(
    LOGOUT_ENDPOINT,
    {},
    {
      withCredentials: true,
      cache: false,
    }
  );
}

export async function initiateRegistration(data?: any): Promise<{ url: string; state: string }> {
  const response = await axios.post(REGISTER_INITIATE_ENDPOINT, data);
  if (response.status === 200) {
    const { url, state } = response.data;
    return { url, state };
  }
  throw new Error('Failed to initiate registration');
}

export async function submitRegistrationCredentials(
  username: string,
  password: string,
  state: string,
  registerSessionId: string
): Promise<{ nextStep: string }> {
  try {
    const response = await axios.post(
      REGISTER_CREDENTIALS_ENDPOINT,
      { username, password, state, registerSessionId },
      { withCredentials: true }
    );
    if (response.status === 200) {
      const { nextStep } = response.data;
      return { nextStep };
    }
  } catch (error: any) {
    if (error.response.status === 409) {
      throw new UsernameIsInvalidError();
    }
    if (error.response.status === 422) {
      throw new PasswordIsInvalidError();
    }
  }
  throw new Error('Failed to submit credentials');
}

export async function submitRegistrationConfirmationCode(
  confirmationCode: string,
  username: string
): Promise<{ nextStep: string }> {
  const response = await axios.post(REGISTER_OTP_ENDPOINT, { confirmationCode, username }, { withCredentials: true });
  if (response.status === 200) {
    const { nextStep } = response.data;
    return { nextStep };
  }
  throw new Error('Failed to submit confirmation code');
}
export async function getProfile() {
  const response = await axios.get(PROFILE_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get profile');
}

export async function getBillingHistory() {
  const response = await axios.get(BILLING_HISTORY_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get billing history');
}

export async function getCurrentBill() {
  const response = await axios.get(CURRENT_BILL_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get current bill');
}

export async function getCurrentTargets() {
  const response = await axios.get(CURRENT_TARGETS_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get current targets');
}

export async function getCurrentContract() {
  const response = await axios.get(CURRENT_CONTRACT_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get current contract');
}

export async function getApartment() {
  const response = await axios.get(APARTMENT_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get the apartment');
}

export async function sendResetPasswordCode(data: { username: string }) {
  const response = await axios.post(FORGOT_PASSWORD_CODE_ENDPOINT, data);
  if (response.status === 200) {
    return;
  }
  throw new Error('Failed to send reset password code to user');
}

export async function confirmNewPassword(data: { username: string; code: string; newPassword: string }) {
  const response = await axios.post(FORGOT_PASSWORD_RESET_ENDPOINT, data);
  if (response.status === 200) {
    return;
  }
  throw new Error('Failed to confirm new password');
}

export async function getBuilding() {
  const response = await axios.get(BUILDING_ENDPOINT, {
    withCredentials: true,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Failed to get building');
}

export async function createPayment(body: PaymentRequest) {
  const response = await axios.post(PAYMENT_ENDPOINT, body, {
    withCredentials: true,
  });
  if (response.status === 201) {
    return response.data;
  }
  throw new Error('Failed to create payment');
}
