import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { FileIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { FileInput, FileInputValue, FormActions, InputField, TextAreaElement } from '../../../components/common/forms';
import { scrollToTop } from '../../../utils';
import { FormPageContainer } from '../FormPageContainer';
import { Column, ContentBlock, Form, FormRow, GridContainer, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { ExpenseReportPreview } from './ExpenseReportPreview';
import { ExpenseReportFormState, schema } from './schema';

const ContactInfoContent = styled(ContentBlock)``;
const EventContent = styled(ContentBlock)``;
const PurchaseReportsContent = styled(ContentBlock)``;

const TotalAmount = styled.span`
  font-size: 24px;
  line-height: 36px;
  font-family: ${(props) => props.theme.text.secondaryFont};
`;

export const ExpenseReport = ({ history }: RouteComponentProps) => {
  const formMethods = useForm<ExpenseReportFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      purchaseReports: [{ report: '', amount: 0.0, receipt: undefined }],
    },
  });

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const t = useMessageGetter('forms.expenseReport');

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'purchaseReports',
  });

  const purchaseReports = formMethods.watch('purchaseReports');

  const handleAddNewPurchaseReport = () => {
    append({
      report: '',
      amount: 0.0,
      // This typecasting is a workaround for typescript error stating that receipt can't be undefined
      // This works differently than useForm's defaultValues in which the values can be null or undefined.
      // Unfortunately append function is strictly following the schema in which the values are required.
      // Discussion about the flaw here: https://github.com/orgs/react-hook-form/discussions/10211
      receipt: undefined as unknown as FileList,
    });
  };

  const handleEmptyForm = () => {
    formMethods.reset();
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
    scrollToTop();
  };

  const handleSave = () => {
    console.log('WIP: save');
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleSubmit = (e: any) => {
    console.log('WIP: onSubmit', e);
  };

  const translationPrefix = 'forms.expenseReport';

  const totalExpensesAmount = purchaseReports.reduce((sum, obj) => sum + obj.amount, 0).toFixed(2);

  return (
    <FormPageContainer<ExpenseReportFormState> history={history} formMethods={formMethods}>
      {previewOpen ? (
        <ExpenseReportPreview closePreview={handleClosePreview} />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <H2>{t('title')}</H2>
          <span>{t('description')}</span>
          <ContactInfoContent>
            <H3>{t('contactInfo.title')}</H3>
            <div>
              <FormRow>
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.name"
                  type="text"
                  required
                />
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.ssn"
                  type="text"
                  required
                />
              </FormRow>
              <InputField<ExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="contactInfo.address"
                type="text"
                required
              />
              <FormRow>
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.zip"
                  type="text"
                  required
                />
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.city"
                  type="text"
                  required
                />
              </FormRow>
              <FormRow>
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.phone"
                  type="text"
                  required
                />
                <InputField<ExpenseReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="contactInfo.email"
                  type="text"
                  required
                />
              </FormRow>
              <InputField<ExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="contactInfo.iban"
                type="text"
                required
              />
            </div>
          </ContactInfoContent>
          <EventContent>
            <H3>{t('event.title')}</H3>
            <div>
              <InputField<ExpenseReportFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="event.name"
                type="text"
                required
              />
              <InputField<ExpenseReportFormState, string>
                fieldPath="event.date"
                translationPrefix={translationPrefix}
                type="date"
                width="50%"
                required
              />
              <InputField<ExpenseReportFormState, string>
                fieldPath="event.additionalInformation"
                translationPrefix={translationPrefix}
                inputElementRenderer={(props) => <TextAreaElement {...props} />}
              />
            </div>
          </EventContent>
          <PurchaseReportsContent>
            <H3>{t('purchaseReports.title')}</H3>
            <span>{t('purchaseReports.description')}</span>
            <div>
              {fields.map((field, index) => {
                const fileName = purchaseReports[index]?.receipt?.[0]?.name;
                return (
                  <div key={field.id} style={{ marginBottom: '1rem' }}>
                    <GridContainer columns={4} mdColumns={1} columnGap="20px">
                      <Column span={3} mdSpan={1}>
                        <InputField<ExpenseReportFormState, string>
                          fieldPath={`purchaseReports.${index}.report`}
                          translationPrefix={translationPrefix}
                          type="text"
                          required
                        />
                      </Column>
                      <Column>
                        <InputField<ExpenseReportFormState, string>
                          translationPrefix={translationPrefix}
                          fieldPath={`purchaseReports.${index}.amount`}
                          required
                          type="number"
                          step="0.01"
                        />
                      </Column>
                    </GridContainer>
                    <FormRow>
                      <InputField<ExpenseReportFormState, FileInputValue>
                        translationPrefix={translationPrefix}
                        hideLabel
                        fieldPath={`purchaseReports.${index}.receipt`}
                        inputElementRenderer={(props) => (
                          <FormRow>
                            <FileInput onChange={props.onChange} label={t('purchaseReports.addReceipt')} />
                            {fileName && (
                              <Row style={{ alignItems: 'center' }}>
                                <FileIcon style={{ flexShrink: 0 }} />
                                <TextBlock>{fileName}</TextBlock>
                              </Row>
                            )}
                          </FormRow>
                        )}
                      />
                    </FormRow>
                    {fields.length > 1 && (
                      <Button style={{ marginTop: '24px' }} primary onClick={() => remove(index)}>
                        {t('purchaseReports.removePurchase')}
                      </Button>
                    )}
                  </div>
                );
              })}
              <FormRow>
                <Button type="button" icon="plus" onClick={handleAddNewPurchaseReport}>
                  {t('purchaseReports.addNewPurchase')}
                </Button>
              </FormRow>
            </div>

            <TotalAmount>{t('purchaseReports.total', { amount: totalExpensesAmount })}</TotalAmount>
          </PurchaseReportsContent>
          <FormActions
            handleEmptyForm={handleEmptyForm}
            handleOpenPreview={handleOpenPreview}
            handleSave={handleSave}
            actionButtonType="send"
          />
        </Form>
      )}
    </FormPageContainer>
  );
};
