import React from 'react';
import { useMessageGetter } from 'react-message-context';
import { Dropdown, DropdownProps } from '../Dropdown';

export const PublicationDurationDropdown = ({ onChange, value, id }: Omit<DropdownProps, 'options' | 'width'>) => {
  const t = useMessageGetter('input.dropdown');

  return (
    <Dropdown
      options={[
        { label: t('monthOption', { amount: 12 }), value: 12 },
        { label: t('monthOption', { amount: 24 }), value: 24 },
      ]}
      onChange={onChange}
      value={value}
      id={id}
      width="230px"
    />
  );
};
