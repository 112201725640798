import { BillingMonth } from '../types/tampuuri';
import { LOAD_STATES } from './common';
import { KENNO_TENANT_ACTIONS, KENNO_TENANT_EVENTS } from './kennoTenantActions';
import { KennoTenantState, initialState } from './kennoTenantContext';

export function kennoTenantReducer(
  state: KennoTenantState,
  [type, payload]: [KENNO_TENANT_EVENTS | KENNO_TENANT_ACTIONS, any?]
): KennoTenantState {
  switch (type) {
    case KENNO_TENANT_ACTIONS.LOGIN:
      return initialState;
    case KENNO_TENANT_EVENTS.LOGGEDIN:
      return {
        ...state,
        user: {
          isLoggedIn: true,
          isLoaded: true,
          attributes: {
            ...payload,
            // Fallback to true, until this is implemented in the backend
            visited: payload?.visited ? payload.visited : true,
          },
        },
      };

    case KENNO_TENANT_ACTIONS.LOGOUT:
      return {
        ...state,
        user: { ...state.user, isLoaded: false },
      };
    case KENNO_TENANT_EVENTS.LOGGEDOUT:
      return {
        ...state,
        user: { isLoggedIn: false, isLoaded: true },
      };

    case KENNO_TENANT_ACTIONS.LOAD_BILLING_HISTORY:
      return {
        ...state,
        billingHistory: { ...state.billingHistory, loadState: LOAD_STATES.BUSY },
      };
    case KENNO_TENANT_EVENTS.LOAD_BILLING_HISTORY_SUCCESS:
      payload.sort((a: BillingMonth, b: BillingMonth) => +(a.startDate < b.startDate) - +(a.startDate > b.startDate));

      const num = payload.length && payload[0].year * 12 + payload[0].month;

      return {
        ...state,
        billingHistory: {
          ...state.billingHistory,
          items: payload,
          rangeEnd: num,
          rangeStart: num - 2,
          loadState: LOAD_STATES.READY,
        },
      };
    case KENNO_TENANT_EVENTS.LOAD_BILLING_HISTORY_FAILURE:
      return {
        ...state,
        billingHistory: {
          ...state.billingHistory,
          items: [],
          loadState: LOAD_STATES.ERROR,
        },
      };

    case KENNO_TENANT_ACTIONS.SET_BILLING_FILTER:
      return {
        ...state,
        billingHistory: { ...state.billingHistory, filter: payload },
      };
    case KENNO_TENANT_ACTIONS.SET_BILLING_RANGE_START:
      return {
        ...state,
        billingHistory: { ...state.billingHistory, rangeStart: payload },
      };
    case KENNO_TENANT_ACTIONS.SET_BILLING_RANGE_END:
      return {
        ...state,
        billingHistory: { ...state.billingHistory, rangeEnd: payload },
      };

    case KENNO_TENANT_ACTIONS.LOAD_CURRENT_BILL:
      return {
        ...state,
        currentBill: { ...state.currentBill, loadState: LOAD_STATES.BUSY },
      };
    case KENNO_TENANT_EVENTS.LOAD_CURRENT_BILL_SUCCESS:
      if (!payload) {
        return {
          ...state,
          currentBill: {
            ...state.currentBill,
            loadState: LOAD_STATES.READY,
          },
        };
      }

      const dueDate = payload.dueDate ? new Date(payload.dueDate) : null;
      return {
        ...state,
        currentBill: {
          ...state.currentBill,
          totalRent: payload.totalRent,
          difference: payload.difference,
          debt: payload.debt,
          dueDate,
          loadState: LOAD_STATES.READY,
        },
      };
    case KENNO_TENANT_EVENTS.LOAD_CURRENT_BILL_FAILURE:
      return {
        ...state,
        currentBill: {
          ...state.currentBill,
          loadState: LOAD_STATES.ERROR,
        },
      };

    case KENNO_TENANT_ACTIONS.LOAD_CURRENT_TARGETS:
      return {
        ...state,
        currentTargets: { ...state.currentTargets, loadState: LOAD_STATES.BUSY },
      };
    case KENNO_TENANT_EVENTS.LOAD_CURRENT_TARGETS_SUCCESS:
      return {
        ...state,
        currentTargets: {
          ...state.currentTargets,
          data: payload,
          loadState: LOAD_STATES.READY,
        },
      };
    case KENNO_TENANT_EVENTS.LOAD_CURRENT_TARGETS_FAILURE:
      return {
        ...state,
        currentTargets: {
          ...state.currentTargets,
          loadState: LOAD_STATES.ERROR,
        },
      };

    case KENNO_TENANT_ACTIONS.LOAD_CONTRACT:
      return {
        ...state,
        contract: { ...state.contract, loadState: LOAD_STATES.BUSY },
      };
    case KENNO_TENANT_EVENTS.LOAD_CONTRACT_SUCCESS:
      const startDate = payload.startDate ? new Date(payload.startDate) : null;
      const endDate = payload.endDate ? new Date(payload.endDate) : null;
      return {
        ...state,
        contract: {
          ...state.contract,
          ...payload,
          startDate,
          endDate,
          loadState: LOAD_STATES.READY,
        },
      };
    case KENNO_TENANT_EVENTS.LOAD_CONTRACT_FAILURE:
      return {
        ...state,
        contract: {
          ...state.contract,
          loadState: LOAD_STATES.ERROR,
        },
      };

    case KENNO_TENANT_ACTIONS.LOAD_APARTMENT:
      return {
        ...state,
        apartment: { ...state.apartment, loadState: LOAD_STATES.BUSY },
      };

    case KENNO_TENANT_EVENTS.LOAD_APARTMENT_SUCCESS:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          data: payload,
          loadState: LOAD_STATES.READY,
        },
      };

    case KENNO_TENANT_EVENTS.LOAD_APARTMENT_FAILURE:
      return {
        ...state,
        apartment: {
          ...state.apartment,
          loadState: LOAD_STATES.ERROR,
        },
      };

    case KENNO_TENANT_ACTIONS.LOAD_BUILDING:
      return {
        ...state,
        building: { ...state.building, loadState: LOAD_STATES.BUSY },
      };
    case KENNO_TENANT_EVENTS.LOAD_BUILDING_SUCCESS:
      return {
        ...state,
        building: {
          ...state.building,
          ...payload,
          loadState: LOAD_STATES.READY,
        },
      };
    case KENNO_TENANT_EVENTS.LOAD_BUILDING_FAILURE:
      return {
        ...state,
        building: {
          ...state.building,
          loadState: LOAD_STATES.ERROR,
        },
      };

    default:
      console.log('Unknown event', type);
      return state;
  }
}
