import { getMenuItems } from '../utils/wordpress';
import { NotificationType } from './appContext';

export enum ACTIONS {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  LOAD_MENU,
  SET_LANGUAGE,
}

export enum EVENTS {
  LOAD_MENU_SUCCESS = 3000,
  LOAD_MENU_FAILURE,
}

export type Dispatcher = React.Dispatch<[EVENTS | ACTIONS, any?]>;

export async function showNotification(dispatch: Dispatcher, message: string, type: NotificationType) {
  const payload = { message, type };
  dispatch([ACTIONS.SHOW_NOTIFICATION, payload]);
  setTimeout(() => dispatch([ACTIONS.HIDE_NOTIFICATION, message]), 10000);
}

export async function loadMenu(dispatch: Dispatcher, language: string) {
  dispatch([ACTIONS.LOAD_MENU]);
  try {
    const menu = await getMenuItems(language);
    const menuItems = { items: menu };
    dispatch([EVENTS.LOAD_MENU_SUCCESS, menuItems]);
  } catch {
    dispatch([EVENTS.LOAD_MENU_FAILURE]);
  }
}

export async function setLanguage(dispatch: Dispatcher, language: string) {
  dispatch([ACTIONS.SET_LANGUAGE, language]);
  sessionStorage.setItem('currentLanguage', language);
  loadMenu(dispatch, language);
}
