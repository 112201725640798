import React from 'react';
import styled from 'styled-components/macro';
import { errorFilledIcon } from '../../../assets';
import { theme } from '../../../theme';
import { BasicText } from '../Text';

export const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;
  max-width: 800px;
  min-height: 50px;
  background-color: white;
  border: 2px solid ${theme().colors.carrot};
  border-radius: 16px;
  font-size: 16px;
  margin: 10px 0 24px 0;
  padding: 10px;
`;

const FormError: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Container>
      <img src={errorFilledIcon} alt="" />
      <BasicText>{message}</BasicText>
    </Container>
  );
};

export default FormError;
