import React from 'react';
import { useMessageGetter } from 'react-message-context';
import PageContainer from '../PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import PaddedContent from '../../components/housing/PaddedContent';
import Newsletter from './Newsletter';
import Liftup from '../../components/Liftup';
import BasicTenancyInfo from './BasicTenancyInfo';
import CardGrid from './CardGrid';
import RentCard from './RentCard';
import M2NewsCard from './M2NewsCard';
import { LinkButtonWithChevron } from '../../components/common';
import { Slugs } from '../../constants/knownSlugs';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { useAppState } from '../../context/appContext';
import { LinkContainer } from '../layoutComponents';
import ContactCard from './ContactCard';

const HomePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('');
  const { menu } = useAppState();
  // const c = useMessageGetter('footer.housing.customerservice');
  // const msgHousing = useMessageGetter('housing');
  // const { language } = useAppState();

  // const fetchResidentPricing = (manuals: any) => {
  //   const residentPricing = manuals
  //     .filter((manual: any) => manual.folder === '1.6.1 Oma talo')
  //     .map((folder: any) => {
  //       return folder.items.filter((item: any) => item.name === 'Asukaslaskutushinnasto_M2_2022');
  //     });
  //   if (residentPricing) return residentPricing[0];
  //   else return null;
  // };

  // TODO: is this expiring contract page in release scope?
  // if (hasExpiringAgreement(user)) {
  //   return (
  //     <PageContainer history={history}>
  //       <PaddedContent>
  //         <WordPressContent
  //           slug={getContentBlockSlug(BlockSlugs.EXPIRED_AGREEMENT, language)}
  //           postType={WpPostType.CONTENT_BLOCKS}
  //         />
  //         {documents.manuals && (
  //           <ResidentPricingContainer>
  //             <DocumentList
  //               t={msgHousing}
  //               header={t('home.residentpricelist')}
  //               type="residentpricelist"
  //               limit={6}
  //               columns={3}
  //               prefix="• "
  //               contents={fetchResidentPricing(documents.manuals)}
  //             />
  //           </ResidentPricingContainer>
  //         )}
  //         {housing && <HousingContact housingContacts={housing} t={msgHousing} c={c} expiringContract={true} />}
  //         <Newsletter
  //           givenName={user.attributes.given_name}
  //           lastName={user.attributes.family_name}
  //           email={user.attributes.email}
  //         />
  //       </PaddedContent>
  //     </PageContainer>
  //   );
  // }

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <Liftup>
          <BasicTenancyInfo />
        </Liftup>
        <LinkContainer>
          <LinkButtonWithChevron to={getMenuItemPathBySlug(menu.items, Slugs.BILLING)} icon="euroOutlined">
            {t('billing.history.title')}
          </LinkButtonWithChevron>
          <LinkButtonWithChevron to={getMenuItemPathBySlug(menu.items, Slugs.PAYMENT)} icon="euroOutlined">
            {t('billing.payment.title')}
          </LinkButtonWithChevron>
          <LinkButtonWithChevron to={getMenuItemPathBySlug(menu.items, Slugs.COMMITTEE)} icon="starOutlined">
            {t('living.committee.title')}
          </LinkButtonWithChevron>
          <LinkButtonWithChevron to={getMenuItemPathBySlug(menu.items, Slugs.CONTACT_INFO)} icon="infoOutlined">
            {t('housing.contacts.title')}
          </LinkButtonWithChevron>
        </LinkContainer>
      </PaddedContent>

      <PaddedContent>
        <CardGrid>
          <RentCard />
          {/* TODO: move the modules and bookings to the Kenno context and return this */}
          {/* {modules.modules && modules.modules.length > 0 && <BookingsCard />} */}
          {/* TODO: add this back when the building contacts are available to fetch */}
          <ContactCard />
          {/*<Card
            title={t('home.grid.events.title')}
            mainButton={
              <LinkButton size="small" background="transparent" iconRight icon="arrowForward" to={Slugs.EVENTS}>
                {t('home.grid.events.action.label')}
              </LinkButton>
            }
          />
          <Card
            title={t('home.grid.events_for_all.title')}
            mainButton={
              <LinkButton size="small" background="transparent" iconRight icon="arrowForward" to={Slugs.EVENTS_FOR_ALL}>
                {t('home.grid.events_for_all.action.label')}
              </LinkButton>
            }
          />*/}
          <M2NewsCard />
          {/* <FaultReportsCard /> */}
        </CardGrid>
      </PaddedContent>
      <Newsletter />
    </PageContainer>
  );
};

// const ResidentPricingContainer = styled.div`
//   white-space: nowrap;
//   border-radius: 24px;
//   padding: 48px;
//   padding-bottom: 24px;
//   margin: 36px 0;
//   background: ${(props) => props.theme.colors.lemon};
// `;

export default HomePage;
