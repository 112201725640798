import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { Button, Checkbox } from '../../../components/common';
import { DropdownOptionValue } from '../../../components/common/Dropdown';
import {
  FileInput,
  FileInputValue,
  FormActions,
  InputField,
  PublicationDurationDropdown,
  TextAreaElement,
  TimeDropdown,
} from '../../../components/common/forms';
import { scrollToTop } from '../../../utils';
import { FormPageContainer } from '../FormPageContainer';
import { ContentBlock, Form, FormRow, RequiredIndicator, Column, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { MeetingMinutesPreview } from './MeetingMinutesPreview';
import { MeetingMinutesFormState, schema } from './schema';
import { FileIcon } from '../../../assets';

const MeetingDetailsContent = styled(ContentBlock)``;
const MeetingContent = styled(ContentBlock)``;
const SignatureContent = styled(ContentBlock)``;
const PublicationContent = styled(ContentBlock)``;

export const MeetingMinutes = ({ history }: RouteComponentProps) => {
  const formMethods = useForm<MeetingMinutesFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      items: [{ title: '', text: '' }],
      publicationDuration: 12,
      signatoryConfirmation: false,
      participants: 0,
    },
  });

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const t = useMessageGetter('forms.meetingMinutes');

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'items',
  });

  const handleSubmit = async (e: any) => {
    console.log('WIP: onSubmit', e);
  };

  const handleAddNewHeading = () => {
    append({ title: '', text: '' });
  };

  const handleEmptyForm = () => {
    formMethods.reset();
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
    scrollToTop();
  };

  const handleSave = () => {
    console.log('WIP: save');
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const translationPrefix = 'forms.meetingMinutes';
  const attachment = formMethods.watch('attachment');
  const attachmentFilename = attachment && attachment[0]?.name;

  return (
    <FormPageContainer<MeetingMinutesFormState> history={history} formMethods={formMethods}>
      {previewOpen ? (
        <MeetingMinutesPreview closePreview={handleClosePreview} />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <MeetingDetailsContent>
            <H2>{t('title')}</H2>
            <span>
              {t('description.part1')} (<RequiredIndicator>*</RequiredIndicator>) {t('description.part2')}
            </span>
            <div>
              <FormRow>
                <InputField<MeetingMinutesFormState, string>
                  fieldPath="name"
                  placeholder={t('name')}
                  translationPrefix={translationPrefix}
                  type="text"
                  required
                />
              </FormRow>
              <FormRow>
                <InputField<MeetingMinutesFormState, string>
                  fieldPath="date"
                  translationPrefix={translationPrefix}
                  type="date"
                  required
                />
                <InputField<MeetingMinutesFormState, DropdownOptionValue>
                  fieldPath="time"
                  translationPrefix={translationPrefix}
                  required
                  inputElementRenderer={(props) => <TimeDropdown {...props} width="230px" />}
                />
              </FormRow>
              <FormRow>
                <InputField<MeetingMinutesFormState, string>
                  fieldPath="location"
                  placeholder={t('locationPlaceholder')}
                  translationPrefix={translationPrefix}
                  type="text"
                  required
                />
              </FormRow>
              <FormRow>
                <InputField<MeetingMinutesFormState, number>
                  fieldPath="participants"
                  translationPrefix={translationPrefix}
                  type="number"
                  required
                  style={{ width: '25%' }}
                />
              </FormRow>
              <FormRow>
                <InputField<MeetingMinutesFormState, FileInputValue>
                  translationPrefix={translationPrefix}
                  hideLabel
                  fieldPath="attachment"
                  inputElementRenderer={(props) => (
                    <FormRow>
                      <FileInput onChange={props.onChange} label={t('attachment')} />
                      <Column style={{ marginTop: '10px' }} gap="4px">
                        {attachmentFilename && (
                          <Row>
                            <FileIcon style={{ flexShrink: 0 }} />
                            <TextBlock>{attachmentFilename}</TextBlock>
                          </Row>
                        )}
                        <span style={{ marginTop: '10px' }}>{t('attachmentPublicationNote')}</span>
                      </Column>
                    </FormRow>
                  )}
                />
              </FormRow>
            </div>
          </MeetingDetailsContent>

          <MeetingContent>
            <H3>{t('content.title')}</H3>
            {t('content.description')}
            {fields.map((field, index) => (
              <div key={field.id} style={{ marginBottom: '1rem' }}>
                <InputField<MeetingMinutesFormState, string>
                  fieldPath={`items.${index}.title`}
                  translationPrefix={translationPrefix}
                  placeholder={t('items.title')}
                  type="text"
                  required
                />

                <InputField<MeetingMinutesFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath={`items.${index}.text`}
                  required
                  inputElementRenderer={(props) => (
                    <TextAreaElement {...props} placeholder={t('items.textPlaceholder')} />
                  )}
                />
                {fields.length > 1 && (
                  <Button primary onClick={() => remove(index)}>
                    {t('removeHeading')}
                  </Button>
                )}
              </div>
            ))}
            <FormRow>
              <Button type="button" icon="plus" onClick={handleAddNewHeading}>
                {t('addNewHeading')}
              </Button>
            </FormRow>
          </MeetingContent>

          <SignatureContent>
            <H3>{t('signatures.title')}</H3>
            <div>
              <InputField<MeetingMinutesFormState, string>
                fieldPath="chairperson"
                translationPrefix={translationPrefix}
                placeholder={t('signaturePlaceholder')}
                type="text"
                required
              />
              <InputField<MeetingMinutesFormState, string>
                fieldPath="secretary"
                translationPrefix={translationPrefix}
                placeholder={t('signaturePlaceholder')}
                type="text"
                required
              />
            </div>
          </SignatureContent>

          <PublicationContent>
            <H3>{t('publication.title')}</H3>
            <div>
              <InputField<MeetingMinutesFormState, DropdownOptionValue>
                fieldPath="publicationDuration"
                translationPrefix={translationPrefix}
                inputElementRenderer={(props) => <PublicationDurationDropdown {...props} />}
              />
              <InputField<MeetingMinutesFormState, DropdownOptionValue>
                fieldPath="signatoryConfirmation"
                translationPrefix={translationPrefix}
                hideLabel
                inputElementRenderer={({ value, id, onChange }) => (
                  <FormRow style={{ gap: '12px' }}>
                    {/* TODO: fix types.. */}
                    <Checkbox value={!!value} id={id} onChange={onChange} />
                    {t('signatoryConfirmation.description')}
                  </FormRow>
                )}
              />
            </div>
          </PublicationContent>

          <FormActions
            handleEmptyForm={handleEmptyForm}
            handleOpenPreview={handleOpenPreview}
            handleSave={handleSave}
          />
        </Form>
      )}
    </FormPageContainer>
  );
};
