import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { Button } from '../../../components/common';
import { FormPreviewActions } from '../../../components/common/forms/FormPreviewActions';
import { useFormattedClockTime } from '../../../hooks';
import { formatISODate } from '../../../utils';
import { PreviewContentBlock, Row } from '../layoutComponents';
import { Bold, H2, H4 } from '../Text';
import { ActionPlanFormState } from './schema';

const PreviewContent = styled(PreviewContentBlock)`
  gap: 40px;
`;

const EventSummary = styled(PreviewContentBlock)`
  gap: 20px;
`;

const Events = styled(PreviewContentBlock)`
  gap: 40px;
`;
const Event = styled(PreviewContentBlock)`
  gap: 16px;
`;

type Props = { closePreview: () => void };

export const ActionPlanPreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<ActionPlanFormState>();
  const t = useMessageGetter('forms');
  const { formatClockTime } = useFormattedClockTime();

  const formData = watch();

  const totalEventCountText =
    formData.activities.length === 1
      ? t('actionPlan.eventCount', { count: formData.activities.length })
      : t('actionPlan.eventCount_plural', { count: formData.activities.length });

  const totalBudgetEstimate = formData.activities.reduce((sum, obj) => sum + obj.budgetEstimate, 0).toFixed(2);

  const formatDate = (year: number | undefined, month: number | undefined, day: number | undefined) => {
    if (year && month && day) {
      const isoString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

      return formatISODate(isoString, true);
    }
    return '';
  };

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {t('closePreview')}
        </Button>
      </Row>
      <H2>{t('actionPlan.previewTitle', { year: formData.year })}</H2>
      <EventSummary>
        <span>{totalEventCountText}</span>
        <Row>
          <Bold>{t('actionPlan.totalBudgetEstimate')}</Bold>
          <span>{`${totalBudgetEstimate}€`}</span>
        </Row>
      </EventSummary>
      <Events>
        {formData.activities.map((event) => (
          <Event id={event.name}>
            <H4>{event.name}</H4>
            <Row>{`${formatDate(formData.year, event.month, event.day)} ${formatClockTime(event.time)}`}</Row>
            <Row>
              <Bold>{`${t('actionPlan.activities.responsiblePersons')}:`}</Bold>
              {event.responsiblePersons}
            </Row>
            <Row>
              <Bold>{`${t('actionPlan.budgetEstimate')}:`}</Bold>
              {`${event.budgetEstimate}€`}
            </Row>
          </Event>
        ))}
      </Events>
      <FormPreviewActions handleEdit={closePreview} handleSubmit={() => console.log('wip')} />
    </PreviewContent>
  );
};
