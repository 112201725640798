import React from 'react';
import { Dropdown, DropdownProps } from '../Dropdown';

const HOURS_IN_DAY = 24;
const QUARTER_LENGTH_MINUTES = 15;
const QUARTERS_PER_HOUR = 4;

export const TimeDropdown = ({ onChange, value, id, width, hasError }: Omit<DropdownProps, 'options'>) => {
  const options = [];
  for (let hour = 0; hour < HOURS_IN_DAY; ++hour) {
    for (let quarter = 0; quarter < QUARTERS_PER_HOUR; quarter++) {
      const hourFormatted = String(hour).padStart(2, '0');
      const minutes = quarter * QUARTER_LENGTH_MINUTES;
      const minuteFormatted = String(minutes).padStart(2, '0');

      const label = `${hourFormatted}:${minuteFormatted}`;
      const value = `${hourFormatted}:${minuteFormatted}`;

      options.push({ label, value });
    }
  }

  return <Dropdown options={options} onChange={onChange} value={value} id={id} width={width} hasError={hasError} />;
};
