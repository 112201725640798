import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../../basic/DeviceSize';
import { Button } from '..';
import { useMessageGetter } from 'react-message-context';

const FormActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const ActionButtonsRow = styled(FormRow)`
  margin-top: 8px;
  padding-top: 24px;
  justify-content: end;
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

type Props = {
  handleEdit: () => void;
  handleSubmit: () => void;
  actionButtonType?: 'publish' | 'send';
};

export const FormPreviewActions = ({ handleEdit, handleSubmit, actionButtonType = 'publish' }: Props) => {
  const t = useMessageGetter('forms');
  return (
    <FormActionsContainer>
      <ActionButtonsRow>
        <FormRow>
          <Button onClick={handleEdit} icon="edit" type="button" id="preview">
            {t('edit')}
          </Button>
        </FormRow>
        {actionButtonType === 'publish' && (
          <Button primary icon="shareFilled" type="button" onClick={handleSubmit} id="publish">
            {t('publish')}
          </Button>
        )}
        {actionButtonType === 'send' && (
          <Button primary icon="send2" type="button" onClick={handleSubmit} id="send">
            {t('send')}
          </Button>
        )}
      </ActionButtonsRow>
    </FormActionsContainer>
  );
};
