import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../../basic/DeviceSize';
import { Button } from '..';
import { useMessageGetter } from 'react-message-context';

const FormActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const ActionButtonsRow = styled(FormRow)`
  margin-top: 8px;
  padding-top: 24px;
  justify-content: space-between;
  border-top: 1px solid black;
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
  }
`;

const EmptyFormText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

type Props = {
  handleOpenPreview: () => void;
  handleSave: () => void;
  handleEmptyForm: () => void;
  actionButtonType?: 'publish' | 'send';
};

export const FormActions = ({
  handleOpenPreview,
  handleSave,
  handleEmptyForm,
  actionButtonType = 'publish',
}: Props) => {
  const t = useMessageGetter('forms');
  return (
    <FormActionsContainer>
      <ActionButtonsRow>
        <FormRow>
          <Button onClick={handleOpenPreview} icon="eye" type="button" id="preview">
            {t('preview')}
          </Button>
          <Button onClick={handleSave} icon="saveOutlined" type="button" id="save">
            {t('save')}
          </Button>
        </FormRow>
        {actionButtonType === 'publish' && (
          <Button primary icon="shareFilled" type="submit" id="publish">
            {t('publish')}
          </Button>
        )}
        {actionButtonType === 'send' && (
          <Button primary icon="send2" type="submit" id="send">
            {t('send')}
          </Button>
        )}
      </ActionButtonsRow>
      <FormRow>
        <EmptyFormText onClick={handleEmptyForm}>{t('emptyForm')}</EmptyFormText>
      </FormRow>
    </FormActionsContainer>
  );
};
