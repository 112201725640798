import React, { FunctionComponent, useEffect, useState } from 'react';
import { DeviceSize } from '../basic/DeviceSize';
import styled, { css } from 'styled-components/macro';
import { baseFontStyle } from '../basic/Text';
import HomeLogo from './HomeLogo';
import SideMenu from './SideMenu';
import { History } from 'history';
import BackgroundLogo from './BackgroundLogo';

import MainMenuItem from './mainmenu/MainMenuItem';
import { MenuItem } from '../../types/wordpress';
import { theme } from '../../theme';
import ProfileMenu from './ProfileMenu';
import HeaderContact from './HeaderContact';
import { RadioButton, RadioButtonRow } from '../button/ButtonLegacy';
import { chevronDownBlack, closeMenuIcon, hamburgerIcon, person, personNoBg } from '../../assets';
import LocalizationButtons from './LocalizationButtons';
import { useKennoTenant } from '../../context/kennoTenantContext';

const Header: FunctionComponent<{ history: History; menuItems: MenuItem[] }> = ({ history, menuItems }) => {
  const [{ user }] = useKennoTenant();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  // Enable closing menus with Escape
  useEffect(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsSideMenuOpen(false);
        setIsProfileMenuOpen(false);
      }
    });
  });

  // const onSearch = (query: string) => {
  //   ReactGA.event({
  //     category: 'Home page',
  //     action: 'Search',
  //     label: query,
  //   });
  //   history.push(Routes.SEARCH.replace(':query', encodeURIComponent(query)));
  // };

  return (
    <header>
      <BackgroundLogo />
      <NaviWrapper>
        <MainNaviHeader>
          <HeaderContact />
          <HomeLogo />
          <NaviDesktopRightSideContainer>
            <NaviDesktopRightSideTopContainer>
              <LocalizationButtons />
              {/* TODO: bring this back when search is overhauled */}
              {/* <HeaderSearchContainer>
                <SearchField onSearch={onSearch} backgroundColor={theme().colors.darkSnow} />
              </HeaderSearchContainer> */}
              <ProfileNavContainer>
                <ProfileNav
                  aria-expanded={isProfileMenuOpen}
                  aria-controls="profileMenu"
                  aria-label="Toggle profile menu"
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                >
                  <img alt="" src={personNoBg} />
                  {`${user.attributes?.firstName} ${user.attributes?.lastName}`}
                  <img width="16px" height="16px" alt="" src={chevronDownBlack}></img>
                </ProfileNav>
              </ProfileNavContainer>
            </NaviDesktopRightSideTopContainer>
            <MainMenu role="navigation" aria-label="Main">
              {menuItems.map((menuItem) => (
                <MainMenuItem key={menuItem.slug} title={menuItem.title} subMenuItems={menuItem.children} />
              ))}
            </MainMenu>
          </NaviDesktopRightSideContainer>
          <NaviMobileRightSideContainer isSideMenuOpen={isSideMenuOpen}>
            <RadioButtonRow>
              <RadioButton
                aria-label="Toggle profile menu"
                aria-expanded={isProfileMenuOpen}
                aria-controls="profileMenu"
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
              >
                <img alt="Person" src={person} />
              </RadioButton>
              {isSideMenuOpen ? (
                <RadioButton aria-label="Close the side menu" onClick={() => setIsSideMenuOpen(false)}>
                  <img alt="Close menu button" src={closeMenuIcon} />
                </RadioButton>
              ) : (
                <RadioButton aria-label="Open the side menu" onClick={() => setIsSideMenuOpen(true)}>
                  <img alt="Open menu button" src={hamburgerIcon} />
                </RadioButton>
              )}
            </RadioButtonRow>
          </NaviMobileRightSideContainer>
          <ProfileMenu active={isProfileMenuOpen} closeMenu={() => setIsProfileMenuOpen(false)} />
        </MainNaviHeader>
        <SideMenu isOpen={isSideMenuOpen} menuItems={menuItems} closeMenu={() => setIsSideMenuOpen(false)} />
      </NaviWrapper>
    </header>
  );
};

const NaviWrapper = styled.div`
  padding: 0 30px;

  @media ${DeviceSize.desktopS} {
    padding: 0 16px;
  }
`;

const MainNaviHeader = styled.div`
  padding-top: 24px;
  position: relative;
  width: 100%;
  max-width: 1256px;
  margin: 0 auto 2rem auto;
  height: ${theme().layout.headerHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${DeviceSize.desktopS} {
    height: ${theme().layout.mobileHeaderHeight}px;
    margin: 0;
  }
`;

const NaviDesktopRightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;

  @media ${DeviceSize.desktopS} {
    display: none;
  }
`;
const NaviDesktopRightSideTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${DeviceSize.desktopS} {
    display: none;
  }
`;

const NaviMobileRightSideContainer = styled.div<{ isSideMenuOpen: boolean }>`
  display: none;
  flex-direction: row;
  z-index: 12;

  @media ${DeviceSize.desktopS} {
    display: flex;
  }

  @media print {
    display: none;
  }

  ${({ isSideMenuOpen }) =>
    isSideMenuOpen &&
    css`
      position: fixed;
      top: 24px;
      right: 16px;
      height: 64px;
      align-items: center;
    `}
`;

// const HeaderSearchContainer = styled.div`
//   margin-right: 16px;
// `;

const MainMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-basis: 100% !important;
  list-style-type: none;
  margin: 0;
  height: 100%;
  flex: 1;
  position: relative;
  z-index: 2;
`;

const ProfileNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;

  @media print {
    display: none;
  }
`;

const ProfileNav = styled.button`
  ${baseFontStyle};
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export default Header;
