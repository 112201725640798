const HEX_OPACITY_20 = '33';

const colors = {
  mint: '#71CFCB',
  lemon: '#ffe64b',
  carrot: '#ed691c',
  peppermint: '#b6dcd5',
  peppermintLight: '#e9f4f2',
  strawberry: '#ff9378',
  coal: '#05262c',
  turquoise: '#02353d',
  blueGray: '#2b393b',
  apila: '#8CD280',
  camel: '#dda46f',
  black: '#000000',
  m2Black: '#0f0f0f',
  m2Black20: `#0f0f0f${HEX_OPACITY_20}`,
  white: '#fffcf2',
  m2White: '#fffcf2',
  almostWhite: '#f3f3f3',
  lightGray: '#979797',
  gray: 'rgba(5, 38, 44, 0.3)',
  peppermintVeryLight: 'rgba(233, 244, 242, 0.3)',
  error: '#eb5d61',
  shadow: 'rgba(5,38,44,0.1)',
  lightYellow: '#fffcf2',
  darkSnow: '#F6EED5',
  charcoal: '#0f0f0f',
  charcoal80: '#3f3f3f',
  charcoal60: '#6f6f6f',
  charcoal20: '#6f6f6f',
  paleClover: '#BEE8C2',
};

const text = {
  primaryFont: 'BwSeidoRaw',
  secondaryFont: 'BwSeidoRoundBold',
  thirdFont: 'BwSeidoRoundBlack',
  fontColor: colors.charcoal,
};

const layout = {
  headerHeight: 153,
  mobileHeaderHeight: 88,
  maxPageWidth: 1256,
  pagePadding: 0,
};

const dark = {
  text: {
    ...text,
    fontColor: colors.m2White,
  },
};

const lemon = {
  text: {
    ...text,
  },
  colors: {
    ...colors,
    peppermint: 'white',
  },
};

const defaultTheme = {
  colors,
  text,
  layout,
};

const Theme = (type?: 'default' | 'dark' | 'lemon') => {
  switch (type) {
    case 'dark':
      return {
        ...defaultTheme,
        ...dark,
      };
    case 'lemon':
      return {
        ...defaultTheme,
        ...lemon,
      };
    default:
      return defaultTheme;
  }
};

export default Theme;
