import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const Loader: React.FunctionComponent<{ minHeight?: React.CSSProperties['minHeight']; className?: string }> = ({
  className,
  minHeight,
}) => (
  <LoaderContainer className={className} minHeight={minHeight}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoaderContainer>
);
const loaderAnimation1 = keyframes`
    from { transform: scale(0)} to { transform: scale(1) }
`;
const loaderAnimation2 = keyframes`
    from { transform: translate(0,0)} to { transform: translate(19px, 0) }
`;
const loaderAnimation3 = keyframes`
    from { transform: scale(1)} to { transform: scale(0) }
`;

const LoaderContainer = styled.div<{ minHeight?: React.CSSProperties['minHeight'] }>`
  display: block;
  position: relative;
  margin: auto;
  width: 64px;
  height: 64px;
  min-height: ${(props) => props.minHeight ?? 'initial'};

  div {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.mint};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 6px;
    animation: ${loaderAnimation1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${loaderAnimation2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${loaderAnimation2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${loaderAnimation3} 0.6s infinite;
  }
`;

export default Loader;
