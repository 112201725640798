import React from 'react';
import { RouteComponentProps } from 'react-router';
import { H2 } from './forms/Text';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import ContactInfo from './auth/ContactInfo';
import { useMessageGetter } from 'react-message-context';
import MaintenanceRequests from '../components/contact/MaintenanceRequests';
import ContactForm from '../components/contact/ContactForm';
import styled from 'styled-components/macro';
import { theme } from '../theme';

const ContentContainer = styled(PaddedContent)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h4 {
    width: 100%;
    font-family: ${theme().text.secondaryFont};
  }
`;

const ContactPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('');

  return (
    <PageContainer history={history}>
      <ContentContainer paddingTop="0">
        <H2>{t('housing.contacts.title')}</H2>
        <MaintenanceRequests />
        <ContactForm />
        <ContactInfo />
      </ContentContainer>
    </PageContainer>
  );
};

export default ContactPage;
