import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router-dom';
import { useKennoTenant } from '../context/kennoTenantContext';
import { FlexColumn, FlexRow } from '../components/basic/Flex';
import { Body2 } from '../components/common';
import { loadContract } from '../context/kennoTenantActions';
import PaddedContent from '../components/housing/PaddedContent';
import styled from 'styled-components/macro';
import { DeviceSize } from '../components/basic/DeviceSize';
import { H2, H4 } from './forms';
import { LocationIcon } from '../assets';
//import ReactGA from 'react-ga';

const PersonalDetailsContainer = styled(FlexRow)`
  margin: 20px 0;
  gap: 20px;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    gap: 0;
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Address = styled(Body2)`
  font-weight: 700;
  margin: 0 0 0 4px;
`;

const ContactDetails = styled(FlexColumn)`
  h4 {
    margin-bottom: 12px;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin: 6px 0px 12px 0px;
  }
`;

const ProfilePage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('');
  const [{ user, contract }, dispatch] = useKennoTenant();

  useEffect(() => {
    loadContract(dispatch);
  }, [dispatch]);

  /* ReactGA.event({
        category: 'Profile',
        action: 'Save changes',
        label: '/omat-tiedot',
      }); */

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H2>
          {user.attributes?.firstName} {user.attributes?.lastName}
        </H2>
        <PersonalDetailsContainer>
          <IconContainer>
            {' '}
            <LocationIcon />
            <Address>{contract.postalAddress?.streetAddressLine1}</Address>
          </IconContainer>

          <Body2>
            {t('agreement.history.text', {
              startDate: contract.startDate ? contract.startDate.toLocaleDateString('fi-FI') : '-',
            })}
          </Body2>
        </PersonalDetailsContainer>
        <ContactDetails>
          <H4>{t('profile.contactinfoheader')}</H4>
          <dt>{t('profile.phone')}</dt>
          <dd>{user.attributes?.phoneNumber}</dd>
          <dt>{t('profile.email')}</dt>
          <dd>{user.attributes?.email}</dd>
        </ContactDetails>
      </PaddedContent>
    </PageContainer>
  );
};

export default ProfilePage;
