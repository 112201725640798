import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { NavLink, useHistory } from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import { Routes } from '../../constants/routes';
import { DeviceSize } from '../basic/DeviceSize';
import { theme } from '../../theme';
import { logoutUser } from '../../context/kennoTenantActions';
import { useKennoTenant } from '../../context/kennoTenantContext';

const ProfileMenuContainer = styled.div`
  z-index: 12;
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  width: 340px;

  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
  pointer-events: none;

  @media ${DeviceSize.desktopS} {
    width: calc(50% - 16px); // Fine-tuning to account for page padding
    top: 100%;
  }

  @media ${DeviceSize.mobileL} {
    width: 100%;
  }

  &.active {
    pointer-events: auto;
    opacity: 1;
  }
`;

const ListContainer = styled.ul`
  background: ${theme().colors.charcoal80};
  border-radius: 16px;
  border: 1px solid #0f0f0f;
  box-shadow: 0px 2px 8px 0px #00000033;
  overflow: hidden; // Prevents the highlight background from overflowing parent container

  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  height: 58px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;

  &:hover,
  &:active {
    background: ${theme().colors.charcoal60};
  }
`;

const ListNavLink = styled(NavLink)`
  color: white;
  height: 58px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  width: 100%;
`;

interface ProfileMenuProps {
  active: boolean;
  closeMenu: () => void;
}

const ProfileMenu = ({ active, closeMenu }: ProfileMenuProps) => {
  const t = useMessageGetter('navigation');
  const history = useHistory();
  const [, dispatch] = useKennoTenant();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      active &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !(document.activeElement && dropdownRef.current.contains(document.activeElement))
    ) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const signOut = async () => {
    try {
      await logoutUser(dispatch);
      history.push(Routes.HOME);
    } catch (error) {
      console.log('Error signing out', error);
    }
  };

  return (
    <ProfileMenuContainer
      id="profileMenu"
      ref={dropdownRef}
      className={active ? 'active' : ''}
      aria-hidden={!active}
      tabIndex={active ? 0 : -1}
    >
      <ListContainer>
        <ListItem>
          <ListNavLink onClick={closeMenu} to={Routes.PROFILE}>
            {t('profileMain.title')}
          </ListNavLink>
        </ListItem>
        {/*         <ListItem>
          <ListNavLink onClick={closeMenu} to="/">
            {t('profileMenu.documents')}
          </ListNavLink>
        </ListItem> */}
        <ListItem>
          <ListNavLink to="/" onClick={() => signOut()}>
            {t('signout.title')}
          </ListNavLink>
        </ListItem>
      </ListContainer>
    </ProfileMenuContainer>
  );
};

export default ProfileMenu;
