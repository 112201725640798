import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  className?: string;
  id: string;
  value?: string;
  hasError?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

const Textarea = styled.textarea<{ hasError?: boolean }>`
  background-color: ${(props) => (props.disabled ? props.theme.colors.almostWhite : props.theme.colors.darkSnow)};
  border: 2px solid ${(props) => (props.hasError ? props.theme.colors.error : props.theme.colors.black)};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.coal};
  font-family: ${(props) => props.theme.text.primaryFont};
  font-size: 16px;
  height: 165px;
  padding: 15px 20px 15px 20px;
`;

export const TextAreaElement = ({
  className = '',
  id,
  value,
  hasError,
  placeholder,
  onChange,
}: Props): React.ReactElement => {
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <Textarea
      hasError={hasError}
      value={value}
      onChange={handleOnChange}
      className={className}
      id={id}
      placeholder={placeholder}
    />
  );
};
