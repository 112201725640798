import React, { useState } from 'react';
import UnauthenticatedPageContainer from './UnauthenticatedPageContainer';
import ForgotPasswordStep from './ForgotPasswordStep';
import NewPasswordStep from './NewPasswordStep';

const ResetPasswordPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState<string>('');

  const handleResetCodeSent = (email: string) => {
    setStep(2);
    setEmail(email);
  };

  const handleNewCode = () => {
    setStep(1);
    setEmail('');
  };

  return (
    <UnauthenticatedPageContainer>
      {step === 1 && <ForgotPasswordStep onResetCodeSent={handleResetCodeSent} />}
      {step === 2 && <NewPasswordStep email={email} onClickNewCode={handleNewCode} />}
    </UnauthenticatedPageContainer>
  );
};

export default ResetPasswordPage;
