import {
  getBillingHistory,
  getProfile,
  login,
  logout,
  LoginCredentials,
  getCurrentBill,
  getCurrentContract,
  getApartment,
  getBuilding,
  getCurrentTargets,
} from '../apis_kenno';

export enum KENNO_TENANT_ACTIONS {
  LOGOUT = 10000,
  LOGIN,
  LOAD_BILLING_HISTORY,
  SET_BILLING_FILTER,
  SET_BILLING_RANGE_START,
  SET_BILLING_RANGE_END,
  LOAD_CURRENT_BILL,
  LOAD_CONTRACT,
  LOAD_APARTMENT,
  LOAD_BUILDING,
  LOAD_CURRENT_TARGETS,
}

export enum KENNO_TENANT_EVENTS {
  LOGGEDIN = 20000,
  LOGGEDOUT,
  LOAD_BILLING_HISTORY_SUCCESS,
  LOAD_BILLING_HISTORY_FAILURE,
  LOAD_CURRENT_BILL_SUCCESS,
  LOAD_CURRENT_BILL_FAILURE,
  LOAD_CONTRACT_SUCCESS,
  LOAD_CONTRACT_FAILURE,
  LOAD_APARTMENT_SUCCESS,
  LOAD_APARTMENT_FAILURE,
  LOAD_BUILDING_SUCCESS,
  LOAD_BUILDING_FAILURE,
  LOAD_CURRENT_TARGETS_SUCCESS,
  LOAD_CURRENT_TARGETS_FAILURE,
}

export type Dispatcher = React.Dispatch<[KENNO_TENANT_EVENTS | KENNO_TENANT_ACTIONS, any?]>;

export async function loadUser(dispatch: Dispatcher) {
  try {
    dispatch([KENNO_TENANT_ACTIONS.LOGIN]);
    const profile = await getProfile();
    dispatch([KENNO_TENANT_EVENTS.LOGGEDIN, profile]);
  } catch (err) {
    dispatch([KENNO_TENANT_EVENTS.LOGGEDOUT]);
  }
}

export async function loginUser(dispatch: Dispatcher, credentials: LoginCredentials) {
  try {
    dispatch([KENNO_TENANT_ACTIONS.LOGIN]);
    await login(credentials);
    // Updating time of the last activity when signing in, so the user wouldn't be logged out immediately
    const now = Date.now();
    window.localStorage.setItem('lastActivity', now.toString());
    const profile = await getProfile();
    dispatch([KENNO_TENANT_EVENTS.LOGGEDIN, profile]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOGGEDOUT]);
    throw error;
  }
}

export async function logoutUser(dispatch: Dispatcher) {
  try {
    dispatch([KENNO_TENANT_ACTIONS.LOGOUT]);
    await logout();
    dispatch([KENNO_TENANT_EVENTS.LOGGEDOUT]);
  } catch (err) {
    console.log('Logout failed:', err);
  }
}

export async function loadBillingHistory(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_BILLING_HISTORY]);
  try {
    const billingHistory = await getBillingHistory();
    dispatch([KENNO_TENANT_EVENTS.LOAD_BILLING_HISTORY_SUCCESS, billingHistory]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_BILLING_HISTORY_FAILURE]);
  }
}

export async function loadCurrentBill(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_CURRENT_BILL]);
  try {
    const currentBill = await getCurrentBill();
    dispatch([KENNO_TENANT_EVENTS.LOAD_CURRENT_BILL_SUCCESS, currentBill]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_CURRENT_BILL_FAILURE]);
  }
}

export async function loadCurrentTargets(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_CURRENT_TARGETS]);
  try {
    const targets = await getCurrentTargets();
    dispatch([KENNO_TENANT_EVENTS.LOAD_CURRENT_TARGETS_SUCCESS, targets]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_CURRENT_TARGETS_FAILURE]);
  }
}

export async function loadContract(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_CONTRACT]);
  try {
    const contract = await getCurrentContract();
    dispatch([KENNO_TENANT_EVENTS.LOAD_CONTRACT_SUCCESS, contract]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_CONTRACT_FAILURE]);
  }
}

export async function loadApartment(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_APARTMENT]);
  try {
    const apartment = await getApartment();
    dispatch([KENNO_TENANT_EVENTS.LOAD_APARTMENT_SUCCESS, apartment]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_APARTMENT_FAILURE]);
  }
}

export async function loadBuilding(dispatch: Dispatcher) {
  dispatch([KENNO_TENANT_ACTIONS.LOAD_BUILDING]);
  try {
    const building = await getBuilding();
    dispatch([KENNO_TENANT_EVENTS.LOAD_BUILDING_SUCCESS, building]);
  } catch (error) {
    dispatch([KENNO_TENANT_EVENTS.LOAD_BUILDING_FAILURE]);
  }
}
