import React from 'react';
import { useTheme } from 'styled-components/macro';

/* An SVG icon component that takes the name of its color as a prop
 * Now we can use any icon in any color anywhere!
 *
 * NOTE: Requires that the svg file has its <path>'s fill property set to #currentColor.
 * e.g. <svg ...><path ... fill="#currentColor"> ...
 *
 * TODO: Refactor the color prop to not use a string as a key like this maybe,
 * perhaps the parent should get the color from the theme and simply pass the color code as a string.
 */

interface IconProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  // an SVG imported as a ReactComponent (e.g. import { ReactComponent as Logo } from 'logo.svg')
  color?: string;
  width?: number;
  height?: number;
}

const ColorIcon = ({ color = 'charcoal', width, height, IconComponent }: IconProps) => {
  const theme: any = useTheme(); // TODO: Theme has no type definition
  return <IconComponent fill={theme.colors[color] || '#000000'} width={width} height={height} />;
};

export default ColorIcon;
