import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import PageContainer from '../PageContainer';
import { Routes } from '../../constants/routes';
import InstructionsModal from '../../components/basic/InstructionsModal';
import { LinkButton } from '../../components/common';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { Slugs } from '../../constants/knownSlugs';
import { useMessageGetter } from 'react-message-context';
import { useAppState } from '../../context/appContext';
import { H2 } from '../../components/basic/Text';
import styled from 'styled-components/macro';
import BillingHistory from './BillingHistory';

const PaymentInfoMessage = styled.div`
  margin-bottom: 1rem;
  text-align: center;

  a {
    margin-top: 30px;
  }
`;

const BillingPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('billing');
  const { menu } = useAppState();
  const [isPaymentInfoOpen, setIsPaymentInfoOpen] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    // TODO: replace these with Kenno routes when implementing Kenno payment
    if (pathname === Routes.Tenancy.PAYMENT_SUCCESS) {
      setPaymentSuccess(true);
      setIsPaymentInfoOpen(true);
    } else if (pathname === Routes.Tenancy.PAYMENT_ERROR) {
      setPaymentSuccess(false);
      setIsPaymentInfoOpen(true);
    }
  }, [pathname]);

  return (
    <PageContainer history={history}>
      <BillingHistory />
      {isPaymentInfoOpen && (
        <InstructionsModal openInstruction={setIsPaymentInfoOpen} modalShown={isPaymentInfoOpen}>
          <PaymentInfoMessage>
            {paymentSuccess ? <H2>{t('payment.success')}</H2> : <H2>{t('payment.failure')}</H2>}
            <LinkButton primary className="modal-input" to={getMenuItemPathBySlug(menu.items, Slugs.BILLING)}>
              {t('payment.continue')}
            </LinkButton>
          </PaymentInfoMessage>
        </InstructionsModal>
      )}
    </PageContainer>
  );
};

export default BillingPage;
