import styled from 'styled-components/macro';
import pageElement from '../basic/PageElement';
import { DeviceSize } from '../basic/DeviceSize';

// temporary solution for padding contents with global style element
const PaddedContent = styled.div<{
  paddingTop?: string;
  paddingBottom?: string;
  mobilePaddingTop?: string;
  mobilePaddingBottom?: string;
}>`
  ${pageElement};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '40px')};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : '26px')};

  @media ${DeviceSize.mobileL} {
    padding-top: ${(props) => (props.mobilePaddingTop ? props.mobilePaddingTop : '24px')};
    padding-bottom: ${(props) => (props.mobilePaddingBottom ? props.mobilePaddingBottom : '16px')};
  }
`;

export default PaddedContent;
