import React, { InputHTMLAttributes } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { FormInputProps } from './';

export interface InputElementDefaultProps extends InputHTMLAttributes<Element> {
  className?: string;
  hasError?: boolean;
  id: string;
}

export type InputElementRenderProps<T> = FormInputProps<T> & InputElementDefaultProps;

interface Props<FormState extends FieldValues, T> extends InputElementDefaultProps {
  fieldPath: Path<FormState>;
  inputElementRenderer: (props: InputElementRenderProps<T>) => React.ReactElement;
}

export const ControlledElement = <FormState extends FieldValues, T>({
  className = '',
  id,
  fieldPath,
  hasError,
  inputElementRenderer,
}: Props<FormState, T>): React.ReactElement => {
  const { control } = useFormContext<FormState>();

  return (
    <Controller<FormState>
      name={fieldPath}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        const renderProps = {
          onChange,
          onBlur,
          value,
          fieldState,
          fieldPath,
          id,
          className,
          hasError,
        };
        const inputElement = inputElementRenderer(renderProps);
        return inputElement;
      }}
    />
  );
};
