import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { LOAD_STATES } from '../../context/common';
import { capitalizeFirstLetter, replaceDotWithComma } from '../../utils';
import { DeviceSize } from '../../components/basic/DeviceSize';
import Loader from '../../components/basic/Loader';
import { H2 } from '../../components/basic/Text';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loadApartment, loadContract } from '../../context/kennoTenantActions';
import { Slugs } from '../../constants/knownSlugs';
import { useAppState } from '../../context/appContext';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { LinkButton } from '../../components/common';

const BasicTenancyInfo = () => {
  const t = useMessageGetter('');
  const { menu } = useAppState();
  const [{ user, contract, apartment }, dispatch] = useKennoTenant();

  const contractLoaded = contract.loadState === LOAD_STATES.READY;
  const apartmentLoaded = apartment.loadState === LOAD_STATES.READY;

  useEffect(() => {
    loadContract(dispatch);
    loadApartment(dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InfoContainer>
      <H2>
        {t('home.hello')} {user.attributes?.firstName}!
      </H2>
      <div>
        {!contractLoaded || !apartmentLoaded ? (
          <Loader />
        ) : (
          <ListContainer>
            <dl>
              <dt>{t('home.address.title')}</dt>
              <dd>
                {contract.postalAddress?.streetAddressLine1},{' '}
                <span>
                  {contract.postalAddress?.postalCode} {capitalizeFirstLetter(contract.postalAddress?.city)}
                </span>
              </dd>
              {/* <dt>{t('agreement.apartment.type')}</dt> */}
              <dt>{t('agreement.apartment.sqfootFull')}</dt>
              {/* <dd className="short">{apartment.data?.technicalTypeLabel}</dd> */}
              <dd>{replaceDotWithComma(apartment.data?.area)} m2</dd>
            </dl>
            <dl>
              <dt>{t('agreement.history.title')}</dt>
              <dd>
                {t('agreement.history.text', {
                  startDate: contract.startDate ? contract.startDate.toLocaleDateString('fi-FI') : '',
                })}
              </dd>
              {/* TODO: add this back when internet info is available */}
              {/* <dt>{t('housing.internet.title')}</dt> */}
              {/* <dd>{t(`housing.internet.${internetSlug}`)}</dd> */}
            </dl>
          </ListContainer>
        )}
        <LinkButton iconRight icon="trailing" size="medium" to={getMenuItemPathBySlug(menu.items, Slugs.AGREEMENT)}>
          {t('agreement.label')}
        </LinkButton>
      </div>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21.6px;

  @media ${DeviceSize.mobileL} {
    font-size: 16px;
    line-height: 19.2px;
  }

  a {
    background-color: white;

    @media ${DeviceSize.mobileL} {
      margin-top: 10px !important;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;

  dl {
    width: 50%;
    padding-right: 12px;
  }

  dt {
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 10px;
  }

  dd {
    height: 44px;
    margin: 0;
  }

  dd.short {
    height: 22px;
  }

  span {
    white-space: nowrap;
  }

  @media ${DeviceSize.mobileL} {
    flex-direction: column;

    dl {
      width: 100%;
      margin: 0;
    }

    dt {
      margin-top: 2px;
      margin-bottom: 4px;
    }

    dd {
      height: 35px;
    }
  }
`;

export default BasicTenancyInfo;
