import styled from 'styled-components/macro';

/**
 * This file is temporary styles for forms. Need to unify with the old styles a bit later.
 */

export const H2 = styled.h2`
  font-size: 32px;
  line-height: 45px;
  font-family: ${(props) => props.theme.text.secondaryFont};
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 36px;
  font-family: ${(props) => props.theme.text.secondaryFont};
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: 21px;
  line-height: 31.5px;
  font-family: ${(props) => props.theme.text.secondaryFont};
  margin: 0;
`;

export const H5 = styled.h5`
  font-family: ${(props) => props.theme.text.secondaryFont};
  font-size: 18px;
  line-height: 27px;
  margin: 0;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const TextBlock = styled.span`
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;
