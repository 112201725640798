import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Dropdown, DropdownProps } from '../Dropdown';

type Props = {
  month: number;
  year: number;
};

const MAX_DAYS_IN_MONTH = 31;

const getDayAmount = (month: number, year: number) => dayjs(`${year}-${month}-01`).daysInMonth();

export const DayDropdown = ({
  onChange,
  value,
  id,
  width,
  month,
  year,
  hasError,
}: Omit<DropdownProps, 'options'> & Props) => {
  const daysInMonth = month && year ? getDayAmount(month, year) : MAX_DAYS_IN_MONTH;

  useEffect(() => {
    if (value && (value as number) > daysInMonth) {
      onChange(daysInMonth);
    }
  }, [month, year, value, daysInMonth, onChange]);

  const options = Array.from({ length: daysInMonth }, (_, index) => ({
    label: (index + 1).toString(),
    value: index + 1,
  }));

  return <Dropdown onChange={onChange} value={value} id={id} options={options} width={width} hasError={hasError} />;
};
