import React, { useEffect, useState } from 'react';
//import ReactGA from 'react-ga';
import { useMessageGetter } from 'react-message-context';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { blackLogo } from '../../assets';
import { setLanguage } from '../../context/appActions';
import { Language, useApp } from '../../context/appContext';
import { MenuItem } from '../../types/wordpress';
import { removeAttributes, setAttributes } from '../../utils';
import { DeviceSize } from '../basic/DeviceSize';
import { baseFontStyle } from '../basic/Text';
import { BaseButton, RadioButtonRow } from '../button/ButtonLegacy';
//import SearchField from '../search/SearchField';
import SideMenuItem from './SideMenuItem';
import { FlexColumn, FlexRow } from '../basic/Flex';

interface SideMenuProps {
  menuItems: MenuItem[];
  isOpen: boolean;
  closeMenu: () => void;
}

const SideMenu = ({ menuItems, isOpen, closeMenu }: SideMenuProps) => {
  const { pathname } = useLocation();
  const t = useMessageGetter('navigation');
  //const history = useHistory();
  const [activeParentItem, setActiveParentItem] = useState<MenuItem | null>(null);
  const mainContent = document.body.querySelector('.content');
  const [{ language }, appDispatch] = useApp();

  const activeTabElements =
    '#mainnavi, #subMenu, #chevronmenu, .subitem, #sidemenu > div > .logoutButton, #localizations';
  const activeSideMenuElements = document.body.querySelectorAll(activeTabElements);

  const onClose = () => {
    setActiveParentItem(null);
    closeMenu();
  };

  useEffect(() => {
    if (mainContent) {
      if (isOpen) {
        // Prevent the site in the background from scrolling while overlay is up
        mainContent.setAttribute('style', 'position: fixed; width: 100%');

        // Enable keyboard navigation to side menu elements
        activeSideMenuElements.forEach((element) => {
          removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
        });
      } else {
        mainContent.removeAttribute('style');

        activeSideMenuElements.forEach((element) => {
          setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
        });
      }
    }
  }, [isOpen, mainContent, activeSideMenuElements]);

  useEffect(() => {
    if (isOpen) {
      const activeElements = document.querySelectorAll(
        activeTabElements
      ) as unknown as HTMLCollectionOf<HTMLInputElement>;
      const first = activeElements[0];
      const last = activeElements[activeElements.length - 1];

      first.focus();

      document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode !== 9 || e.key !== 'Tab') return;

        if (e.keyCode === 9) {
          if (e.shiftKey) {
            if (document.activeElement === first) {
              last.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === last) {
              first.focus();
              e.preventDefault();
            }
          }
        }
      });
    }
  }, [isOpen]);

  const handleMenuItemClick = (item: MenuItem | null, to: string) => {
    if (activeParentItem === item) {
      // Close submenu if it is clicked while open
      setActiveParentItem(null);
    } else if (item && item.children.length > 0) {
      // If the item is a parent with children, expand it
      setActiveParentItem(item);
    }
    // If user pressed on the already active page, close the menu
    if (to === pathname) {
      onClose();
    }
  };

  /*   const onSearch = (searchQuery: string) => {
    ReactGA.event({
      category: 'Home page',
      action: 'Search',
      label: searchQuery,
    });
    history.push(`/haku/${encodeURIComponent(searchQuery)}`);
    onClose();
  }; */

  return (
    <SideMenuContainer>
      <Overlay onClick={onClose} className={isOpen ? 'active' : ''} />
      <MenuContainer id="sidemenu" className={isOpen ? 'active' : ''} aria-haspopup="true">
        <MenuInnerContainer>
          <MenuTopContainer>
            <MobileLogoLink to={'/'} onClick={onClose} aria-label={t('home.title')}>
              <MobileLogoIcon src={blackLogo} />
            </MobileLogoLink>
          </MenuTopContainer>
          {/*           <SearchContainer>
            <SearchField onSearch={onSearch} />
          </SearchContainer> */}
          <MenuItems role="navigation" aria-label="Sidemenu navigation">
            {menuItems.map((menuItem) => (
              <SideMenuItem
                key={menuItem.title}
                name={menuItem.title}
                children={menuItem.children}
                to={menuItem.path}
                clickMenuItem={(_, to) => handleMenuItemClick(menuItem, to)}
                clickSubMenuItem={(_, to) => handleMenuItemClick(activeParentItem, to)}
                isOpen={activeParentItem === menuItem}
              />
            ))}
          </MenuItems>
          <RadioButtonRow id="localizations">
            <LanguageButton onClick={() => setLanguage(appDispatch, Language.FI)} active={language === Language.FI}>
              FI
            </LanguageButton>
            <LanguageButton onClick={() => setLanguage(appDispatch, Language.EN)} active={language === Language.EN}>
              EN
            </LanguageButton>
          </RadioButtonRow>
        </MenuInnerContainer>
      </MenuContainer>
    </SideMenuContainer>
  );
};

const SideMenuContainer = styled.div`
  ${baseFontStyle};
  display: none;
  position: relative;

  @media ${DeviceSize.desktopS} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media print {
    display: none;
  }
`;

const Overlay = styled.div`
  width: 0%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: rgba(15, 15, 15, 0.4);

  overflow: hidden;
  z-index: 10;

  &.active {
    width: 100%;
  }
`;

const MenuContainer = styled(FlexColumn)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: ${(props) => props.theme.colors.darkSnow};
  color: white;
  transition: 0.3s;
  z-index: 11;
  overflow-y: scroll;
  width: 0%;

  &.active {
    @media ${DeviceSize.desktopS} {
      width: 50%;
    }
    @media ${DeviceSize.mobileL} {
      width: 100%;
    }
  }
`;

const MobileLogoLink = styled(NavLink)`
  display: flex; // Removes default spacing from anchor
`;

const MobileLogoIcon = styled.img`
  width: 64px;
  height: 48px;
`;

const MenuInnerContainer = styled.div`
  margin: 0 16px;
`;

const MenuTopContainer = styled(FlexRow)`
  height: ${(props) => props.theme.layout.mobileHeaderHeight}px;
  padding-top: 24px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

/* const SearchContainer = styled.div`
  margin-top: 32px;
`; */

const MenuItems = styled(FlexColumn)`
  margin: 32px 0 32px;
`;

const LanguageButton = styled(BaseButton)`
  padding: 11px 18px;
`;

export default SideMenu;
