import React, { useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Button } from '../../components/common';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loginUser, logoutUser } from '../../context/kennoTenantActions';
import UnauthenticatedPageContainer from './UnauthenticatedPageContainer';
import { Routes } from '../../constants/routes';
import Loader from '../../components/basic/Loader';
import { SignInFormState, signInSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatDate } from '../../utils';
import LoginForm from './LoginForm';

const LoggedInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const enum UNAUTHORIZED_ERROR_TYPE {
  MISSING_CREDENTIALS = 'MISSING_CREDENTIALS',
  INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS',
  CONTRACT_NOT_STARTED = 'CONTRACT_NOT_STARTED',
  CONTRACT_EXPIRED = 'CONTRACT_EXPIRED',
}

const SignInPage: React.FC<RouteComponentProps> = ({ history, location }) => {
  const t = useMessageGetter('signin');
  const [{ user }, kennoTenantDispatch] = useKennoTenant();
  const [isBusy, setIsBusy] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const formMethods = useForm<SignInFormState>({ resolver: zodResolver(signInSchema) });

  const onSubmit: SubmitHandler<SignInFormState> = async (data) => {
    setLoginFailed(false);
    setIsBusy(true);
    try {
      await loginUser(kennoTenantDispatch, data);
      navigateToSite();
    } catch (error: any) {
      if (error.response?.status === 401) {
        switch (error.response?.data?.errorType) {
          case UNAUTHORIZED_ERROR_TYPE.INCORRECT_CREDENTIALS:
            setErrorMessage(t('error.incorrectCredentials'));
            break;
          case UNAUTHORIZED_ERROR_TYPE.CONTRACT_NOT_STARTED:
            setErrorMessage(
              t('error.contractNotStarted', {
                startDate: formatDate(error.response.data.startDate),
              })
            );
            break;
          case UNAUTHORIZED_ERROR_TYPE.CONTRACT_EXPIRED:
            setErrorMessage(t('error.contractExpired'));
            break;
          default:
            setErrorMessage(t('error.general'));
            break;
        }
      } else {
        setErrorMessage(t('error.general'));
      }
      setLoginFailed(true);
      setIsBusy(false);
    }
  };

  const navigateToSite = () => {
    const { from }: any = location.state || { from: { pathname: Routes.HOME } };
    history.push(from);
  };

  const IsLoggedIn = () => {
    return (
      <LoggedInContainer>
        <Button onClick={() => logoutUser(kennoTenantDispatch)}>Kirjaudu ulos</Button>
      </LoggedInContainer>
    );
  };

  return (
    <UnauthenticatedPageContainer>
      {isBusy ? (
        <Loader />
      ) : user.isLoggedIn ? (
        <IsLoggedIn />
      ) : (
        <FormProvider {...formMethods}>
          <LoginForm
            onSubmit={formMethods.handleSubmit(onSubmit)}
            loginFailed={loginFailed}
            errorMessage={errorMessage}
          />
        </FormProvider>
      )}
    </UnauthenticatedPageContainer>
  );
};

export default SignInPage;
