import { z } from 'zod';
import { EventType } from './EventTypeDropdown';
import { CoverImageType } from './CoverImageDropdown';
import { RepeatOption } from '../../../components/common/forms';

const publicationBaseSchema = z.object({ publicationDuration: z.number().refine((val) => val === 12 || val === 24) });

const publicationSchema = z.discriminatedUnion('publicationRepeat', [
  publicationBaseSchema.extend({ publicationRepeat: z.literal(RepeatOption.Never) }),
  publicationBaseSchema.extend({ publicationRepeat: z.literal(RepeatOption.Weekly), repeatEndDate: z.string().min(1) }),
  publicationBaseSchema.extend({
    publicationRepeat: z.literal(RepeatOption.Biweekly),
    repeatEndDate: z.string().min(1),
  }),
  publicationBaseSchema.extend({
    publicationRepeat: z.literal(RepeatOption.Monthly),
    repeatEndDate: z.string().min(1),
  }),
  publicationBaseSchema.extend({ publicationRepeat: z.literal(RepeatOption.Yearly), repeatEndDate: z.string().min(1) }),
]);

const baseSchema = z.object({
  name: z.string().min(1),
  date: z.string().min(1),
  time: z.string().min(1),
  description: z.string().min(1),
  property: z.string().min(1),
  coverImage: z.nativeEnum(CoverImageType),
  attachment: z.instanceof(FileList).optional(),
  publication: publicationSchema,
});

/**
 * Event invite schema is a bit different for different eventTypes.
 * Some fields are used in all schemas, even though their label texts might
 * differ. e.g. name field is used for name of event / subject of community meeting.
 */
export const schema = z.discriminatedUnion('eventType', [
  baseSchema.extend({
    eventType: z.literal(EventType.CommunityMeeting),
    location: z.string().min(1),
    regards: z.string(),
  }),
  baseSchema.extend({
    eventType: z.literal(EventType.CommunityWorkDay),
    contactPerson: z.string(),
  }),
  baseSchema.extend({
    eventType: z.literal(EventType.OtherEvent),
    contactPerson: z.string(),
  }),
  baseSchema.extend({
    eventType: z.literal(EventType.Party),
    contactPerson: z.string(),
  }),
]);

export type EventInviteFormState = z.infer<typeof schema>;
