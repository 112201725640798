import React, { useEffect, useRef, useState } from 'react';
import { MenuItem } from '../../../types/wordpress';
import styled from 'styled-components/macro';
import { theme } from '../../../theme';
import SubMenu from './SubMenu';

interface MainMenuItemProps {
  title: string;
  subMenuItems: MenuItem[];
}

const MainMenuItem = ({ title, subMenuItems }: MainMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleClickOutsideOrFocusLoss = (event: MouseEvent | FocusEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !(document.activeElement && menuRef.current.contains(document.activeElement))
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideOrFocusLoss);
    document.addEventListener('focusin', handleClickOutsideOrFocusLoss);

    return () => {
      document.removeEventListener('click', handleClickOutsideOrFocusLoss);
      document.removeEventListener('focusin', handleClickOutsideOrFocusLoss);
    };
  }, []);

  const numberOfColumns = (): number => {
    if (subMenuItems.length > 8) return 3;
    if (subMenuItems.length > 4) return 2;
    return 1;
  };

  const isLastMenuItem = menuRef.current?.parentElement?.lastElementChild === menuRef.current;
  const positionedOnRight = isLastMenuItem || numberOfColumns() > 1;

  return (
    <MainMenuItemContainer ref={menuRef} positionedOnRight={positionedOnRight} submenuIsOpen={isOpen}>
      <button onClick={handleToggle}>
        <MainMenuItemTitle>{title}</MainMenuItemTitle>
      </button>
      <SubMenu subMenuItems={subMenuItems} columnAmount={numberOfColumns()} positionedOnRight={positionedOnRight} />
    </MainMenuItemContainer>
  );
};

const MainMenuItemContainer = styled.div<{ positionedOnRight: boolean; submenuIsOpen: boolean }>`
  position: ${({ positionedOnRight }) => (positionedOnRight ? 'static' : 'relative')};
  display: flex;
  align-items: center;
  margin: 10px 0 10px;

  :not(:last-child) {
    margin-right: 15px;
  }

  button {
    all: unset;
    cursor: pointer;
    padding: 0 16px;
    margin-bottom: 2px;
  }

  button:focus-visible {
    border-radius: 40px;
    border: 2.5px solid ${theme().colors.black};
  }

  ul {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in, visibility 0s 200ms;
  }

  ${({ submenuIsOpen }) =>
    submenuIsOpen &&
    `
    ul {
      opacity: 1;
      visibility: visible;
      transition: opacity 200ms ease-in, visibility 0s 0ms;
    }

    ${MainMenuItemTitle},
    ${MainMenuItemTitle}:hover {
      color: ${theme().colors.charcoal80};
      border-bottom: 2.5px solid ${theme().colors.charcoal80};
    }
  `}
`;

const MainMenuItemTitle = styled.span`
  font-family: ${(props) => props.theme.text.secondaryFont};
  font-size: 21px;
  line-height: 31.5px;
  padding: 5px 0;
  border-bottom: 2.5px solid transparent;
  transition: border-bottom 0.2s ease-in;

  :hover {
    border-bottom: 2.5px solid ${theme().colors.black};
  }
`;

export default MainMenuItem;
