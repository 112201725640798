import { z } from 'zod';

export const signInSchema = z.object({
  username: z.string().min(1).email(),
  password: z.string().min(1),
});

export type SignInFormState = z.infer<typeof signInSchema>;

export const resetPasswordSchema = z.object({
  username: z.string().min(1).email(),
});

export type ResetPasswordFormState = z.infer<typeof resetPasswordSchema>;

export const acceptTermsSchema = z.object({
  termsAccepted: z.boolean().refine((val) => val === true),
});

export type AcceptTermsFormState = z.infer<typeof acceptTermsSchema>;

export const setCredentialsSchema = z
  .object({
    username: z.string().min(1).email(),
    password: z.string().min(8).regex(/[A-Z]/).regex(/[a-z]/).regex(/[0-9]/).regex(/[\W_]/),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
  });

export type SetCredentialsFormState = z.infer<typeof setCredentialsSchema>;

export const oneTimePasswordSchema = z.object({
  code: z.string().min(4),
});

export type OneTimePasswordFormState = z.infer<typeof oneTimePasswordSchema>;

export const newPasswordSchema = z
  .object({
    code: z.string().min(4),
    password: z.string().min(8).regex(/[A-Z]/).regex(/[a-z]/).regex(/[0-9]/).regex(/[\W_]/),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
  });

export type NewPasswordFormState = z.infer<typeof newPasswordSchema>;
