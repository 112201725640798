import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { MinusIcon, PlusIcon } from '../../../assets';
import { Row } from '../layoutComponents';
import { H4 } from '../Text';

type Props = {
  title: string;
  children: React.ReactNode;
  isInitiallyExpanded?: boolean;
  className?: string;
};

const TitleRow = styled(Row)`
  justify-content: space-between;
`;

const ExpandButton = styled.svg`
  cursor: pointer;
`;

export const ExpandableItem: React.FC<Props> = ({ title, children, isInitiallyExpanded = false, className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  const handleToggleExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleToggleExpanded();
    }
  };

  return (
    <div className={className}>
      <TitleRow>
        <H4>{title}</H4>
        <ExpandButton
          as={isExpanded ? MinusIcon : PlusIcon}
          role="button"
          aria-label={isExpanded ? 'Collapse content' : 'Expand content'}
          aria-expanded={isExpanded}
          aria-controls="expandable-content"
          tabIndex={0}
          onClick={handleToggleExpanded}
          onKeyDown={handleKeyPress}
        />
      </TitleRow>
      {isExpanded && (
        <div id="expandable-content" role="region" aria-live="polite">
          {children}
        </div>
      )}
    </div>
  );
};
