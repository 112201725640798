import React, { useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import axios from 'axios';
import styled from 'styled-components/macro';
import './wordpress.scss';
import Loader from '../basic/Loader';
import { H1 } from '../basic/Text';
import { Interweave, Node } from 'interweave';
import { WPAccordion, BackgroundBox } from './WPComponents';
import { EmergencyPlanItem } from '../housing/EmergencyPlanItem';
import { getWordPressEndpoint } from '../../utils/wordpress';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loadBuilding } from '../../context/kennoTenantActions';
import { LOAD_STATES } from '../../context/common';

export enum WpPostType {
  PAGES = 'pages',
  CONTENT_BLOCKS = 'content-blocks',
}

export interface WPContentProps {
  slug?: string;
  postType?: WpPostType;
}

const WordPressContent = ({ slug, postType }: WPContentProps) => {
  const t = useMessageGetter('wordpress');
  const [post, setPost] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [{ building }, dispatch] = useKennoTenant();

  useEffect(() => {
    loadBuilding(dispatch);
  }, [dispatch]);

  const wpUrl = getWordPressEndpoint();

  // Transforms input tags into some other format, such as a React Component.
  // If there are no matches, the element will be returned as-is.
  const wpContentTransformer = (node: HTMLElement, children: Node[]) => {
    if (node.classList.contains('wp-block-pb-accordion-item')) {
      const title = node.firstChild?.textContent || '';
      const content = children.slice(1);
      return <WPAccordion title={title}>{content}</WPAccordion>;
    }

    if (node.classList.contains('wp-block-button')) {
      // Built-in button(s) element
      if (node.classList.contains('emergency-plan-link')) {
        if (building.loadState === LOAD_STATES.BUSY) {
          return <Loader />;
        } else {
          return <EmergencyPlanItem url={building.emergencyPlan?.url} />;
        }
      }
    }

    if (node.classList.contains('bg-lemon')) {
      return <BackgroundBox>{children}</BackgroundBox>;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // Ignore fetch and keep loading when we don't have a slug
    if (!slug) {
      return;
    }

    axios
      .get(`${wpUrl}/${postType}?slug=${slug}`, { timeout: 30000 })
      .then((res) => {
        setPost(res.data[0].content.rendered);
        setTitle(res.data[0].title.rendered);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTitle(t('errorpage.title'));
        setPost(`<p>${t('errorpage.post')}</p>`);
        setIsLoading(false);
      });
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'wp_content'}>
      {title && post && !isLoading ? (
        <div>
          {postType === WpPostType.PAGES && <H1>{title}</H1>}
          <BodyWrapper>
            <Interweave transform={wpContentTransformer} content={post} />
          </BodyWrapper>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const BodyWrapper = styled.div`
  margin-top: 40px;
`;

export default WordPressContent;
