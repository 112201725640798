import React from 'react';
import { FunctionComponent } from 'react';
import BackgroundLogo from '../../components/header/BackgroundLogo';
import styled from 'styled-components/macro';
import { theme } from '../../theme';
import pageElement from '../../components/basic/PageElement';
import Footer from '../../components/footer/Footer';
import LocalizationButtons from '../../components/header/LocalizationButtons';
import HomeLogo from '../../components/header/HomeLogo';

const Container = styled.div`
  background-color: ${theme().colors.m2White};
`;

const Header = styled.div`
  ${pageElement};
  display: flex;
  align-content: space-between;
  background-color: ${theme().colors.m2White};
  padding: 30px 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightYellow};
  min-height: 70vh;
`;

const IconLinks = styled.div`
  display: flex;
  flex: 1;
`;

const UnauthenticatedPageContainer: FunctionComponent = ({ children }) => {
  return (
    <Container>
      <BackgroundLogo />
      <Header>
        <IconLinks>
          <HomeLogo margin={'0px'} />
        </IconLinks>
        <LocalizationButtons />
      </Header>
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </Container>
  );
};

export default UnauthenticatedPageContainer;
