import { CSSProperties } from 'react';
import styled from 'styled-components/macro';

export const FlexRow = styled.div<{
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  wrap?: CSSProperties['flexWrap'];
  width?: string;
  padding?: string;
  gap?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => props.wrap ?? 'initial'};
  justify-content: ${(props) => props.justifyContent ?? 'initial'};
  align-items: ${(props) => props.alignItems ?? 'initial'};
  width: ${(props) => props.width ?? 'initial'};
  padding: ${(props) => props.padding ?? 'initial'};
  gap: ${(props) => props.gap ?? 'initial'};
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const MarginContainer = styled.div<{ marginTop?: string; direction?: string }>`
  display: flex;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.direction ? 'row' : 'column')};
`;
