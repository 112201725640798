import React from 'react';
import { MenuItem } from '../../../types/wordpress';
import styled from 'styled-components/macro';
import { theme } from '../../../theme';
import LinkWithChevron from '../../basic/LinkWithChevron';

interface SubMenuProps {
  subMenuItems: MenuItem[];
  columnAmount: number;
  positionedOnRight: boolean;
}

const SubMenu = ({ subMenuItems, columnAmount, positionedOnRight }: SubMenuProps) => {
  return (
    <SubMenuContainer positionedOnRight={positionedOnRight} columnAmount={columnAmount}>
      {subMenuItems.map((item) => (
        <SubMenuItem key={item.slug}>
          <LinkWithChevron title={item.title} path={item.path} />
        </SubMenuItem>
      ))}
    </SubMenuContainer>
  );
};

const SubMenuContainer = styled.ul<{
  positionedOnRight: boolean;
  columnAmount: number;
}>`
  display: grid;
  gap: 2px;
  grid-template-columns: ${({ columnAmount }) => `repeat(${columnAmount}, 1fr)`};
  width: ${({ columnAmount }) => `calc(${columnAmount} * 407px)`};
  max-width: 95vw; // Guardrail to prevent overflowing viewport if submenu is very large
  background-color: white;
  padding: 16px 16px 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  list-style: none;
  position: absolute;
  ${({ positionedOnRight }) =>
    positionedOnRight
      ? `
    top: 100%;
    right: 0;
    left: auto;
    transform: none;
    `
      : `
    top: calc(100% + 14px);
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    `}
`;

const SubMenuItem = styled.li`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px 0px 12px;
  font-family: ${theme().text.primaryFont};
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.8px;
`;

export default SubMenu;
