import React, { Fragment, FunctionComponent, useState } from 'react';
import styled from 'styled-components/macro';
import { useMessageGetter } from 'react-message-context';
import ReactGA from 'react-ga';
import { activeElements, removeAttributes, setAttributes } from '../../utils';
import RedirectModal from '../basic/RedirectModal';
import { CallOut } from '../basic/Text';
import { DeviceSize } from '../basic/DeviceSize';

type EmergencyPlanItemProps = {
  url?: string;
};

// NOTE: Lots of code copied and/or moved straight from DocumentItem.tsx here, mostly relating to the redirection modal.
// Could probably be cleaned up a bit at some point.
export const EmergencyPlanItem: FunctionComponent<EmergencyPlanItemProps> = ({ url }) => {
  const t = useMessageGetter('housing');
  const modalText = useMessageGetter('modal');
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const backgroundElements = document.querySelectorAll(activeElements);

  const cancelRedirection = () => {
    document.body.style.overflow = 'auto';
    setShowModal(false);

    // deactivating inputs, buttons in the background etc. when modal is shown
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });

    ReactGA.event({
      category: 'Housing',
      action: 'Cancel redirection',
      label: url,
    });
  };

  const continueToPage = () => {
    document.body.style.overflow = 'auto';

    window.open(modalUrl, '_blank');
    setShowModal(false);

    // activating inputs, buttons in the background etc. when modal is hidden
    backgroundElements.forEach((element) => {
      removeAttributes(element, { tabindex: '-1', 'aria-hidden': 'false' });
    });

    ReactGA.event({
      category: 'Housing',
      action: 'Redirect to emergency plan page',
      label: url,
    });
  };

  const openRedirectModal = (url: string) => {
    document.body.style.overflow = 'hidden';

    // deactivating inputs, buttons in the background etc. when modal is shown
    backgroundElements.forEach((element) => {
      setAttributes(element, { tabindex: '-1', 'aria-hidden': 'true' });
    });

    setShowModal(true);
    setModalUrl(url);
  };

  if (!url) {
    return t('noexist.emergencyplan');
  }

  return (
    <Fragment>
      {showModal && (
        <RedirectModal cancelRedirect={cancelRedirection} continueRedirect={continueToPage} modalShown={showModal}>
          {modalText('redirect')}
        </RedirectModal>
      )}
      <EmergencyPlanRow onClick={() => openRedirectModal(url)} role="button">
        <PlanTitle>
          <PlanName>
            <span>{t('emergencyplan.title')}</span>
          </PlanName>
        </PlanTitle>
      </EmergencyPlanRow>
    </Fragment>
  );
};

const EmergencyPlanRow = styled.div<{ display?: string }>`
  // display: ${(props) => (props.display ? props.display : 'none')}; // This shouldn't break anything, right?
  display: inline-block;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.colors.charcoal};
  font-size: 18px;
  border-radius: 25px;
  padding: 12px 25px;
  background-color: #0f0f0f;

  span {
    color: ${(props) => props.theme.colors.white} !important;
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopM} {
    width: auto;
  }
`;

const PlanTitle = styled.div`
  margin-bottom: 0;
  width: 100%;

  @media ${DeviceSize.desktopS} {
    > span {
      margin-right: 10px;
    }
  }
`;

const PlanName = styled(CallOut)`
  font-family: ${(props) => props.theme.text.primaryFont};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: initial;
  margin: 0;
  font-weight: 700;

  @media ${DeviceSize.desktopS} {
    // color: white;
    margin: 0;
    word-break: break-word;
  }
`;
