import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../basic/DeviceSize';
import Card from '../common/Card';
import { theme } from '../../theme';
import { AnchorButton } from '../common';
import { useMessageGetter } from 'react-message-context';
import { NotUrgentPictogram, UrgentPictogram } from '../../assets';
//import { scrollToElement } from '../../utils';
import { Body2 } from '../common/Text';

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 24px;

  @media ${DeviceSize.mobileL} {
    display: flex;
    flex-direction: column;
  }
`;

const MaintenanceRequests = () => {
  const t = useMessageGetter('');

  /*   const UrgentButton = (
    <Button onClick={() => scrollToElement('maintenanceContactInfo')} size="medium">
      {t('housing.contacts.callMaintenance')}
    </Button>
  ); */

  const NonUrgentButton = (
    <AnchorButton href="https://m2kodit.fi/etusivu/ota-yhteytta/tee-vikailmoitus/" size="medium">
      {t('housing.reportFault')}
    </AnchorButton>
  );

  return (
    <CardContainer>
      <Card
        title={t('housing.contacts.urgentRequest')}
        background={theme().colors.strawberry}
        Pictogram={UrgentPictogram}
        // TODO: Display this when we get maintenance company's emergency number from M-Files
        /* secondaryButton={UrgentButton} */
      >
        {/* TODO: use 'housing.contacts.urgentRequestInfo' when we get number */}
        <Body2>{t('housing.contacts.temporaryUrgentRequestInfo')}</Body2>
      </Card>
      <Card
        id="notUrgentMaintenanceRequest"
        title={t('housing.contacts.nonurgentRequest')}
        background={theme().colors.lemon}
        Pictogram={NotUrgentPictogram}
        secondaryButton={NonUrgentButton}
      >
        <Body2>{t('housing.contacts.nonurgentRequestInfo')}</Body2>
      </Card>
    </CardContainer>
  );
};

export default MaintenanceRequests;
