import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import WordPressContent, { WpPostType } from '../components/wordpress/WordPressContent';

export interface WordPressPageProps extends RouteComponentProps {
  slug?: string;
}

const WordPressPage = ({ history, slug }: WordPressPageProps) => {
  return (
    <PageContainer history={history}>
      <PaddedContent>
        <WordPressContent slug={slug} postType={WpPostType.PAGES} />
      </PaddedContent>
    </PageContainer>
  );
};

export default WordPressPage;
