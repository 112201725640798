import { z } from 'zod';

const contactInfoSchema = z.object({
  name: z.string().min(1),
  ssn: z.string().min(1),
  address: z.string().min(1),
  zip: z.string().min(1),
  city: z.string().min(1),
  phone: z.string().min(1),
  email: z.string().email(),
  iban: z.string().min(1),
});

const eventSchema = z.object({
  name: z.string().min(1),
  date: z.string().date(),
  additionalInformation: z.string().optional(),
});

const purchaseReportSchema = z.object({
  report: z.string().min(1),
  amount: z.number(),
  receipt: z.instanceof(FileList),
});

export const schema = z.object({
  contactInfo: contactInfoSchema,
  event: eventSchema,
  purchaseReports: z.array(purchaseReportSchema).min(1),
});

export type ExpenseReportFormState = z.infer<typeof schema>;
