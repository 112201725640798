import { z } from 'zod';

const normalizeSpaces = (value: string): string => {
  return value.trim().replace(/\s+/g, ' ');
};

export const itemSchema = z.object({
  title: z.string().min(1),
  text: z.string().min(1),
});

export const fullNameSchema = z
  .string()
  .refine((value) => /^[^\s]+(\s+[^\s]+)+$/.test(normalizeSpaces(value)))
  .transform((value) => normalizeSpaces(value));

export const schema = z.object({
  name: z.string().min(1),
  date: z.string().min(1),
  time: z.string().min(1),
  location: z.string().min(1),
  participants: z.coerce.number().min(2),
  attachment: z.instanceof(FileList).optional(),
  items: z.array(itemSchema).min(1),
  chairperson: fullNameSchema,
  secretary: fullNameSchema,
  publicationDuration: z.number().refine((val) => val === 12 || val === 24),
  signatoryConfirmation: z.boolean().refine((val) => val === true),
});

export type MeetingMinutesFormState = z.infer<typeof schema>;
