import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from 'styled-components/macro';
import Bulletin from './Bulletin';
import { getWordPressAuth, getWordPressEndpoint } from '../../utils/wordpress';
import { setupCache } from 'axios-cache-interceptor';

const instance = Axios.create();
const axios = setupCache(instance, {
  cacheTakeover: false, // Remove the cache headers, as they're not allowed by the WP API
});

const Bulletins: React.FunctionComponent = () => {
  const wpUrl = getWordPressEndpoint();

  const [bulletins, setBulletins] = useState([]);
  const [loading, setLoading] = useState(true);

  /*   useEffect(() => {
    // Do not fetch again if we already fetched once in order to reduce API calls.
    // Value does not change often.
    if (!modules.modules) {
      getTenantModules(dispatch);
    }
  }, [dispatch]); */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === 'production';
    const currentLang = sessionStorage.getItem('currentLanguage');

    // Only bulletins with the post status 'publish' should be shown on the production site, while the 'draft'
    // should only be show on local or staging. Thus, auth is only necessary on staging or local,
    // since non-published bulletins aren't publicly accessible. The 'password' is an API key with
    // very limited access, in this case reading non-public bulletins.
    const params = { auth: getWordPressAuth() };

    // Only include bulletins with status 'draft' on staging or local.
    const includeDrafts = isProduction ? '' : '?status[]=draft&status[]=publish';

    axios
      .get(`${wpUrl}/bulletins${includeDrafts}`, isProduction ? {} : params)
      .then((res) => {
        setBulletins(res.data.filter((post: any) => filterBulletins(post)));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });

    const filterBulletins = (post: any) => {
      const isSameLanguage = currentLang === post.acf.bulletin_language;

      // This monstrosity here gets the last part of the page url.
      const currentPage = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));

      // TODO: Hide all posts on varauskeni page for now, remove later
      /*       if (currentPage === '/varaukseni') {
        return false;
      } */

      // Only show the bulletins on specified pages (like with the language)
      const showOnPage = post.acf.show_on_pages.includes(currentPage);

      // TODO: Custom One4all logic for showing only on homepage, sauna and washing room, remove later
      /* const one4allPost = showOnPage &&
        post.acf.show_on_pages.some((page: string) => (page === '/varaukseni'));

      if (one4allPost && !modules.modules?.length) {
        return false;
      }

      if ((one4allPost && modules.modules?.length) && isSameLanguage) {
        return true;
      } */

      return isSameLanguage && showOnPage;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (bulletins.length < 1 || loading) return null;

  return (
    <Container>
      {bulletins.map((v: any) => (
        <Bulletin
          key={v.title.rendered}
          title={v.title.rendered}
          body={v.content.rendered}
          theme={v.acf.bulletin_type}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 0px;
  padding-bottom: 0px;

  @media print {
    display: none;
  }
`;

export default Bulletins;
