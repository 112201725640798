import React, { useEffect } from 'react';
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom';
import { useKennoTenant } from './context/kennoTenantContext';
import { Dispatcher, logoutUser } from './context/kennoTenantActions';
import { Routes } from './constants/routes';
import { isLoggedIn } from './utils';

const LOGOUT_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours

export type AuthenticatedRouteComponent = (props: RouteComponentProps & { slug?: string }) => JSX.Element;

interface AuthenticatedRouteProps extends RouteProps {
  component: AuthenticatedRouteComponent;
  slug?: string;
}

function logoutOnInactivity(dispatcher: Dispatcher) {
  // Only use on production
  // TODO: Turning this back on for now, I'm suspecting something's acting up here
  // if(process.env.REACT_APP_ENV !== 'production') {
  //   return
  // };

  const now = Date.now();
  const lastActivity = Number.parseInt(window.localStorage.getItem('lastActivity') || now.toString());
  window.localStorage.setItem('lastActivity', now.toString());
  if (lastActivity < now - LOGOUT_TIMEOUT) {
    logoutUser(dispatcher);

    // will clear all the data from the previous session about the user
    window.localStorage.clear();
    window.localStorage.setItem('lastActivity', now.toString());
    return true;
  }
  return false;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component: Component, slug, ...rest }) => {
  const [{ user }, dispatch] = useKennoTenant();

  useEffect(() => {
    logoutOnInactivity(dispatch);
  });

  if (!user.isLoaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn(user) ? (
          <Component {...routeProps} slug={slug} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.SIGN_IN,
              search: window.location.search,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
