import React from 'react';
import { History } from 'history';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import PageContainer from '../PageContainer';
import { FormContainer, FormContent } from './layoutComponents';

type Props<FormData extends FieldValues> = {
  history: History;
  formMethods: UseFormReturn<FormData>;
  children: React.ReactNode;
};

export const FormPageContainer = <FormData extends FieldValues>({
  history,
  formMethods,
  children,
}: Props<FormData>) => {
  return (
    <PageContainer history={history}>
      <FormProvider {...formMethods}>
        <FormContainer>
          <FormContent>{children}</FormContent>
        </FormContainer>
      </FormProvider>
    </PageContainer>
  );
};
