import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Logo } from '../../assets/background_logo.svg';
import { ReactComponent as LogoSnow } from '../../assets/background_logo_snow.svg';
import { ReactComponent as LogoGreen } from '../../assets/background_logo_green.svg';
import { ReactComponent as LogoYellow } from '../../assets/background_logo_yellow.svg';
import { ReactComponent as LogoBlue } from '../../assets/background_logo_blue.svg';
import ColorIcon from '../basic/ColorIcon';
import { DeviceSize } from '../basic/DeviceSize';
import { useKennoTenantState } from '../../context/kennoTenantContext';
import { useLocation } from 'react-router';
import { Slugs } from '../../constants/knownSlugs';

const BackgroundLogoContainer = styled.div`
  position: absolute;
  width: 33vw;
  overflow: hidden;

  @media ${DeviceSize.desktopM} {
    width: 66vw;
  }

  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media print {
    display: none;
  }
`;

const BackgroundLogo: React.FC = () => {
  const location = useLocation();
  const { user } = useKennoTenantState();

  const pathSegments = location.pathname.split('/').filter((x) => x);

  const getLogo = () => {
    if (!user.isLoggedIn || location.pathname === '/') {
      return <ColorIcon IconComponent={Logo} />;
    }
    if (pathSegments.includes(Slugs.LIVING)) {
      return <ColorIcon IconComponent={LogoGreen} />;
    }
    if (pathSegments.includes(Slugs.TENANCY)) {
      return <ColorIcon IconComponent={LogoYellow} />;
    }
    if (pathSegments.includes(Slugs.MY_BUILDING)) {
      return <ColorIcon IconComponent={LogoBlue} />;
    }
    return <ColorIcon IconComponent={LogoSnow} />;
  };

  return <BackgroundLogoContainer>{getLogo()}</BackgroundLogoContainer>;
};

export default BackgroundLogo;
