const GaConfig = {
  local: {
    key: 'UA-48267036-4',
  },
  dev: {
    key: 'UA-48267036-4',
  },
  staging: {
    key: 'UA-48267036-4',
  },
  production: {
    key: 'UA-48267036-3',
  },
};

export default GaConfig;
