import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import UnauthenticatedPageContainer from './UnauthenticatedPageContainer';
import StrongAuthStep from './StrongAuthStep';
import SetCredentialsStep from './SetCredentialsStep';
import OneTimePasswordStep from './OneTimePasswordStep';
import SuccessStep from './SuccessStep';

const REGISTER_SESSION_ID_KEY = 'register_session_id';

const SignUpPage: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const history = useHistory();
  const [oauthState, setOauthState] = React.useState<string | null>(null);
  const [username, setUsername] = React.useState<string | null>(null);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [registerSessionId, setRegisterSessionId] = React.useState<string | null>(null);

  useEffect(() => {
    // Check if user's session has an oauthState. This implies they have just returned from the auth provider.
    // Move the oauthState to component's state and clear the sessionStorage to prevent value from hanging around
    // after it has expired
    const oauthStateInSession = window.sessionStorage.getItem('oauthState');
    if (oauthStateInSession) {
      setOauthState(oauthStateInSession);
      window.sessionStorage.removeItem('oauthState');
    }
  }, []);

  useEffect(() => {
    // Check if we have a register session ID
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get(REGISTER_SESSION_ID_KEY);

    if (sessionId) {
      setRegisterSessionId(sessionId);
      // Clear value from URL
      searchParams.delete(REGISTER_SESSION_ID_KEY);
      history.replace({ search: searchParams.toString() });
    }
  }, [location.search, history]);

  /**
   * Renders the correct step which should be shown to the user. Conditions here are in the inverse order of when
   * the user will see them, since this makes the logic easier to implement.
   */
  const renderStep = () => {
    // Registration has already been completed
    if (isCompleted) {
      return <SuccessStep />;
    }
    // We have given our credentials and now only need to enter the one-time password
    if (username) {
      return <OneTimePasswordStep username={username} onSuccess={() => setIsCompleted(true)} />;
    }
    // We have returned from the strong auth provider. Next we should to set our credentials
    if (oauthState && registerSessionId) {
      return (
        <SetCredentialsStep oauthState={oauthState} setUsername={setUsername} registerSessionId={registerSessionId} />
      );
    }
    // Registration has just begun. Go to strong auth step.
    return <StrongAuthStep />;
  };

  return <UnauthenticatedPageContainer>{renderStep()}</UnauthenticatedPageContainer>;
};

export default SignUpPage;
