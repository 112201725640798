import React from 'react';
import { Dropdown, DropdownProps } from '../../../components/common/Dropdown';
import { useMessageGetter } from 'react-message-context';

export enum EventType {
  CommunityWorkDay = 'communityWorkDay',
  Party = 'party',
  CommunityMeeting = 'communityMeeting',
  OtherEvent = 'otherEvent',
}

export const EventTypeDropdown = ({ onChange, value, id }: Omit<DropdownProps, 'options' | 'width'>) => {
  const t = useMessageGetter('forms.eventInvite.eventTypes');
  const options = [
    { label: t('communityWorkDay'), value: EventType.CommunityWorkDay },
    { label: t('party'), value: EventType.Party },
    { label: t('communityMeeting'), value: EventType.CommunityMeeting },
    { label: t('otherEvent'), value: EventType.OtherEvent },
  ];
  return <Dropdown width="270px" onChange={onChange} value={value} id={id} options={options} />;
};
