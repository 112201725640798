import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { baseFontStyle } from '../basic/Text';
import { Language, useApp } from '../../context/appContext';
import { setLanguage } from '../../context/appActions';

const Localizations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px; // Bottom border will offset the content. Realign it with this margin
  gap: 8px;
  margin-right: 32px;
`;

const StyledLanguageButton = styled.button`
  ${baseFontStyle};
  font-size: 16px;
  font-weight: 700;
  height: 36px;
  background: none;
  border: none;

  cursor: pointer;

  &.active {
    border-bottom: 2px solid #0f0f0f;
    margin-bottom: -2px; // Offset the border height
  }
`;

const LocalizationButtons = () => {
  const [{ language }, appDispatch] = useApp();

  const handleLanguageChange = (lang: Language) => {
    setLanguage(appDispatch, lang);
  };

  const LanguageButton: FunctionComponent<{ lang: Language }> = ({ lang }) => (
    <StyledLanguageButton className={language === lang ? 'active' : ''} onClick={() => handleLanguageChange(lang)}>
      {lang.toUpperCase()}
    </StyledLanguageButton>
  );

  return (
    <Localizations id="mainlocalizations">
      <LanguageButton lang={Language.FI} />
      <LanguageButton lang={Language.EN} />
    </Localizations>
  );
};

export default LocalizationButtons;
