import React from 'react';
import styled from 'styled-components/macro';
import { hoverEffectChevron } from './LinkWithChevron';
import { DeviceSize } from './DeviceSize';

interface IconLinkProps {
  icon: string;
  label: string;
  href: string;
}

const IconLink: React.FC<IconLinkProps> = ({ icon, label, href }) => {
  return (
    <StyledLink href={href}>
      <IconContainer>
        {/* For decorative images, the alt attribute should be set to an empty
       string (alt="") to inform assistive technologies that the image can be ignored. */}
        <Icon src={icon} alt="" />
      </IconContainer>
      <span>{label}</span>
    </StyledLink>
  );
};

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  height: 25px;
  ${hoverEffectChevron}

  span {
    font-size: 14px;
    line-height: 21px;
    border-bottom: 1.5px solid transparent;
    padding: 0;

    @media ${DeviceSize.mobileL} {
      font-size: 12px;
      line-height: 18px;
    }
  }

  :hover span {
    border-bottom: 1.5px solid black;
  }
`;

const IconContainer = styled.div`
  padding-bottom: 1px;
`;

const Icon = styled.img`
  display: inline-flex;
  margin-right: 4px;
  width: 12px;
  height: 12px;
`;

export default IconLink;
