import React from 'react';
import { H2 } from '../forms';
import { ButtonContainer, FormContainer } from '../layoutComponents';
import { Body2, LinkButton } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { Routes } from '../../constants/routes';

const SuccessStep: React.FC = () => {
  const t = useMessageGetter('signup');
  return (
    <FormContainer>
      <H2>{t('title')}</H2>
      <Body2>{t('successStep')}</Body2>
      <ButtonContainer>
        <LinkButton primary to={Routes.SIGN_IN}>
          {t('returnToLogin')}
        </LinkButton>
      </ButtonContainer>
    </FormContainer>
  );
};

export default SuccessStep;
