import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  ArrowForwardIcon,
  CalendarIcon,
  ChevronRightIcon,
  ErrorOutlineIcon,
  EditIcon,
  EyeIcon,
  LeadingIcon,
  PlusIcon,
  SaveOutlinedIcon,
  SendIcon,
  ShareFilledIcon,
  TrailingIcon,
  XCloseIcon,
  PrintIcon,
  Send2Icon,
  EuroOutlineIcon,
  InfoOutlinedIcon,
  StarOutlinedIcon,
} from '../../../assets';
import { DeviceSize } from '../../basic/DeviceSize';

export const HEX_OPACITY_50 = '80';

const primaryStyles = css`
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  path {
    fill: ${(props) => props.theme.colors.white};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.charcoal80};
    border: 2px solid ${(props) => props.theme.colors.charcoal80};
  }
`;

const largeButtonStyles = css`
  height: 48px;
  font-size: 18px;
  padding: 11px 16px;
`;

const mediumButtonStyles = css`
  height: 40px;
  font-size: 16px;
  padding: 8px 12px;
`;

const smallButtonStyles = css`
  height: 32px;
  font-size: 14px;
  padding: 4px 12px;
`;

const miniButtonStyles = css`
  height: 24px;
  font-size: 12px;
  padding: 3px 9px;
  border: 1px solid black;
`;

export const buttonStyles = css<{
  primary?: boolean;
  iconRight?: boolean;
  size: ButtonSize;
  background?: React.CSSProperties['background'];
}>`
  cursor: pointer;
  background: ${(props) => props.background ?? 'transparent'};
  color: ${(props) => props.theme.colors.black};
  border: 2px solid black;
  border-radius: 40px;
  font-weight: 700;
  font-family: ${(props) => props.theme.text.primaryFont};
  white-space: nowrap;
  display: inline-flex;
  flex-direction: ${(props) => (props.iconRight ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return largeButtonStyles;
      case 'medium':
        return mediumButtonStyles;
      case 'small':
        return smallButtonStyles;
    }
  }};

  // One size smaller button is used in mobile view
  @media ${DeviceSize.mobileL} {
    ${({ size }) => {
      switch (size) {
        case 'large':
          return mediumButtonStyles;
        case 'medium':
          return smallButtonStyles;
        case 'small':
          return miniButtonStyles;
      }
    }};
  }

  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: ${(props) => `${props.theme.colors.white}${HEX_OPACITY_50}`};
  }
  &:focus-visible {
    outline: 2px #f9f9f9 solid;
    outline-offset: 0;
    box-shadow: 0 0 0 4px #193146;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${(props) => props.primary && primaryStyles};
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export type IconName =
  | 'plus'
  | 'saveOutlined'
  | 'eye'
  | 'shareFilled'
  | 'xClose'
  | 'trailing'
  | 'arrowForward'
  | 'chevronRight'
  | 'leading'
  | 'errorOutline'
  | 'calendar'
  | 'edit'
  | 'print'
  | 'send'
  | 'send2'
  | 'euroOutlined'
  | 'infoOutlined'
  | 'starOutlined';

export type ButtonSize = 'large' | 'medium' | 'small';
type Icons = Record<IconName, JSX.Element>;

export type CommonButtonProps = {
  icon?: IconName;
  primary?: boolean;
  iconRight?: boolean;
  size?: ButtonSize;
  children?: React.ReactNode;
  background?: React.CSSProperties['background'];
};

type ButtonProps = CommonButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const getIcon = (icon: IconName | undefined, primary: boolean | undefined): JSX.Element | null => {
  const icons: Icons = {
    plus: <PlusIcon />,
    saveOutlined: <SaveOutlinedIcon />,
    eye: <EyeIcon />,
    shareFilled: primary ? <ShareFilledIcon /> : <ShareFilledIcon />,
    xClose: <XCloseIcon />,
    trailing: <TrailingIcon />,
    arrowForward: <ArrowForwardIcon />,
    chevronRight: <ChevronRightIcon />,
    leading: <LeadingIcon />,
    errorOutline: <ErrorOutlineIcon />,
    calendar: <CalendarIcon />,
    edit: <EditIcon />,
    print: <PrintIcon />,
    send: <SendIcon />,
    send2: <Send2Icon />,
    euroOutlined: <EuroOutlineIcon />,
    infoOutlined: <InfoOutlinedIcon />,
    starOutlined: <StarOutlinedIcon />,
  };

  return icon ? icons[icon] : null;
};

/**
 * Button component with Icon possibility, which can render the predefined icon to the left side of the button,
 * unless `iconRight` flag is given.
 * `primary` flag can be given to revert the colors.
 * Size can be defined with prop `size`. Default size is large and by default one size smaller button styles are
 * applied for mobile view.
 *
 * Example use:
 * <Button icon='plus' primary iconRight onClick={handleOnClick}>Button text here</Button>
 * This would render a button with the text on the left and the `plus` icon on the right
 * and the colors would match the primary button colors.
 *
 * For further development: To add more icons to this component, simply add the
 * wanted parameter name to IconName type, and then add the icon with the parameter to icons
 * variable.
 */
export const Button: React.FC<ButtonProps> = ({
  icon,
  primary,
  iconRight,
  size = 'large',
  background,
  children,
  ...props
}) => {
  const renderedIcon = getIcon(icon, primary);

  return (
    <StyledButton primary={primary} iconRight={iconRight} size={size} background={background} {...props}>
      {renderedIcon}
      {children}
    </StyledButton>
  );
};
