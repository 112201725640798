import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { ActionReportPreview } from '.';
import { Button } from '../../../components/common';
import { DropdownOptionValue } from '../../../components/common/Dropdown';
import {
  DayDropdown,
  FormActions,
  InputField,
  MonthDropdown,
  PublicationDurationDropdown,
  TextAreaElement,
  TimeDropdown,
} from '../../../components/common/forms';
import { scrollToTop } from '../../../utils';
import { FormPageContainer } from '../FormPageContainer';
import { ContentBlock, Form, FormRow, RequiredIndicator } from '../layoutComponents';
import { H2, H3 } from '../Text';
import { ActionReportFormState, schema } from './schema';
import { YearDropdown } from './YearDropdown';

const ActivitiesSummaryRow = styled(FormRow)`
  font-size: 18px;
  font-weight: 700;
  font-family: ${(props) => props.theme.text.secondaryFont}
  line-height: 27px;
  justify-content: space-between;
`;

const ActivitiesContent = styled(ContentBlock)``;
const PublicationContent = styled(ContentBlock)``;

export const ActionReport = ({ history }: RouteComponentProps) => {
  const formMethods = useForm<ActionReportFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      year: new Date().getFullYear(), // Set to current year
      activities: [{ name: '', additionalInformation: '', expenses: 0.0 }],
      publicationDuration: 12,
    },
  });

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const t = useMessageGetter('forms.actionReport');

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'activities',
  });

  const handleAddNewEvent = () => {
    append({
      name: '',
      // These typecastings are a workaround for typescript error stating that day and month are missing
      // (we do not want to default them to anything)
      // This works differently than useForm's defaultValues in which the values can be null or undefined.
      // Unfortunately append function is strictly following the schema in which the values are required.
      // Discussion about the flaw here: https://github.com/orgs/react-hook-form/discussions/10211
      day: undefined as unknown as number,
      month: undefined as unknown as number,
      time: undefined,
      additionalInformation: '',
      expenses: 0.0,
    });
  };

  const handleEmptyForm = () => {
    formMethods.reset();
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
    scrollToTop();
  };

  const handleSave = () => {
    console.log('WIP: save');
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleSubmit = (e: any) => {
    console.log('WIP: onSubmit', e);
  };

  const translationPrefix = 'forms.actionReport';

  const activities = formMethods.watch('activities');
  const selectedYear = formMethods.watch('year');

  const totalExpenses = activities.reduce((sum, obj) => sum + obj.expenses, 0).toFixed(2);

  const totalEventCountText =
    activities.length === 1
      ? t('eventCount', { count: activities.length })
      : t('eventCount_plural', { count: activities.length });

  return (
    <FormPageContainer<ActionReportFormState> history={history} formMethods={formMethods}>
      {previewOpen ? (
        <ActionReportPreview closePreview={handleClosePreview} />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <H2>{t('title')}</H2>
          <span>
            {t('description.part1')} (<RequiredIndicator>*</RequiredIndicator>) {t('description.part2')}
          </span>
          <ActivitiesContent>
            <InputField<ActionReportFormState, DropdownOptionValue>
              translationPrefix={translationPrefix}
              fieldPath="year"
              required
              inputElementRenderer={(props) => <YearDropdown {...props} />}
            />
            <H3>{t('completedActivities.title')}</H3>
            {t('completedActivities.description')}

            {fields.map((field, index) => (
              <div key={field.id} style={{ marginBottom: '1rem' }}>
                <InputField<ActionReportFormState, string>
                  fieldPath={`activities.${index}.name`}
                  translationPrefix={translationPrefix}
                  type="text"
                  required
                />
                <FormRow>
                  <InputField<ActionReportFormState, DropdownOptionValue>
                    translationPrefix={translationPrefix}
                    fieldPath={`activities.${index}.month`}
                    inputElementRenderer={(props) => <MonthDropdown {...props} />}
                    required
                  />
                  <InputField<ActionReportFormState, DropdownOptionValue>
                    translationPrefix={translationPrefix}
                    fieldPath={`activities.${index}.day`}
                    required
                    inputElementRenderer={(props) => {
                      const month = formMethods.watch(`activities.${index}.month`);
                      return <DayDropdown {...props} year={selectedYear} month={month} />;
                    }}
                  />
                  <InputField<ActionReportFormState, DropdownOptionValue>
                    translationPrefix={translationPrefix}
                    fieldPath={`activities.${index}.time`}
                    inputElementRenderer={(props) => <TimeDropdown {...props} />}
                  />
                </FormRow>
                <InputField<ActionReportFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath={`activities.${index}.additionalInformation`}
                  required
                  inputElementRenderer={(props) => (
                    <TextAreaElement placeholder={t('activities.responsiblePersonsPlaceholder')} {...props} />
                  )}
                />
                <InputField<ActionReportFormState, number>
                  translationPrefix={translationPrefix}
                  fieldPath={`activities.${index}.expenses`}
                  required
                  type="number"
                  step="0.01"
                />
                {fields.length > 1 && (
                  <Button primary onClick={() => remove(index)}>
                    {t('removeEvent')}
                  </Button>
                )}
              </div>
            ))}
            <FormRow>
              <Button type="button" icon="plus" onClick={handleAddNewEvent}>
                {t('addNewEvent')}
              </Button>
            </FormRow>

            <ActivitiesSummaryRow>
              <span>{totalEventCountText} </span>
              <span>{t('totalExpensesWithAmount', { amount: totalExpenses })}</span>
            </ActivitiesSummaryRow>
          </ActivitiesContent>
          <PublicationContent>
            <H3>{t('publication.title')}</H3>
            <InputField<ActionReportFormState, DropdownOptionValue>
              fieldPath="publicationDuration"
              translationPrefix={translationPrefix}
              inputElementRenderer={(props) => <PublicationDurationDropdown {...props} />}
            />
          </PublicationContent>
          <FormActions
            handleEmptyForm={handleEmptyForm}
            handleOpenPreview={handleOpenPreview}
            handleSave={handleSave}
          />
        </Form>
      )}
    </FormPageContainer>
  );
};
