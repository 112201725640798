import React from 'react';
import styled from 'styled-components/macro';
import { BasicParagraph, H1 } from '../basic/Text';

const Container = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

const HeaderContact = () => {
  return (
    <Container>
      <H1>M2-Kodit</H1>
      <BasicParagraph>m2.asiakaspalvelu@ysaatio.fi</BasicParagraph>
      <BasicParagraph>09 7742 5500</BasicParagraph>
    </Container>
  );
};

export default HeaderContact;
