import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components/macro';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from './DeviceSize';

type InstructionsModalProps = {
  openInstruction: (isOpen: boolean) => void;
  modalShown: boolean;
};

const InstructionsModal: FunctionComponent<InstructionsModalProps> = ({ openInstruction, modalShown, children }) => {
  useEffect(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        openInstruction(false);
      }
    });
  });

  return (
    <Fragment>
      <Overlay onClick={() => openInstruction(false)} />
      <Container role="dialog" aria-modal="true" aria-labelledby="modal-text" onClick={() => openInstruction(false)}>
        <Content onClick={(e) => e.stopPropagation()}>{children}</Content>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;

  @media ${DeviceSize.mobileL} {
    width: 100%;
    top: 50%;
    height: 100%;
  }
`;

const Content = styled(PaddedContent)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  padding: 24px;
  max-height: 1000px;

  @media ${DeviceSize.mobileL} {
    overflow-y: scroll;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.mint};
`;

export default InstructionsModal;
