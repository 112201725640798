import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ChevronDownIcon } from '../../assets';
import { useMessageGetter } from 'react-message-context';

const StyledChevronDown = styled(ChevronDownIcon)`
  pointer-events: none;
  width: 14px;
  height: 16px;
`;

const StyledChevronUp = styled(StyledChevronDown)`
  transform: rotate(180deg);
`;

const DropdownContainer = styled.div<{ width: string }>`
  display: flex;
  font-family: ${(props) => props.theme.text.primaryFont};
  width: ${(props) => props.width};
  position: relative;
  height: 48px;
`;

const DropdownButton = styled.button<{ hasError?: boolean }>`
  padding: 9px 16px 8px 16px;
  width: 100%;
  font-size: 18px;
  border: 2px solid ${(props) => (props.hasError ? props.theme.colors.error : props.theme.colors.black)};
  border-radius: 40px;
  line-height: 23.4px;
  background-color: ${(props) => props.theme.colors.darkSnow};
  color: ${(props) => props.theme.colors.coal};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  white-space: nowrap;
`;

const OptionList = styled.ul`
  position: absolute;
  top: 50px;
  width: 100%;
  margin: 0;
  padding: 0;
  gap: 8px;
  max-height: 200px;
  list-style: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 16px;
  overflow-y: auto;
  z-index: 10;
`;

const Option = styled.li<{ isFocused: boolean }>`
  padding: 16px 8px 16px 16px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.paleClover};
  }
  background-color: ${(props) => (props.isFocused ? props.theme.colors.paleClover : 'transparent')};
  &:focus {
    background-color: ${(props) => props.theme.colors.paleClover};
  }
`;

const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.charcoal60};
`;

export type DropdownOptionValue = string | number | boolean | null | undefined;

type SelectOption = {
  label: string;
  value: DropdownOptionValue;
};

export interface DropdownProps {
  value: DropdownOptionValue;
  options: SelectOption[];
  id: string;
  hasError?: boolean;
  onChange: (value: DropdownOptionValue) => void;
  width?: string | number;
}

export const Dropdown = ({
  value,
  options,
  id,
  hasError,
  onChange,
  width = '100%',
}: DropdownProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const t = useMessageGetter('input.dropdown');

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
  const focusedIndexRef = useRef(focusedIndex);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    // In order to close the dropdown when clicked outside, we need to check
    // the instance of event.target, some explanation here https://stackoverflow.com/questions/61164018/
    if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        setIsOpen(true);
      }
    } else {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex === null || prevIndex === options.length - 1 ? 0 : prevIndex + 1));
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex === null || prevIndex === 0 ? options.length - 1 : prevIndex - 1));
      } else if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        if (focusedIndex !== null) {
          handleOptionClick(options[focusedIndex].value);
        }
      }
    }
  };

  const handleOptionClick = (value: DropdownOptionValue) => {
    onChange(value);
    setIsOpen(false);
  };

  const handleOptionFocus = (index: number) => {
    setFocusedIndex(index);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // This useEffects changes the scrollbehaviour, so when changing the focus via arrow keys
  // we use the smooth scroll behaviour, but when we open the dropdown we use 'auto' which
  // scrolls instantly to the correct option position
  useEffect(() => {
    if (isOpen && focusedIndex !== null && optionRefs.current[focusedIndex]) {
      optionRefs.current[focusedIndex]?.scrollIntoView({
        behavior: focusedIndexRef.current !== focusedIndex ? 'smooth' : 'auto',
        block: 'nearest',
      });
    }
    focusedIndexRef.current = focusedIndex;
  }, [focusedIndex, isOpen]);

  useEffect(() => {
    const selectedIndex = options.findIndex((option) => option.value === value);
    setFocusedIndex(selectedIndex !== -1 ? selectedIndex : null);
  }, [value, options, isOpen]);

  return (
    <DropdownContainer width={width} id={id} ref={dropdownRef}>
      <DropdownButton hasError={hasError} type="button" onClick={toggleDropdown} onKeyDown={handleKeyDown}>
        {value === undefined ? (
          <Placeholder>{t('choose')}</Placeholder>
        ) : (
          <span>{options.find((option) => option.value === value)?.label}</span>
        )}
        {isOpen ? <StyledChevronUp /> : <StyledChevronDown />}
      </DropdownButton>
      {isOpen && (
        <OptionList>
          {options.map((option, index) => (
            <Option
              key={option.label}
              isFocused={focusedIndex === index}
              onClick={() => handleOptionClick(option.value)}
              onFocus={() => handleOptionFocus(index)}
              ref={(el) => (optionRefs.current[index] = el)}
            >
              {option.label}
            </Option>
          ))}
        </OptionList>
      )}
    </DropdownContainer>
  );
};
