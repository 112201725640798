import React, { useEffect, useState } from 'react';
import { useMessageGetter } from 'react-message-context';
import { H1, H2, H3, BasicParagraph } from '../components/basic/Text';
import { ButtonLegacy } from '../components/button/ButtonLegacy';
import Loader from '../components/basic/Loader';
import PageContainer from './PageContainer';
import PaddedContent from '../components/housing/PaddedContent';
import { Input } from '../components/input/Input';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { formatEuros, shouldHideFinancialInfo } from '../utils';
import { v4 as uuidv4 } from 'uuid';

import PaytrailConfig from '../PaytrailConfig';
import { LOAD_STATES } from '../context/common';
import { useKennoTenant } from '../context/kennoTenantContext';
import { loadContract, loadCurrentBill } from '../context/kennoTenantActions';
import { PaymentRequest, PaymentCustomer, PaymentAddress } from '../types/payment';
import { BasicText, Body2, Button, LinkButton } from '../components/common';
import { getMenuItemPathBySlug } from '../utils/wordpress';
import { useAppState } from '../context/appContext';
import { Slugs } from '../constants/knownSlugs';
import { createPayment } from '../apis_kenno';
import FormError from '../components/common/forms/FormError';
import { MaintenanceNote } from '../components/FinancialInfoMaintenanceNote';

const environment = process.env.REACT_APP_ENV;
const paytrailConfig = PaytrailConfig[environment].paytrail;

const getPaytrailFields = async (
  customer: PaymentCustomer,
  invoicingAddress: PaymentAddress,
  amount: number,
  referenceNumber: string
): Promise<PaymentRequest> => {
  const trimmedRefNum = referenceNumber.replace(/\s/g, '');

  const paymentRequestBody: PaymentRequest = {
    stamp: uuidv4(), // "Merchant unique ID" according to docs.
    reference: referenceNumber,
    amount: Math.round(amount * 100),
    currency: 'EUR', // only EUR supported
    language: sessionStorage.getItem('currentLanguage')?.toUpperCase() || 'FI',
    orderId: trimmedRefNum.slice(trimmedRefNum.length - 4) + '_' + String(Date.now()),
    items: [
      {
        unitPrice: Math.round(amount * 100), // Currency minor units i.e. cents
        units: 1,
        vatPercentage: 0,
        productCode: '1',
        stamp: uuidv4(),
        reference: referenceNumber,
        merchant: '', // Submerchant ID; Added in the backend.
      },
    ],
    customer,
    invoicingAddress,
    redirectUrls: {
      success: paytrailConfig.urlSuccess,
      cancel: paytrailConfig.urlCancel,
    },
  };

  return paymentRequestBody;
};

const Payment = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('billing.payment');
  const tBilling = useMessageGetter('billing');
  const { menu } = useAppState();
  const [{ currentBill, user, contract }, dispatch] = useKennoTenant();
  const [fetchingProviders, setFetchingProviders] = useState<boolean>(false);
  const minAmount = 0;
  const difference = currentBill.difference || 0;
  const [amountToPayInputValue, setAmountToPayInputValue] = useState<string>(String(minAmount));
  const [amountToPay, setAmountToPay] = useState<number>(minAmount);
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [paymentProviders, setPaymentProviders] = useState<Array<any>>([]);
  const [paytrailError, setPaytrailError] = useState<string | null>(null);

  useEffect(() => {
    loadCurrentBill(dispatch);
    loadContract(dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const amount = currentBill.difference && currentBill.difference < 0 ? currentBill.difference : minAmount;
    setAmountToPay(Math.abs(amount)); // Difference is negative if there's something to pay
    setAmountToPayInputValue(Math.abs(amount).toFixed(2));
  }, [difference]); // eslint-disable-line react-hooks/exhaustive-deps

  // Note: temporary implementation, only for Kenno launch, can be removed once
  // Y-Säätiö has confirmed financials in Kenno are correct
  if (shouldHideFinancialInfo()) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <H1>{t('title')}</H1>
          <MaintenanceNote translationKey="financial.paymentPage" />
        </PaddedContent>
      </PageContainer>
    );
  }

  // Creates a payment with the Paytrail API, returns list of
  // available payment providers AND a link to external payment portal.
  const initPayment = async () => {
    setPaytrailError(null);
    if (!Number.isNaN(amountToPay) && amountToPay >= 1) {
      setFetchingProviders(true);

      const paymentCustomer: PaymentCustomer = {
        email: user.attributes?.email!, // We verify that the email exists in "paymentNotPossible" check
        firstName: user.attributes?.firstName,
        lastName: user.attributes?.lastName,
        phone: user.attributes?.phoneNumber,
      };

      const paymentAddress: PaymentAddress = {
        streetAddress: contract.postalAddress?.streetAddressLine1 || '-',
        postalCode: contract.postalAddress?.postalCode || '-',
        city: contract.postalAddress?.city || '-',
        country: 'FI',
      };

      const paytrailBody = await getPaytrailFields(
        paymentCustomer,
        paymentAddress,
        amountToPay,
        contract.referenceNumber!
      );

      try {
        const response = await createPayment(paytrailBody);
        setPaymentLink(response.data.href);
        setPaymentProviders(response.data.providers);
      } catch (e) {
        console.error(`Failed to initialize Paytrail payment: ${(e as Error).message}`);
        setPaytrailError(t('error'));
      }
      setFetchingProviders(false);
    }
  };

  const onChangePaymentAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAmountToPayInputValue(e.target.value);
    const amount = parseFloat(e.target.value.replace(',', '.'));
    setAmountToPay(Number.isNaN(amount) || amount < minAmount ? minAmount : amount);
  };

  const parameterToInput = (param: any) => <input type="hidden" name={param.name} value={param.value} />;

  const paymentNotPossible =
    contract.loadState === LOAD_STATES.ERROR ||
    (contract.loadState === LOAD_STATES.READY &&
      !contract.referenceNumber &&
      !user.attributes?.firstName &&
      !user.attributes?.lastName &&
      !user.attributes?.phoneNumber &&
      !user.attributes?.email &&
      !contract.postalAddress?.streetAddressLine1 &&
      !contract.postalAddress?.postalCode);

  if (contract.loadState === LOAD_STATES.BUSY || currentBill.loadState === LOAD_STATES.BUSY) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <Loader />
        </PaddedContent>
      </PageContainer>
    );
  }

  if (paymentNotPossible) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <H1>{t('title')}</H1>
          <BasicParagraph>{t('errorhelptext')}</BasicParagraph>
        </PaddedContent>
      </PageContainer>
    );
  }

  if (paymentLink === null) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <H1>{t('title')}</H1>
          <PaymentSection>
            <Body2>{t('description')}</Body2>
            <LinkButton to={getMenuItemPathBySlug(menu.items, Slugs.BILLING)} size="medium" primary>
              {tBilling('title')}
            </LinkButton>
            <Body2 dangerouslySetInnerHTML={{ __html: t('description2') }} />
            {/*{difference < 0 && (
                <Actions>
                  <InternalLinkButton to={getMenuItemPathBySlug(menu.items, 'velkaerittely')}>
                    {t('showbilling')}
                  </InternalLinkButton>
                </Actions>
              )} */}
          </PaymentSection>

          <PaymentContainer>
            {fetchingProviders ? (
              <Loader />
            ) : (
              <>
                <H2>{t('subtitle1')}</H2>
                {paytrailError && <FormError message={paytrailError} />}
                <PaymentSection>
                  {!!(difference && difference < 0) && (
                    <DebtAmountInfo>
                      <H3>{t('description1')}</H3>
                      <H2>{formatEuros(Math.abs(difference))}</H2>
                    </DebtAmountInfo>
                  )}

                  <PaymentAmountContainer>
                    <Input
                      type="text"
                      inputmode="decimal"
                      name="amount"
                      placeholder={t('tobepaid')}
                      maxWidth="30rem"
                      onChange={onChangePaymentAmount}
                      value={amountToPayInputValue}
                    />
                    {amountToPay < 1 && <span>{t('amounttoolow')}</span>}
                  </PaymentAmountContainer>
                </PaymentSection>

                <PaymentSection>
                  <Body2>{t('paytrailinfo')}</Body2>
                  <Button onClick={() => initPayment()} icon="arrowForward" iconRight primary>
                    {t('continue')}
                  </Button>
                </PaymentSection>
              </>
            )}
          </PaymentContainer>
        </PaddedContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer history={history}>
      <PaddedContent>
        <H1>{t('title')}</H1>
        <BasicParagraph>
          <BasicText>{t('description')}</BasicText>
          <BasicParagraph>
            <div dangerouslySetInnerHTML={{ __html: t('description2') }} />
          </BasicParagraph>

          <H2>{t('subtitle2')}</H2>
          <PaymentMethodContainer>
            {paymentProviders.map((p) => (
              <form method="POST" action={p.url}>
                {p.parameters.map(parameterToInput)}
                <PaymentMethodLink type="submit">
                  <img src={p.svg} alt={p.name} />
                </PaymentMethodLink>
              </form>
            ))}
          </PaymentMethodContainer>
        </BasicParagraph>
      </PaddedContent>
    </PageContainer>
  );
};

/* const Actions = styled.div`
  margin-bottom: 2rem;

  button {
    margin: 0;
  }
`; */

const PaymentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
`;

const PaymentContainer = styled.div`
  background: ${(props) => props.theme.colors.apila};
  border-radius: 24px;
  padding: 24px 32px;
  margin-top: 30px;
`;

const DebtAmountInfo = styled.div`
  margin-top: 1rem;
`;

const PaymentAmountContainer = styled.div`
  margin: 1.5rem 0;
`;

const PaymentMethodContainer = styled.div`
  padding-top: 30px;
  --gap: 8px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
`;

const PaymentMethodLink = styled(ButtonLegacy)`
  display: flex;
  background-color: ${(props) => props.theme.colors.almostWhite};
  border-radius: 24px;
  margin: var(--gap) 0 0 var(--gap);
  padding: 1rem;
  width: 10rem;
  height: 8rem;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }

  img {
    object-fit: contain;
    width: 8rem;
  }
`;

export default Payment;
