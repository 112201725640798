import React from 'react';
import { Dropdown, DropdownProps } from '../../../components/common/Dropdown';
import { useMessageGetter } from 'react-message-context';

export enum CoverImageType {
  NoImage = 'noImage',
  PropertyImage = 'propertyImage',
}

export const CoverImageDropdown = ({ onChange, value, id }: Omit<DropdownProps, 'options' | 'width'>) => {
  const t = useMessageGetter('forms.eventInvite.coverImageOption');
  const options = [
    { label: t('propertyImage'), value: CoverImageType.PropertyImage },
    { label: t('noImage'), value: CoverImageType.NoImage },
  ];
  return <Dropdown width="270px" onChange={onChange} value={value} id={id} options={options} />;
};
