import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { Dispatcher, setLanguage } from './appActions';
import { appReducer } from './appReducer';
import messages from '../localizations/messages';
import { MessageProvider } from 'react-message-context';
import { MenuItem } from '../types/wordpress';
import { LOAD_STATES } from './common';

export enum Language {
  FI = 'fi',
  EN = 'en',
}

export interface CarouselWindowDimensions {
  height: number;
  width: number;
}

export interface CarouselItem {
  id: number;
  title: string;
  text: string;
  index: number;
  image: string;
}

export interface CarouselContent {
  content?: CarouselItem[];
  hideCarousel?: () => void;
  navigateToNext?: (event: React.MouseEvent<HTMLInputElement>) => void;
  navigateToPrev?: (event: React.MouseEvent<HTMLInputElement>) => void;
  dimensions?: CarouselWindowDimensions;
}

export interface Menu {
  loadState?: LOAD_STATES | false;
  items: MenuItem[];
}

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export interface Notification {
  message: string;
  type: NotificationType;
}

export interface AppState {
  carousel?: CarouselContent;
  language: Language | string;
  menu: Menu;
  notifications: Notification[];
}

export const initialState: AppState = {
  notifications: [],
  menu: { loadState: false, items: [] },
  language: '',
};

const AppStateContext = createContext({} as AppState);
const AppDispatchContext = createContext((() => {}) as Dispatcher);

function AppStateProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    const currentlanguage = sessionStorage.getItem('currentLanguage') || Language.FI;
    setLanguage(dispatch, currentlanguage);
  }, []);

  return (
    <MessageProvider
      locale={sessionStorage.getItem('currentLanguage') as string}
      messages={(messages as any)[sessionStorage.getItem('currentLanguage') as string]}
    >
      <AppStateContext.Provider value={state}>
        <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
      </AppStateContext.Provider>
    </MessageProvider>
  );
}

function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppStateProvider');
  }
  return context;
}

function useApp(): [AppState, Dispatcher] {
  return [useAppState(), useAppDispatch()];
}

export { AppStateProvider, useAppState, useAppDispatch, useApp };
