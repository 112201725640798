import React from 'react';
import { H2 } from '../../components/basic/Text';
import ServiceDescription from '../ServiceDescription';
import { FormContainer } from '../layoutComponents';
import { useMessageGetter } from 'react-message-context';

interface LoginFormProps {
  onSubmit: () => void;
  loginFailed: boolean;
  errorMessage: string;
}

const LoginForm: React.FC<LoginFormProps> = () => {
  const translationPrefix = 'signin';
  const t = useMessageGetter(translationPrefix);

  return (
    <FormContainer>
      <H2>{t('title')}</H2>
      <ServiceDescription />
    </FormContainer>
  );
};

export default React.memo(LoginForm);
