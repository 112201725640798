import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { theme } from '../../theme';
import { scrollToTop } from '../../utils';

interface ComponentProps {
  title: string;
  path: string;
}

const LinkWithChevron: React.FC<ComponentProps> = ({ title, path }) => {
  return (
    <LinkItem to={path} onClick={scrollToTop}>
      <span>{title}</span>
    </LinkItem>
  );
};

export const hoverEffectChevron = css`
  ::after {
    content: '';
    width: 12px;
    height: 12px;
    background: url('/assets/chevron-right-coal.svg') no-repeat center center;
    background-size: contain;
    display: inline-block;
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  :hover::after {
    opacity: 1;
  }
`;

const LinkItem = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${theme().colors.charcoal};
  display: flex;
  align-items: center;
  ${hoverEffectChevron};

  span {
    position: relative;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.2s ease-in;
  }

  :hover span {
    border-bottom: 2px solid ${theme().colors.black};
  }
`;

export default LinkWithChevron;
