import React, { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { chevronDownBlack, chevronUpBlack } from '../../assets';
import { baseFontStyle } from '../basic/Text';
import { MenuItem } from '../../types/wordpress';

interface SideMenuChildItemProps {
  name: string;
  to: string;
  clickSubMenuItem?: (event: React.MouseEvent<HTMLParagraphElement>, to: string) => void;
}

interface SideMenuItemProps extends SideMenuChildItemProps {
  clickMenuItem?: (event: React.MouseEvent<HTMLParagraphElement>, to: string) => void;
  children: MenuItem[];
  isOpen?: boolean;
}

const SubMenuItem = ({ name, to, clickSubMenuItem }: SideMenuChildItemProps) => {
  return (
    <Fragment>
      <NavLink activeClassName={'active'} exact to={to} className="subitem" role="menuitem" aria-labelledby="sideMenu">
        <SubNaviText onClick={(event) => clickSubMenuItem?.(event, to)}>{name}</SubNaviText>
      </NavLink>
    </Fragment>
  );
};

const SubNaviText = styled.p`
  ${baseFontStyle};
  font-family: ${(props) => props.theme.text.primaryFont};
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  padding: 12px 0;
`;

const SideMenuItem = ({ name, to, children, clickMenuItem, clickSubMenuItem, isOpen }: SideMenuItemProps) => {
  return (
    <MenuContainer>
      {children.length > 0 ? (
        <>
          <ParentMenuItem role="menuitem" aria-haspopup="true" onClick={(event) => clickMenuItem?.(event, to)}>
            <NaviText>{name}</NaviText>
            <ChevronDropdown
              id="chevronmenu"
              aria-label={`${isOpen ? 'Close' : 'Open'} the submenu`}
              type="image"
              src={isOpen ? chevronUpBlack : chevronDownBlack}
              alt="Close the submenu"
            />
          </ParentMenuItem>
          <SubMenuContainer isOpen={isOpen} subItems={children.length} className={isOpen ? 'open' : ''}>
            {children.map((child) => (
              <SubMenuItem name={child.title} to={child.path} clickSubMenuItem={clickSubMenuItem} key={child.title} />
            ))}
          </SubMenuContainer>
        </>
      ) : (
        <MenuItemNavLink key={name} activeClassName="active" id="mainnavi" exact to={to} role="menuitem">
          <NaviText>{name}</NaviText>
        </MenuItemNavLink>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;

const baseMenuItemStyle = css`
  border-bottom: 2px solid ${(props) => props.theme.colors.charcoal};
  margin: 10px 0;
`;

const ParentMenuItem = styled.div`
  ${baseMenuItemStyle};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MenuItemNavLink = styled(NavLink)`
  ${baseMenuItemStyle};
`;

const NaviText = styled.p`
  ${baseFontStyle};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.05px;
  cursor: pointer;
  margin: 0;
`;

const ChevronDropdown = styled.input`
  width: 16px;
  height: 16px;
`;

const SubMenuContainer = styled.div<{ isOpen?: boolean; subItems: number }>`
  transition: max-height 0.4s ease;
  max-height: 0;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.open {
    max-height: ${(props) =>
      `${props.subItems * 52}px`}; // Sets the approx submenu height based on the number of items.
  }
`;

export default SideMenuItem;
