import React from 'react';
import { Dropdown, DropdownProps } from '../../../components/common/Dropdown';

const YEAR_RANGE = 3;

export const YearDropdown = ({ onChange, value, id }: Omit<DropdownProps, 'options' | 'width'>) => {
  const currentYear = new Date().getFullYear();
  const options = Array.from({ length: YEAR_RANGE }, (_, index) => ({
    label: (currentYear - index).toString(),
    value: currentYear - index,
  }));

  return <Dropdown width="136px" onChange={onChange} value={value} id={id} options={options} />;
};
