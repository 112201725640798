import React from 'react';
import styled from 'styled-components/macro';
import { buttonStyles, CommonButtonProps, getIcon } from './Button';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../utils';

export const StyledLink = styled(Link)`
  ${buttonStyles}
`;

export type LinkButtonProps = CommonButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & { to: string };

/**
 * Link component which uses the same styles as project's Button component. Icon possibility, which can
 * render the predefined icon to the left side of the button, unless `iconRight` flag is given.
 * `primary` flag can be given to revert the colors.
 * Size can be defined with prop `size`. Default size is large.
 *
 * Example use:
 * <LinkButton icon='plus' primary iconRight to='/path'>Button text here</LinkButton>
 */
export const LinkButton: React.FC<LinkButtonProps> = ({
  icon,
  primary,
  iconRight,
  size = 'large',
  to,
  children,
  ...props
}) => {
  const renderedIcon = getIcon(icon, primary);

  return (
    <StyledLink to={to} primary={primary} iconRight={iconRight} size={size} onClick={scrollToTop} {...props}>
      {renderedIcon}
      {children}
    </StyledLink>
  );
};
