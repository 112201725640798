import React from 'react';
import styled from 'styled-components/macro';
import { DeviceSize } from '../components/basic/DeviceSize';
import { useMessageGetter } from 'react-message-context';
import { Body2 } from '../components/common';

const DescriptionContainer = styled.div`
  margin: 20px 0 0;

  li {
    padding: 2px 0;
  }

  @media ${DeviceSize.mobileL} {
    ul {
      padding-left: 20px;
    }
  }
`;

const ServiceDescription = () => {
  const t = useMessageGetter('signin.step1');

  return (
    <DescriptionContainer>
      <Body2>{t('tempMaintenanceInfo')}</Body2>
    </DescriptionContainer>
  );
};

export default ServiceDescription;
