import React from 'react';
import { Dropdown, DropdownProps } from '../common/Dropdown';
import { useMessageGetter } from 'react-message-context';

export enum MainContactCategory {
  CUSTOMER_SERVICE = '471',
  RENT_ISSUES = '473',
  HOUSING = '859',
}

export interface ContactCategory {
  value: MainContactCategory;
  subcategories: { value: string }[];
}

export const contactCategories: ContactCategory[] = [
  {
    value: MainContactCategory.CUSTOMER_SERVICE,
    subcategories: [
      { value: 'customerservice.apartmentapplications' },
      { value: 'customerservice.sauna_laundry_parking' },
      { value: 'customerservice.contracttermination' },
      { value: 'customerservice.propertymanagement' },
      { value: 'customerservice.faultreports' },
      { value: 'customerservice.other' },
    ],
  },
  // {
  //   value: MainContactCategory.RENT_ISSUES,
  //   subcategories: [
  //     { value: 'billing.latepayment' },
  //     { value: 'billing.paymentissues' },
  //     { value: 'billing.debtrepayment' },
  //   ],
  // },
  // {
  //   value: MainContactCategory.HOUSING,
  //   subcategories: [
  //     { value: 'billing.paymentissues' },
  //     { value: 'billing.debtrepayment' },
  //     { value: 'housing.debtassistance' },
  //     { value: 'housing.benefitapplication' },
  //     { value: 'housing.tooexpensive' },
  //     { value: 'housing.neighbourdispute' },
  //     { value: 'housing.evictionassistance' },
  //     { value: 'housing.dailyerrandsassistance' },
  //     { value: 'housing.mentalhealthandsubstanceabuse' },
  //   ],
  // },
];

export const CategoryDropdown = ({ onChange, value, id, width, hasError }: Omit<DropdownProps, 'options'>) => {
  const t = useMessageGetter('messages.new.form.categories');

  const options = contactCategories.map((category) => {
    const label = t(category.value);
    return { label: label, value: category.value };
  });

  return <Dropdown onChange={onChange} value={value} id={id} options={options} width={width} hasError={hasError} />;
};
