import styled from 'styled-components/macro';
import { DeviceSize } from '../../components/basic/DeviceSize';

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  margin-top: 1rem;
`;

export const FormContent = styled.div`
  flex: 1;
  max-width: 628px;
  @media ${DeviceSize.mobileL} {
    padding-left: 16.5px;
    padding-right: 16.5px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  gap: 20px;
  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    gap: 0px;
  }
`;

/**
 * CSS grid container.
 * * columns defines the number of columns used, defaults to 2
 * * mdColumns defines the number of columns when device size is mobileL, defaults to columns if given, otherwise 2
 */
export const GridContainer = styled.div<{
  columns?: number;
  mdColumns?: number;
  rowGap?: string;
  columnGap?: string;
  gap?: string;
}>`
  display: grid;
  column-gap: ${({ columnGap }) => columnGap};
  row-gap: ${({ rowGap }) => rowGap};
  gap: ${({ gap }) => gap};
  grid-template-columns: repeat(${({ columns }) => columns ?? 2}, 1fr);
  @media ${DeviceSize.mobileL} {
    grid-template-columns: repeat(${({ mdColumns, columns }) => mdColumns ?? columns ?? 2}, 1fr);
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
`;

/**
 * Flex column component
 * * span will determine how many column spaces the column takes from css grid
 * * mdSpan will determine how many column spaces the column takes when deviceSize = mobileL
 */
export const Column = styled.div<{ span?: number; mdSpan?: number; gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  grid-column: span ${({ span }) => span ?? 1};
  @media ${DeviceSize.mobileL} {
    grid-column: span ${({ mdSpan, span }) => mdSpan ?? span ?? 1};
  }
`;

export const RequiredIndicator = styled.span`
  color: red;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

export const PreviewContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 24px;
`;
