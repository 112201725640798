import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { Button } from '../../../components/common';
import { FormPreviewActions } from '../../../components/common/forms/FormPreviewActions';
import { useFormattedClockTime } from '../../../hooks';
import { formatISODate } from '../../../utils';
import { PreviewContentBlock, Row } from '../layoutComponents';
import { Bold, H2, TextBlock } from '../Text';
import { ExpandableItem } from './ExpandableItem';
import { ActionReportFormState } from './schema';

const PreviewContent = styled(PreviewContentBlock)`
  gap: 40px;
`;

const EventSummary = styled(PreviewContentBlock)`
  gap: 10px;
`;

const Events = styled(PreviewContentBlock)`
  gap: 0px;
`;
const EventContent = styled(PreviewContentBlock)`
  gap: 16px;
`;
const EventDetails = styled(PreviewContentBlock)`
  margin-top: 16px;
  gap: 4px;
`;

const ExpandableEventItem = styled(ExpandableItem)`
  padding: 16px;
  border-bottom: 1px solid black;
`;

type Props = { closePreview: () => void };

export const ActionReportPreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<ActionReportFormState>();
  const t = useMessageGetter('forms');
  const { formatClockTime } = useFormattedClockTime();

  const formData = watch();

  const totalEventCountText =
    formData.activities.length === 1
      ? t('actionReport.eventCount', { count: formData.activities.length })
      : t('actionReport.eventCount_plural', { count: formData.activities.length });

  const totalExpenses = formData.activities.reduce((sum, obj) => sum + obj.expenses, 0).toFixed(2);

  const formatDate = (year: number | undefined, month: number | undefined, day: number | undefined) => {
    if (year && month && day) {
      const isoString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

      return formatISODate(isoString, true);
    }
    return '';
  };

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {t('closePreview')}
        </Button>
      </Row>
      <H2>{t('actionReport.previewTitle', { year: formData.year })}</H2>
      <EventSummary>
        <span>{totalEventCountText}</span>
        <Row>
          <Bold>{t('actionReport.totalExpenses')}</Bold>
          <span>{`${totalExpenses}€`}</span>
        </Row>
      </EventSummary>
      <Events>
        {formData.activities.map((event) => (
          <ExpandableEventItem title={event.name}>
            <EventContent>
              <EventDetails id={event.name}>
                <Row>{`${formatDate(formData.year, event.month, event.day)} ${formatClockTime(event.time)}`}</Row>
                <Row>
                  <Bold>{`${t('actionReport.expenses')}:`}</Bold>
                  {`${event.expenses}€`}
                </Row>
              </EventDetails>
              <TextBlock>{event.additionalInformation}</TextBlock>
            </EventContent>
          </ExpandableEventItem>
        ))}
      </Events>
      <FormPreviewActions handleEdit={closePreview} handleSubmit={() => console.log('wip')} />
    </PreviewContent>
  );
};
