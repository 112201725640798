import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { CalendarIcon, LocationIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { FormPreviewActions } from '../../../components/common/forms/FormPreviewActions';
import { useFormattedClockTime } from '../../../hooks';
import { formatISODate } from '../../../utils';
import { Bold, H2, H5, TextBlock, Underline } from '../Text';
import { PreviewContentBlock, Row } from '../layoutComponents';
import { EventType } from './EventTypeDropdown';
import { EventInviteFormState } from './schema';

const PreviewContent = styled(PreviewContentBlock)``;
const EventDetails = styled(PreviewContentBlock)`
  gap: 16px;
`;
const EventDescription = styled(PreviewContentBlock)`
  gap: 10px;
`;

type Props = { closePreview: () => void };

export const EventInvitePreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<EventInviteFormState>();
  const tForms = useMessageGetter('forms');
  const t = useMessageGetter('forms.eventInvite');
  const { formatClockTime } = useFormattedClockTime();

  const formData = watch();

  const formattedDate = formData.date ? formatISODate(formData.date, true) : '';

  const isCommunityMeeting = formData.eventType === EventType.CommunityMeeting;
  const titleText = isCommunityMeeting ? `${t('communityMeeting')}: ${formData.name}` : formData.name;

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {tForms('closePreview')}
        </Button>
      </Row>
      <H2>{titleText}</H2>
      <EventDetails>
        <Row>
          <CalendarIcon />{' '}
          <Bold>
            {formattedDate} {formatClockTime(formData.time)}
          </Bold>
        </Row>
        <Row>
          <LocationIcon />
          <Underline>
            {formData.property} {isCommunityMeeting && ` - ${formData.location}`}
          </Underline>
        </Row>
      </EventDetails>
      {isCommunityMeeting && <H5>{t('agenda')}</H5>}
      <EventDescription>
        <TextBlock>{formData.description}</TextBlock>
        {isCommunityMeeting && <TextBlock style={{ marginTop: '40px' }}>{formData.regards}</TextBlock>}
      </EventDescription>
      <FormPreviewActions handleEdit={closePreview} handleSubmit={() => console.log('wip')} />
    </PreviewContent>
  );
};
