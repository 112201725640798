import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Body2, Body4, Button, LinkButton, TextButton } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { InputField } from '../../components/common/forms';
import { ButtonContainer, Form, FormContainer } from '../layoutComponents';
import { Routes } from '../../constants/routes';
import FormError from '../../components/common/forms/FormError';
import { NewPasswordFormState, newPasswordSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components/macro';
import { confirmNewPassword } from '../../apis_kenno';
import { DeviceSize } from '../../components/basic/DeviceSize';
import { AxiosError } from 'axios';
import { H2 } from '../../components/basic/Text';
import { scrollToTop } from '../../utils';

const PasswordInstructions = styled.div`
  margin: 0 20px 20px 20px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    text-align: center;
    button {
      height: 48px;
    }
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

interface NewPasswordStepProps {
  email: string;
  onClickNewCode: () => void;
}

const NewPasswordStep: React.FC<NewPasswordStepProps> = ({ email, onClickNewCode }) => {
  const t = useMessageGetter('');
  const formTranslationPrefix = 'signup';
  const [isBusy, setIsBusy] = useState(false);
  const [stepFailed, setStepFailed] = useState(false);
  const [stepReady, setStepReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleStepReady = () => {
    setStepReady(true);
    scrollToTop();
  };

  const formMethods = useForm<NewPasswordFormState>({ resolver: zodResolver(newPasswordSchema) });
  const onSubmit: SubmitHandler<NewPasswordFormState> = async (data) => {
    setStepFailed(false);
    setIsBusy(true);

    const payload = {
      username: email,
      code: data.code,
      newPassword: data.password,
    };

    try {
      await confirmNewPassword(payload);
      handleStepReady();
    } catch (error) {
      setStepFailed(true);
      setIsBusy(false);
      console.error('Failed to set new password', error);
      if (error instanceof AxiosError && error.response?.status === 400) {
        const exception = error.response?.data?.error;
        if (exception === 'InvalidCode') {
          setErrorMessage(t('signup.invalidCodeError'));
        }
        if (exception === 'ExpiredCode') {
          setErrorMessage(t('signup.expiredCodeError'));
        }
        if (exception === 'LimitExceeded') {
          setErrorMessage(t('signup.limitExceededError'));
        }
      } else {
        setErrorMessage(t('signup.generalError'));
      }
    }
  };

  return (
    <FormContainer>
      {!stepReady ? (
        <FormProvider {...formMethods}>
          <H2>{t('signin.setNewPassword')}</H2>
          <Body2>{t('signin.setNewPasswordInfo')}</Body2>
          <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputField
              fieldPath="code"
              placeholder={t('code')}
              translationPrefix={formTranslationPrefix}
              type="text"
              required
              dark
            />
            <InputField fieldPath="password" translationPrefix={formTranslationPrefix} type="password" required dark />
            <PasswordInstructions>
              <Body4>{t('signup.passwordRequirements')}</Body4>
            </PasswordInstructions>
            <InputField
              fieldPath="confirmPassword"
              translationPrefix={formTranslationPrefix}
              type="password"
              required
              dark
            />
            {stepFailed && <FormError message={errorMessage} />}
            <BottomRow>
              <ButtonContainer>
                <Button type="submit" id="submit" primary disabled={isBusy}>
                  {t('signin.save')}
                </Button>
                <LinkButton to={Routes.SIGN_IN}>{t('signin.cancel')}</LinkButton>
              </ButtonContainer>
              <TextButton onClick={onClickNewCode}>{t('signin.newCode')}</TextButton>
            </BottomRow>
          </Form>
        </FormProvider>
      ) : (
        <SuccessContainer>
          <H2>{t('signin.newPasswordSaved')}</H2>
          <ButtonContainer>
            <LinkButton to={Routes.SIGN_IN} primary>
              {t('signin.continueToLogin')}
            </LinkButton>
          </ButtonContainer>
        </SuccessContainer>
      )}
    </FormContainer>
  );
};

export default NewPasswordStep;
