import React from 'react';
import styled from 'styled-components/macro';
import { theme } from '../theme';
import { NotificationType, useAppState } from '../context/appContext';
import { DeviceSize } from './basic/DeviceSize';
import { Body2 } from './common/Text';
import { infoFilledIcon } from '../assets';

const notificationColors: Record<NotificationType, string> = {
  [NotificationType.SUCCESS]: theme().colors.apila,
  [NotificationType.ERROR]: theme().colors.strawberry,
  [NotificationType.INFO]: theme().colors.darkSnow,
};

const NotificationContainer = styled.div<{ type: NotificationType; visible: boolean }>`
  position: fixed;
  width: 600px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ type }) => notificationColors[type]};
  padding: 0 24px;
  border-radius: 16px;
  z-index: 1000;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media ${DeviceSize.desktopS} {
    width: 80%;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;

  img {
    margin-top: 16px;
  }
`;

const Notifications: React.FC = () => {
  const { notifications } = useAppState();

  return (
    <div>
      {notifications.map((notification, i) => (
        <NotificationContainer key={i} type={notification.type} visible={!!notifications.length}>
          <MessageContainer>
            <img src={infoFilledIcon} alt="" />
            <Body2>{notification.message}</Body2>
          </MessageContainer>
        </NotificationContainer>
      ))}
    </div>
  );
};

export default Notifications;
