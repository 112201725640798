import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { H2 } from '../forms';
import { Body2, Button, Checkbox, LinkButton, TextLinkExternal } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { InputField } from '../../components/common/forms';
import { ButtonContainer, Form, FormContainer } from '../layoutComponents';
import { Routes } from '../../constants/routes';
import FormError from '../../components/common/forms/FormError';
import { AcceptTermsFormState, acceptTermsSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormRow } from '../forms/layoutComponents';
import { DropdownOptionValue } from '../../components/common/Dropdown';
import { initiateRegistration } from '../../apis_kenno';
import { Input } from '../../components/input/Input';

const StrongAuthStep = () => {
  const translationPrefix = 'signup';
  const t = useMessageGetter(translationPrefix);
  const [stepFailed, setStepFailed] = useState(false);
  const formMethods = useForm<AcceptTermsFormState>({ resolver: zodResolver(acceptTermsSchema) });
  // Used to pass data to registration initialization request body. Not required in normal usage.
  const [postData, setPostData] = useState<any | undefined>(undefined);

  const onSubmit: SubmitHandler<AcceptTermsFormState> = async () => {
    try {
      const { url, state } = await initiateRegistration(postData);
      // Session storage required for this since user is moved to external OIDC provider
      window.sessionStorage.setItem('oauthState', state);
      window.location.href = url;
    } catch (error) {
      // Terms were not accepted
      setStepFailed(true);
    }
  };

  return (
    <FormContainer>
      <FormProvider {...formMethods}>
        <H2>{t('title')}</H2>
        <Body2>{t('instructions')}</Body2>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputField<AcceptTermsFormState, DropdownOptionValue>
            fieldPath="termsAccepted"
            translationPrefix={translationPrefix}
            hideLabel
            dark
            inputElementRenderer={({ value, id, onChange }) => (
              <FormRow style={{ gap: '12px' }}>
                <Checkbox value={!!value} id={id} onChange={onChange} />
                <div>
                  {`${t('accept')} `}
                  <TextLinkExternal
                    href="https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('termsOfUse')}
                  </TextLinkExternal>
                </div>
              </FormRow>
            )}
          />
          {stepFailed && <FormError message={t('generalError')} />}
          {process.env.REACT_APP_USE_MOCK_OIDC === '1' && (
            <Input
              name="Testing SSN"
              placeholder="Tenant SSN"
              onChange={(event) => setPostData({ testSsn: event.target.value })}
            />
          )}
          <ButtonContainer>
            <Button type="submit" id="submit" primary>
              {t('continue')}
            </Button>
            <LinkButton to={Routes.SIGN_IN}>{t('cancel')}</LinkButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </FormContainer>
  );
};

export default StrongAuthStep;
