import React, { FunctionComponent, Fragment, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useMessageGetter } from 'react-message-context';
import { mintCross } from '../../assets';
import { H2 } from './Text';
import ButtonLegacy from '../button/ButtonLegacy';
import { DeviceSize } from './DeviceSize';
import { modalFocusableElements } from '../../utils';

type RedirectModalProps = {
  cancelRedirect: () => void;
  continueRedirect: () => void;
  modalShown?: boolean;
};

const RedirectModal: FunctionComponent<RedirectModalProps> = ({
  cancelRedirect,
  continueRedirect,
  modalShown,
  children,
}) => {
  const t = useMessageGetter('modal');

  useEffect(() => {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        cancelRedirect();
      }
    });
  });

  useEffect(() => {
    if (modalShown) {
      const modalElements = document.querySelectorAll(
        modalFocusableElements
      ) as unknown as HTMLCollectionOf<HTMLInputElement>;
      const first = modalElements[0];
      const last = modalElements[modalElements.length - 1];

      first.focus();

      document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode !== 9 || e.key !== 'Tab') return;

        if (e.keyCode === 9) {
          if (e.shiftKey) {
            if (document.activeElement === first) {
              last.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === last) {
              first.focus();
              e.preventDefault();
            }
          }
        }
      });
    }
  }, [modalShown]);

  const continueToPage = () => {
    continueRedirect();
  };

  const cancelModal = () => {
    cancelRedirect();
  };

  return (
    <Fragment>
      <Overlay />
      <ModalContainer role="dialog" aria-modal="true" aria-labelledby="modal-text">
        <Content>
          <CancelIcon>
            <input className="modal-input" type="image" src={mintCross} onClick={cancelModal} alt="Cancel" />
          </CancelIcon>
          <Text>
            <H2 id="modal-text">{children}</H2>
          </Text>
          <ButtonGroup>
            <ButtonLegacy className="modal-input" onClick={continueToPage}>
              {t('continue')}
            </ButtonLegacy>
            <ButtonLegacy className="modal-input" onClick={cancelModal}>
              {t('cancel')}
            </ButtonLegacy>
          </ButtonGroup>
        </Content>
      </ModalContainer>
    </Fragment>
  );
};

export default RedirectModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;

  @media ${DeviceSize.desktopS} {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 30px;
`;

const Text = styled.div`
  padding-left: 51px;
  padding-right: 51px;
  margin-bottom: 42px;

  @media ${DeviceSize.mobileL} {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  > button {
    margin: auto 0;

    :first-child {
      margin-right: 20px;
    }
  }

  @media ${DeviceSize.mobileL} {
    flex-direction: column;

    > button {
      margin: 0;

      :first-child {
        margin-bottom: 5px;
        margin-right: 0;
      }
    }
  }
`;

const CancelIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.mint};
`;
