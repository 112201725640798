import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import HomePage from './pages/homepage/HomePage';
import ProfilePage from './pages/ProfilePage';
import SignInPage from './pages/auth/SignInPage';
import WordPressPage from './pages/WordPressPage';
import * as history from 'history';
import { reactPageSlugs } from './constants/reactPageSlugs';
import { Routes } from './constants/routes';
import { useApp } from './context/appContext';
import {
  TravelExpenseReport,
  ActionPlan,
  ActionReport,
  ExpenseReport,
  MeetingMinutes,
  EventInvite,
} from './pages/forms';
import { flattenMenuItems } from './utils/wordpress';
import BillingPage from './pages/billing/BillingPage';
import AuthenticatedRoute from './AuthenticatedRoute';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import SignUpPage from './pages/auth/SignUpPage';

const customHistory = history.createBrowserHistory();

const getPageComponent = (pageSlug: string) => (pageSlug in reactPageSlugs ? reactPageSlugs[pageSlug] : WordPressPage);

const RoutingRules = () => {
  const [{ menu }] = useApp();
  const menuItems = menu.items.length > 0 && flattenMenuItems(menu.items);

  useEffect(() => {
    return customHistory.listen((location) => ReactGA.pageview(location.pathname));
  }, []);

  return (
    <Router history={customHistory}>
      <Switch>
        <Route path={Routes.SIGN_IN} component={SignInPage} />
        <Route path={Routes.RESET_PASSWORD} component={ResetPasswordPage} />
        <Route path={Routes.SIGN_UP} component={SignUpPage} />

        <AuthenticatedRoute path={Routes.BILLING} component={BillingPage} />

        <AuthenticatedRoute exact path={Routes.HOME} component={HomePage} />
        <AuthenticatedRoute path={Routes.PROFILE} component={ProfilePage} />
        {/* <AuthenticatedRoute path={Routes.SEARCH} component={SearchPage} /> */}
        <AuthenticatedRoute path={Routes.Tenancy.PAYMENT_SUCCESS} component={BillingPage} />
        <AuthenticatedRoute path={Routes.Tenancy.PAYMENT_ERROR} component={BillingPage} />
        {/* <AuthenticatedRoute path={Routes.APARTMENT_SWAP} component={ApartmentSwapPage} /> */}
        {/* <AuthenticatedAdminRoute path={Routes.Administrative.HOME} component={AdministrativeHome} /> */}
        <AuthenticatedRoute path={Routes.ResidentActivity.MEETING_MINUTES} component={MeetingMinutes} />
        <AuthenticatedRoute path={Routes.ResidentActivity.ACTION_PLAN} component={ActionPlan} />
        <AuthenticatedRoute path={Routes.ResidentActivity.ACTION_REPORT} component={ActionReport} />
        <AuthenticatedRoute path={Routes.ResidentActivity.EXPENSE_REPORT} component={ExpenseReport} />
        <AuthenticatedRoute path={Routes.ResidentActivity.TRAVEL_EXPENSE_REPORT} component={TravelExpenseReport} />
        <AuthenticatedRoute path={Routes.ResidentActivity.EVENT_INVITE} component={EventInvite} />
        {menuItems &&
          menuItems
            .filter((item) => item.parentId !== 0)
            .map((item) => (
              <AuthenticatedRoute
                exact
                key={item.title}
                path={item.path}
                component={getPageComponent(item.slug)}
                slug={item.slug}
              />
            ))}
        {menuItems && <Redirect to={Routes.HOME} />}
      </Switch>
    </Router>
  );
};

export default RoutingRules;
