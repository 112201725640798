import Axios from 'axios';
import { removeLanguagePostfix } from '../pages/pageUtils';
import { MenuItem } from '../types/wordpress';
import { BlockSlugs } from '../constants/knownSlugs';
import { htmlDecode } from '../utils';
import { setupCache } from 'axios-cache-interceptor';

const instance = Axios.create();
const axios = setupCache(instance, {
  cacheTakeover: false, // Remove the cache headers, as they're not allowed by the WP API
});

export const getWordPressEndpoint = () => process.env.REACT_APP_WORDPRESS_REST_URL;

export const getWordPressAuth = () => {
  const username = process.env.REACT_APP_WORDPRESS_API_USER ?? '';
  const password = process.env.REACT_APP_WORDPRESS_API_KEY ?? '';

  return { username, password };
};

const wpUrl = getWordPressEndpoint();
const params = { auth: getWordPressAuth() };

const getMenusFromWp = async (): Promise<any> => {
  try {
    const response = await axios.get(`${wpUrl}/menus`, params);
    const menus: any[] = response.data;
    return menus;
  } catch (error) {
    console.error('Error fetching menus from WordPress:', error);
    throw error;
  }
};

const getMenuItemsFromWp = async (menuId: number): Promise<any[]> => {
  try {
    const response = await axios.get(`${wpUrl}/menu-items?menus=${menuId}`, params);
    const menuItems: any[] = response.data;
    return menuItems;
  } catch (error) {
    console.error('Error fetching menu items from WordPress:', error);
    throw error;
  }
};

const processMenuItems = (items: any[]): MenuItem[] => {
  const itemMap = items.reduce((acc, item) => {
    acc[item.id] = {
      id: item.id,
      title: htmlDecode(item.title.rendered),
      parentId: item.parent,
      slug: item.slug,
      path: '',
      children: [],
    };
    return acc;
  }, {} as Record<number, MenuItem>);

  const generatePath = (item: MenuItem): string => {
    const slug = removeLanguagePostfix(item.slug);

    if (item.parentId === 0) {
      return `/${slug}`;
    } else {
      const parent = itemMap[item.parentId];
      return `${generatePath(parent)}/${slug}`;
    }
  };

  const topLevelMenuItems: MenuItem[] = [];

  items.forEach((item) => {
    const menuItem = itemMap[item.id];
    menuItem.path = generatePath(menuItem);
    if (item.parent === 0) {
      topLevelMenuItems.push(menuItem);
    }
    if (itemMap[item.parent]) {
      itemMap[item.parent].children.push(menuItem);
    }
  });

  return topLevelMenuItems;
};

export const flattenMenuItems = (items: MenuItem[]): MenuItem[] => {
  return items.reduce<MenuItem[]>((acc, item) => {
    acc.push(item);
    if (item.children.length > 0) {
      acc.push(...flattenMenuItems(item.children));
    }
    return acc;
  }, []);
};

export const getMenuItems = async (currentLanguage: string): Promise<MenuItem[]> => {
  try {
    const wpMenus = await getMenusFromWp();
    const menuIdByLanguage = wpMenus.find((menu: any) => menu.name === currentLanguage)?.id;

    const wpMenuItems = await getMenuItemsFromWp(menuIdByLanguage);
    const menuItems = processMenuItems(wpMenuItems);
    return menuItems;
  } catch (error) {
    console.error('Error getting and processing menu items:', error);
    throw error;
  }
};

export const getMenuItemPathBySlug = (menuItems: MenuItem[], slug: string) => {
  const items = flattenMenuItems(menuItems);
  return items.find((item) => removeLanguagePostfix(item.slug) === slug)?.path || '/';
};

export const getInternetSlug = (isp: string | undefined, broadbandType: string | undefined) => {
  if (isp === undefined || broadbandType === undefined) return;
  if (isp === 'DNA') {
    if (broadbandType === 'Ethernet') return BlockSlugs.DNA_ETHERNET;
    if (broadbandType === 'Kaapelimodeemi') return BlockSlugs.DNA_MODEM;
    return BlockSlugs.DNA;
  } else if (isp === 'Elisa') return 'elisa';
  return BlockSlugs.NO_INTERNET;
};

export const getSaunaSlug = (hasSauna: boolean | undefined, hasCalendar: boolean | undefined) => {
  if (hasSauna === undefined || hasCalendar === undefined) {
    return;
  }
  if (hasSauna && hasCalendar) {
    return BlockSlugs.SAUNA_CALENDAR;
  }
  if (hasSauna) {
    return BlockSlugs.SAUNA;
  }
  return BlockSlugs.NO_SAUNA;
};

export const getLaundrySlug = (laundry: boolean | undefined, hasCalendar: boolean | undefined) => {
  if (laundry === undefined || hasCalendar === undefined) {
    return;
  }
  if (laundry && hasCalendar) {
    return BlockSlugs.LAUNDRY_CALENDAR;
  }
  if (laundry) {
    return BlockSlugs.LAUNDRY;
  }
  return BlockSlugs.NO_LAUNDRY;
};
