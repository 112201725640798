/* define device sizes here and use them in media queries*/
const size = {
  mobileL: '670px',
  desktopS: '1024px',
  desktopM: '1280px',
};

export const DeviceSize = {
  mobileL: `(max-width: ${size.mobileL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`,
};
