import React, { useState } from 'react';
import Card from '../common/Card';
import { theme } from '../../theme';
import { HousingQuestionsPictogram } from '../../assets';
import { Body2 } from '../common/Text';
import styled from 'styled-components/macro';
import { useMessageGetter } from 'react-message-context';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import ReactGA from 'react-ga';
import { showNotification } from '../../context/appActions';
import { NotificationType, useAppDispatch } from '../../context/appContext';
import { Button } from '../common';
import { DeviceSize } from '../basic/DeviceSize';
import FormInstructions from './FormInstructions';
import axios from 'axios';
import { InputField, TextAreaElement } from '../common/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContactFormState, schema } from './schema';
import { DropdownOptionValue } from '../common/Dropdown';
import { CategoryDropdown, MainContactCategory } from './CategoryDropdown';
import { SubcategoryDropdown } from './SubcategoryDropdown';
import FormError from '../common/forms/FormError';
import { useKennoTenant } from '../../context/kennoTenantContext';

const FormInfo = styled(Body2)`
  width: 50%;

  @media ${DeviceSize.desktopS} {
    width: 100%;
  }
`;

const FormContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media ${DeviceSize.mobileL} {
    display: flex;
    flex-direction: column;
  }
`;

// Service provided by Elisa
const CUSTOMER_SERVICE_FORM_HANDLER_URL =
  'https://form-api-y-saatio.eee.do/Dd3jRaanbrrnQZ9WrTdJpUugNf6DwPzxVeVTuTDHdLjuZbqE6vNKMbc/formhandler/';

const ContactForm: React.FC = () => {
  const [{ user }] = useKennoTenant();
  const appDispatch = useAppDispatch();
  const t = useMessageGetter('');
  const [isBusy, setIsBusy] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const formMethods = useForm<ContactFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      phone: user.attributes?.phoneNumber || '',
      email: user.attributes?.email || '',
      category: MainContactCategory.CUSTOMER_SERVICE,
      // Add language for selecting correct email response template in backend
      language: sessionStorage.getItem('currentLanguage') || 'fi',
    },
  });

  const { watch, reset, handleSubmit } = formMethods;
  const category = watch('category');
  const subcategory = watch('subcategory');

  const submitFormToCustomerService = async (data: ContactFormState) => {
    const formData = new FormData(); // Mimics browser's default form submit behavior
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    try {
      await axios.post(CUSTOMER_SERVICE_FORM_HANDLER_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxRedirects: 0,
      });
      await showNotification(appDispatch, t('messages.new.form.messagesent'), NotificationType.SUCCESS);
      // TODO: return this when ported to new backend
      // await postMessage(data); // Backend sends confirmation email
      handleEmptyForm();
    } catch (error) {
      console.error(error);
      await showNotification(appDispatch, t('messages.new.form.messagefailure'), NotificationType.ERROR);
    }
  };

  const onSubmit: SubmitHandler<ContactFormState> = async (data) => {
    setSubmitFailed(false);
    setIsBusy(true);

    try {
      if (
        data.category === MainContactCategory.CUSTOMER_SERVICE ||
        (data.category === MainContactCategory.HOUSING && data.subcategory === 'billing.latepayment')
      ) {
        await submitFormToCustomerService(data);
      } else {
        //await postMessage(data);
        await showNotification(appDispatch, t('messages.new.form.messagesent'), NotificationType.SUCCESS);
        handleEmptyForm();
      }

      ReactGA.event({
        category: `New message ${t(`form.categories.${data.category}`)}`,
        action: `Submit subject: ${data.subject}`,
        label: data.content,
      });
    } catch (error) {
      console.error(error);
      setSubmitFailed(true);
      setErrorMessage(t('messages.new.form.messagefailure'));
    } finally {
      setIsBusy(false);
    }
  };

  const disableSubmit =
    !user.attributes?.email ||
    subcategory === 'customerservice.faultreports' ||
    subcategory === 'customerservice.sauna_laundry_parking';

  const handleEmptyForm = () => {
    reset();
  };

  const translationPrefix = 'forms.contact';

  return (
    <FormProvider {...formMethods}>
      <Card title={t('messages.new.title')} Pictogram={HousingQuestionsPictogram} background={theme().colors.mint}>
        <FormInfo>{t('messages.new.info')}</FormInfo>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContentContainer>
            <div>
              <InputField<ContactFormState, DropdownOptionValue>
                fieldPath="category"
                translationPrefix={translationPrefix}
                required
                inputElementRenderer={(props) => <CategoryDropdown {...props} />}
              />
              <InputField<ContactFormState, DropdownOptionValue>
                fieldPath="subcategory"
                translationPrefix={translationPrefix}
                required
                inputElementRenderer={(props) => <SubcategoryDropdown {...props} category={category} />}
              />
            </div>
            <FormInstructions subcategory={subcategory} showOnMobileOnly />
            <div>
              <InputField<ContactFormState, string>
                fieldPath="subject"
                translationPrefix={translationPrefix}
                placeholder={t(`${translationPrefix}.subject`)}
                type="text"
                required
              />
              <InputField<ContactFormState, string>
                fieldPath="content"
                translationPrefix={translationPrefix}
                required
                inputElementRenderer={(props) => (
                  <TextAreaElement {...props} placeholder={t(`${translationPrefix}.contentPlaceholder`)} />
                )}
              />
            </div>
          </FormContentContainer>
          <FormInstructions subcategory={subcategory} showOnMobileOnly={false} />
          {submitFailed && <FormError message={errorMessage} />}
          <Button type="submit" primary icon="send" disabled={disableSubmit || isBusy}>
            {t('messages.new.form.button')}
          </Button>
        </form>
      </Card>
    </FormProvider>
  );
};

export default ContactForm;
