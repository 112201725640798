import React from 'react';
import styled from 'styled-components/macro';
import { CheckMarkIcon } from '../../assets';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledCheckboxContainer = styled.label<{ checked: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: solid 2px;
  background-color: ${(props) => (props.checked ? props.theme.colors.black : props.theme.colors.white)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCheckMark = styled(CheckMarkIcon)`
  width: 14px;
  height: 16px;
  path {
    fill: ${(props) => props.theme.colors.white};
  }
`;

type CheckboxProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  id: string;
};

export const Checkbox = ({ value, onChange, id }: CheckboxProps) => {
  return (
    <StyledCheckboxContainer htmlFor={id} checked={value}>
      <HiddenCheckbox id={id} checked={value} onChange={(e) => onChange(e.target.checked)} />
      {value && <StyledCheckMark />}
    </StyledCheckboxContainer>
  );
};
