import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { H2 } from '../forms';
import { Body2, Body4, Button, LinkButton } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { InputField } from '../../components/common/forms';
import { ButtonContainer, Form, FormContainer } from '../layoutComponents';
import { Routes } from '../../constants/routes';
import FormError from '../../components/common/forms/FormError';
import { SetCredentialsFormState, setCredentialsSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components/macro';
import { submitRegistrationCredentials } from '../../apis_kenno';
import { PasswordIsInvalidError, UsernameIsInvalidError } from '../../types/exceptions';

const PasswordInstructions = styled.div`
  margin: 0 20px 20px 20px;
`;

type Props = {
  oauthState: string;
  registerSessionId: string;
  setUsername: (email: string) => void;
};

const SetCredentialsStep: React.FC<Props> = ({ oauthState, setUsername, registerSessionId }) => {
  const translationPrefix = 'signup';
  const t = useMessageGetter(translationPrefix);
  const [stepFailed, setStepFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('credentialsError');
  const formMethods = useForm<SetCredentialsFormState>({ resolver: zodResolver(setCredentialsSchema) });

  const onSubmit: SubmitHandler<SetCredentialsFormState> = async (data) => {
    const { username, password } = data;
    try {
      await submitRegistrationCredentials(username, password, oauthState, registerSessionId);
      setStepFailed(false);
      setUsername(username);
    } catch (error: any) {
      switch (true) {
        case error instanceof UsernameIsInvalidError:
          setErrorMessage('usernameError');
          break;
        case error instanceof PasswordIsInvalidError:
          setErrorMessage('passwordError');
          break;
        default:
          setErrorMessage('credentialsError');
      }
      setStepFailed(true);
    }
  };

  return (
    <FormContainer>
      <FormProvider {...formMethods}>
        <H2>{t('createAccount')}</H2>
        <Body2>{t('accountInstructions')}</Body2>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputField fieldPath="username" translationPrefix={translationPrefix} type="text" required dark />
          <InputField fieldPath="password" translationPrefix={translationPrefix} type="password" required dark />
          <PasswordInstructions>
            <Body4>{t('passwordRequirements')}</Body4>
          </PasswordInstructions>
          <InputField fieldPath="confirmPassword" translationPrefix={translationPrefix} type="password" required dark />
          {stepFailed && <FormError message={t(errorMessage)} />}
          <ButtonContainer>
            <Button type="submit" id="submit" primary>
              {t('saveAndContinue')}
            </Button>
            <LinkButton to={Routes.SIGN_IN}>{t('cancel')}</LinkButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </FormContainer>
  );
};

export default SetCredentialsStep;
