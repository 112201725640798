import React, { FunctionComponent } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { H2, H4, SmallParagraph, baseFontStyle } from '../basic/Text';
import { blackLogo } from '../../assets';
import PaddedContent from '../housing/PaddedContent';
import { DeviceSize } from '../basic/DeviceSize';
import LinksForTenant from './LinksForTenant';
import FooterBottomRow from './BottomRow';
import { MenuItem } from '../../types/wordpress';
import { Body2, AnchorButton } from '../common';
import { useKennoTenantState } from '../../context/kennoTenantContext';

const FooterDescriptionContainer = styled.div`
  margin-top: 20px;
  max-width: 1000px;

  @media ${DeviceSize.desktopS} {
    width: 85%;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: 90px;
  @media ${DeviceSize.mobileL} {
    width: 120px;
    height: 90px;
    margin: 20px 0;
  }

  @media print {
    display: none;
  }
`;

const MainContact = styled.div`
  margin-top: 16px;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin: auto;
  }

  p:last-of-type {
    text-decoration: underline;
  }

  @media print {
    position: absolute;
    top: 0;
    left: 0;

    p {
      color: ${(props) => props.theme.colors.coal};
      font-size: 10px;
      letter-spacing: 0.5px;
    }

    p:first-of-type {
      ${baseFontStyle};
      color: ${(props) => props.theme.colors.coal};
      margin: 0;
      font-size: 24px;
      letter-spacing: 1.2px;
    }
  }
`;

const FooterContainer = styled.footer`
  position: relative;
  margin-top: 20px;
  width: 100%;
  min-height: 452px;

  @media print {
    display: none;
  }
`;

const FooterContentContainer = styled(PaddedContent)`
  border-radius: 24px;
  padding: 2em 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.mint};

  @media ${DeviceSize.desktopM} {
    margin: 0;
    border-radius: unset;
    padding: 2em;
  }
`;

const FooterElement = styled.div`
  margin: 24px 16px;

  @media ${DeviceSize.mobileL} {
    width: 100%;
    margin: 2px 0;
  }
`;

const ContactButton = styled(AnchorButton)`
  margin-top: 15px;

  @media print {
    display: none;
  }
`;

const Footer: FunctionComponent<{ menuItems?: MenuItem[] }> = ({ menuItems }) => {
  const t = useMessageGetter('footer');
  const { user } = useKennoTenantState();

  return (
    <FooterContainer>
      <FooterContentContainer>
        {user.isLoggedIn ? (
          <LinksForTenant menuItems={menuItems} />
        ) : (
          <FooterElement>
            <H2>{t('housing.defaultcontact')}</H2>
            <MainContact>
              <H4>{t('housing.customerservice.title')}</H4>
              <Body2>{t('housing.customerservice.phone')}</Body2>
              <Body2>{t('housing.customerservice.email')}</Body2>
              <ContactButton primary href="https://m2kodit.fi/yhteystiedot/">
                {t('linkbutton')}
              </ContactButton>
            </MainContact>
          </FooterElement>
        )}
        <Logo src={blackLogo} alt="M2 logo" aria-label="M2-Kodit logo" />
        {!user.isLoggedIn && (
          <FooterDescriptionContainer>
            <SmallParagraph>{t('text')}</SmallParagraph>
          </FooterDescriptionContainer>
        )}
      </FooterContentContainer>
      <FooterBottomRow />
    </FooterContainer>
  );
};

export default Footer;
