import React, { useEffect, Fragment } from 'react';
import PageContainer from './PageContainer';
import { RouteComponentProps } from 'react-router';
import { useMessageGetter } from 'react-message-context';
import { H1, H3, BasicParagraph, SemiboldParagraph, H2 } from '../components/basic/Text';
import PaddedContent from '../components/housing/PaddedContent';
import styled from 'styled-components/macro';
import { DeviceSize } from '../components/basic/DeviceSize';
import Loader from '../components/basic/Loader';
import { formatDate, formatMoney, formatPrice, replaceDotWithComma, shouldHideFinancialInfo } from '../utils';
import ReactGA from 'react-ga';
import { LOAD_STATES } from '../context/common';
import { Button } from '../components/common';
import { useKennoTenant } from '../context/kennoTenantContext';
import { loadApartment, loadContract, loadCurrentBill, loadCurrentTargets } from '../context/kennoTenantActions';
import { MaintenanceNote } from '../components/FinancialInfoMaintenanceNote';

const MainHeader = styled(H1)`
  margin-bottom: 40px;

  @media ${DeviceSize.desktopM} {
    font-size: 24px;
  }
`;

const AgreementInfo = styled.div``;

const RentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    padding: 0 20px 20px 0;
    width: calc((100% / 2) - 5px);
    margin-bottom: 20px;

    @media ${DeviceSize.desktopM} {
      width: 100%;
      margin-bottom: 0;
    }

    @media print {
      margin: 10px;
      padding: 0;
    }
  }

  h2 {
    margin-bottom: 20px;

    @media print {
      margin-bottom: 10px;
    }
  }

  h3 {
    @media print {
      padding-top: 10px;
    }
  }

  p {
    margin: 0 0 5px 0;
    padding: 0;

    @media print {
      padding: 5px 0px;
    }
  }
`;

const RentBox = styled.div`
  @media ${DeviceSize.desktopM} {
    width: 100%;
    background: none;
    padding: 0;
    margin-bottom: 30px;
    min-height: auto;
  }

  @media print {
    min-height: 0;
    padding: 10px 0;
    border-radius: 0;
    background: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.charcoal};
  }
`;

const SecondaryHeader = styled(H2)`
  font-size: 21px;
  white-space: nowrap;

  @media ${DeviceSize.desktopS} {
    white-space: initial;
    padding-bottom: 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media print {
    display: none;
  }

  @media ${DeviceSize.desktopS} {
    flex-direction: column-reverse;
  }
`;

const AgreementPage = ({ history }: RouteComponentProps) => {
  const t = useMessageGetter('agreement');
  const [{ user, apartment, contract, currentTargets, currentBill }, dispatch] = useKennoTenant();

  useEffect(() => {
    loadApartment(dispatch);
    loadContract(dispatch);
    loadCurrentBill(dispatch);
    loadCurrentTargets(dispatch);
  }, [dispatch]);

  // Note: temporary implementation, only for Kenno launch, can be removed once
  // Y-Säätiö has confirmed financials in Kenno are correct
  if (shouldHideFinancialInfo()) {
    return (
      <PageContainer history={history}>
        <PaddedContent>
          <H1>{t('title')}</H1>
          <MaintenanceNote translationKey="financial.generic" />
        </PaddedContent>
      </PageContainer>
    );
  }

  const printAgreementInfo = () => {
    ReactGA.event({
      category: 'Agreement',
      action: 'Print information',
      label: '/vuokrani/vuokrasopimus',
    });

    window.print();
  };

  const formattedRent = currentTargets.data?.monthlyRent ? formatPrice(currentTargets.data.monthlyRent) : '-';
  const dueDateDay = currentBill.dueDate?.getDate();
  const address = apartment.data?.postalAddress;

  // TODO: convert this to check if water information is available, after we have confirmed that water info can be shown
  const showWaterInfo = false;

  const loading =
    apartment.loadState === LOAD_STATES.BUSY ||
    contract.loadState === LOAD_STATES.BUSY ||
    currentBill.loadState === LOAD_STATES.BUSY ||
    currentTargets.loadState === LOAD_STATES.BUSY;

  return (
    <PageContainer history={history}>
      <PaddedContent>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <AgreementInfo>
              <MainHeader>{t('title')}</MainHeader>
              <RentContainer>
                <div>
                  <SecondaryHeader>{t('apartment.title')}</SecondaryHeader>
                  <RentBox>
                    <BasicParagraph>
                      <b>{t('apartment.address')}: </b>
                      {address?.streetAddressLine1}, {address?.postalCode} {address?.city}
                    </BasicParagraph>
                    {/* <BasicParagraph>
                      <b>{t('apartment.type')}:</b> {apartment.data?.technicalTypeLabel}
                    </BasicParagraph> */}
                    <BasicParagraph>
                      <b>{t('apartment.sqfoot')}:</b> {replaceDotWithComma(apartment.data?.area)} m2
                    </BasicParagraph>
                    {/*                     <BasicParagraph>
                      <b>{t('apartment.houseowner')}:</b> {agreement.houseowner ? agreement.houseowner.name : '-'}
                    </BasicParagraph> */}
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('tenants.title')}</SecondaryHeader>
                  <RentBox>
                    <Fragment>
                      <H3>{t('tenants.contract')}</H3>
                      <SemiboldParagraph>
                        {`${user.attributes?.firstName} ${user.attributes?.lastName}`}
                      </SemiboldParagraph>
                      <H3>{t('tenants.other')}</H3>
                      <SemiboldParagraph>{t('tenants.partiesUnavailable')}</SemiboldParagraph>
                      {/* {agreement.parties &&
                        agreement.parties.contractParties &&
                        agreement.parties.contractParties.length > 0 &&
                        agreement.parties.contractParties.map((p, index) => (
                          <SemiboldParagraph key={index}>
                            {p.name} ({p.socialNumber})
                          </SemiboldParagraph>
                        ))}
                      {agreement.parties &&
                        agreement.parties.otherParties &&
                        agreement.parties.otherParties.length > 0 && <H3>{t('tenants.other')}</H3>}
                      {agreement.parties &&
                        agreement.parties.otherParties &&
                        agreement.parties.otherParties.length > 0 &&
                        agreement.parties.otherParties.map((p, index) => (
                          <SemiboldParagraph key={index}>
                            {p.name} {p.socialNumber ? p.socialNumber : null}
                          </SemiboldParagraph>
                        ))} */}
                    </Fragment>
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('lease.title')}</SecondaryHeader>
                  <RentBox>
                    <BasicParagraph>
                      <b>{t('lease.price')}:</b> {formattedRent}
                      {t('permonth')}
                    </BasicParagraph>
                    {!showWaterInfo && (
                      <BasicParagraph>
                        <b>{t('lease.waterPerPerson')}:</b> {t('lease.waterPaymentUnavailable')}
                      </BasicParagraph>
                    )}
                    {showWaterInfo && currentTargets.data?.monthlyWaterAdvance && (
                      <BasicParagraph>
                        <b>{t('lease.waterPrePayment')}:</b>{' '}
                        {formatMoney(currentTargets.data?.monthlyWaterAdvance.toString())}
                        {t('permonth')}
                      </BasicParagraph>
                    )}
                    {/* {agreement.waterPerPerson && (
                      <BasicParagraph>
                      <b>{t('lease.waterPerPerson')}:</b> {formatMoney(agreement.waterPerPerson.toString())}
                      {t('personmonth')}
                      </BasicParagraph>
                      )}
                      {agreement.waterPerConsumption && (
                        <BasicParagraph>
                        <b>{t('lease.waterPerConsumption')}:</b> {formatMoney(agreement.waterPerConsumption.toString())}
                        {t('permonth')}
                        </BasicParagraph>
                        )}
                        {apartment.info.waterDescription && (
                          <BasicParagraph>{apartment.info.waterDescription}</BasicParagraph>
                          )} */}
                    <BasicParagraph>
                      <b>{t('lease.duedate')}:</b> {dueDateDay ? `${t('month')} ${dueDateDay}. ${t('day')}` : '-'}
                    </BasicParagraph>
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('period.title')}</SecondaryHeader>
                  <RentBox>
                    <BasicParagraph>
                      <b>{t('period.type')}:</b> {contract.endDate ? t('istemporary') : t('ongoing')}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('period.startdate')}:</b> {formatDate(contract.startDate?.toDateString())}
                    </BasicParagraph>
                    <BasicParagraph>
                      <b>{t('period.enddate')}:</b> {formatDate(contract.endDate?.toDateString(), '-')}
                    </BasicParagraph>
                  </RentBox>
                </div>
                <div>
                  <SecondaryHeader>{t('deposit.title')}</SecondaryHeader>
                  <SemiboldParagraph>{t('deposit.infoUnavailable')}</SemiboldParagraph>
                </div>
                {/*                 {agreement.deposit && agreement.deposit.length > 0 && (
                  <div>
                    <SecondaryHeader>{t('deposit.title')}</SecondaryHeader>
                    <RentBox>
                      {agreement.deposit.map((d, index) => (
                        <Fragment key={index}>
                          {depositCategories.map(
                            (category, i) =>
                              category.toString() === d.depositId && (
                                <BasicParagraph key={i}>
                                  <b>{t(`deposit.paymenttype.${d.depositId}`)}:</b> {formatMoney(d.depositSum)}{' '}
                                  {d.refundDate !== '' &&
                                    `(${t('deposit.refunded')}: ${formatDate(d.refundDate)}, ${t(
                                      'deposit.refundedsum'
                                    )}: ${formatMoney(Math.abs(d.refundSum).toString())})`}
                                </BasicParagraph>
                              )
                          )}
                          <BasicParagraph>{t('deposit.info')}</BasicParagraph>
                        </Fragment>
                      ))}
                    </RentBox>
                  </div>
                )} */}
              </RentContainer>
            </AgreementInfo>
            <Buttons>
              <Button iconRight icon="print" onClick={printAgreementInfo} primary>
                {t('print')}
              </Button>
            </Buttons>
          </Fragment>
        )}
      </PaddedContent>
    </PageContainer>
  );
};

export default AgreementPage;
