import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components/macro';
import { DeviceSize } from '../basic/DeviceSize';
import { H4 } from '../basic/Text';
import { removeLanguagePostfix } from '../../pages/pageUtils';
import LinkWithChevron from '../basic/LinkWithChevron';
import IconLink from '../basic/IconLink';
import { facebookIcon, instagramIcon } from '../../assets';
import { useMessageGetter } from 'react-message-context';
import { MenuItem } from '../../types/wordpress';
import { flattenMenuItems } from '../../utils/wordpress';
import { footerLinkSlugs, Slugs } from '../../constants/knownSlugs';

const LinksForTenant: FunctionComponent<{ menuItems?: MenuItem[] }> = ({ menuItems }) => {
  const t = useMessageGetter('footer');
  const tenantMenuItems = !!menuItems?.length && flattenMenuItems(menuItems);

  const getMenuItemBySlug = (slug: string) =>
    tenantMenuItems && tenantMenuItems.find((item) => removeLanguagePostfix(item.slug) === slug);

  return (
    <FooterColumns>
      {Object.entries(footerLinkSlugs).map(([topLevelSlug, childSlugs]) => {
        const topLevelMenuItem = getMenuItemBySlug(topLevelSlug);
        return (
          topLevelMenuItem && (
            <Column key={topLevelSlug}>
              <H4>{topLevelMenuItem.title}</H4>
              <LinkList>
                {childSlugs.map((slug) => {
                  const menuItem = getMenuItemBySlug(slug);
                  return (
                    menuItem && (
                      <li key={slug}>
                        <LinkWithChevron title={menuItem.title} path={menuItem.path} />
                      </li>
                    )
                  );
                })}
                {topLevelSlug === Slugs.CONTACT && (
                  <>
                    <IconLink
                      icon={facebookIcon}
                      label={t('externalLinks.facebook')}
                      href="https://fi-fi.facebook.com/M2vuokrakodit/about/"
                    ></IconLink>
                    <IconLink
                      icon={instagramIcon}
                      label={t('externalLinks.instagram')}
                      href="https://www.instagram.com/m2kodit/"
                    ></IconLink>
                  </>
                )}
              </LinkList>
            </Column>
          )
        );
      })}
    </FooterColumns>
  );
};

const FooterColumns = styled.div`
  display: flex;
  text-align: left;
  flex-direction: row;
  width: 100%;

  @media ${DeviceSize.mobileL} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 0 10px;
  }

  @media print {
    display: none;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  @media ${DeviceSize.mobileL} {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

export const smallLinkStyle = css`
  span {
    font-size: 14px;
    line-height: 21px;
    border-bottom: 1.5px solid transparent;
    padding: 0;

    @media ${DeviceSize.mobileL} {
      font-size: 12px;
      line-height: 18px;
    }
  }

  li:hover span {
    border-bottom: 1.5px solid black;
  }
`;

const LinkList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  gap: 12px;
  ${smallLinkStyle}

  @media ${DeviceSize.mobileL} {
    gap: 18px;
  }

  @media ${DeviceSize.desktopM} {
    max-height: initial;
  }

  @media ${DeviceSize.mobileL} {
    > li {
      margin-bottom: 4px;
    }
  }
`;

export default LinksForTenant;
