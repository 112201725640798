import React, { useEffect } from 'react';
import { Dropdown, DropdownProps } from '../common/Dropdown';
import { useMessageGetter } from 'react-message-context';
import { contactCategories } from './CategoryDropdown';

export const SubcategoryDropdown = ({
  category,
  onChange,
  value,
  id,
  width,
  hasError,
}: Omit<DropdownProps, 'options'> & { category: string }) => {
  const t = useMessageGetter('messages.new');

  useEffect(() => {
    onChange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const options =
    contactCategories
      .find((option) => option.value === category)
      ?.subcategories?.map((subcategory) => ({
        label: t(subcategory.value),
        value: subcategory.value,
      })) ?? [];

  return <Dropdown onChange={onChange} value={value} id={id} options={options} width={width} hasError={hasError} />;
};
