import dayjs from 'dayjs';
import { z } from 'zod';

const activitySchema = z.object({
  name: z.string().min(1),
  month: z.number().int().min(1).max(12),
  day: z.number().min(1),
  time: z.string().min(1).optional(),
  additionalInformation: z.string().min(1),
  expenses: z.number(),
});

export const schema = z
  .object({
    year: z.coerce.number().min(1000).max(9999),
    activities: z.array(activitySchema),
    publicationDuration: z.number().refine((val) => val === 12 || val === 24),
  })
  .superRefine((data, ctx) => {
    for (let i = 0; i < data.activities.length; ++i) {
      const { year } = data;
      const { month, day } = data.activities[i];

      const isoString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const isValidDay = dayjs(isoString, 'YYYY-MM-DD', true).isValid();

      if (day && !isValidDay) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['activities', i, 'day'],
        });
      }
    }
    return true;
  });

export type ActionReportFormState = z.infer<typeof schema>;
