import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { CalendarIcon, LocationIcon, UserFilledIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { useFormattedClockTime } from '../../../hooks';
import { formatISODate } from '../../../utils';
import { Bold, H2, H3, H5, TextBlock, Underline } from '../Text';
import { PreviewContentBlock, Row } from '../layoutComponents';
import { MeetingMinutesFormState } from './schema';

const PreviewContent = styled(PreviewContentBlock)``;
const MeetingDetails = styled(PreviewContentBlock)`
  gap: 16px;
`;
const MeetingItems = styled(PreviewContentBlock)``;
const MeetingItem = styled(PreviewContentBlock)`
  gap: 20px;
`;
const Signatures = styled(PreviewContentBlock)``;
const Signee = styled(PreviewContentBlock)`
  font-size: 16px;
  gap: 8px;
`;

type Props = { closePreview: () => void };

export const MeetingMinutesPreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<MeetingMinutesFormState>();
  const tForms = useMessageGetter('forms');
  const t = useMessageGetter('forms.meetingMinutes');
  const { formatClockTime } = useFormattedClockTime();

  const formData = watch();

  const formattedDate = formData.date ? formatISODate(formData.date) : '';

  const formattedTime = formatClockTime(formData.time);

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {tForms('closePreview')}
        </Button>
      </Row>
      <H2>{formData.name}</H2>
      <MeetingDetails>
        <Row>
          <CalendarIcon />
          <Bold>{`${formattedDate}${formattedDate && formattedTime && ', '}${formattedTime}`}</Bold>
        </Row>
        <Row>
          <LocationIcon />
          <Underline>{formData.location}</Underline>
        </Row>
        <Row>
          <UserFilledIcon />
          {formData.participants}
        </Row>
      </MeetingDetails>
      <MeetingItems>
        {formData.items.map((item) => (
          <MeetingItem id={item.title}>
            <H5>{item.title}</H5>
            <TextBlock>{item.text}</TextBlock>
          </MeetingItem>
        ))}
      </MeetingItems>
      <Signatures>
        <H3>{t('signatures.title')}</H3>
        <Signee>
          <span>{t('chairperson')}:</span>
          <span>{formData.chairperson}</span>
        </Signee>
        <Signee>
          <span>{t('secretary')}:</span>
          <span>{formData.secretary}</span>
        </Signee>
      </Signatures>
    </PreviewContent>
  );
};
