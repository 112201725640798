import { Routes } from './constants/routes';

const paymentSuccessPath = Routes.Tenancy.PAYMENT_SUCCESS;
const paymentErrorPath = Routes.Tenancy.PAYMENT_ERROR;

const PaytrailConfig = {
  local: {
    paytrail: {
      // Return to dev environment to prevent browser blocking an HTTPS-to-HTTP redirect
      urlSuccess: `https://d1xwoloz6deys4.cloudfront.net${paymentSuccessPath}`,
      urlCancel: `https://d1xwoloz6deys4.cloudfront.net${paymentErrorPath}`,
    },
  },
  dev: {
    paytrail: {
      urlSuccess: `https://d1xwoloz6deys4.cloudfront.net${paymentSuccessPath}`,
      urlCancel: `https://d1xwoloz6deys4.cloudfront.net${paymentErrorPath}`,
    },
  },
  staging: {
    paytrail: {
      urlSuccess: `https://dfe2tdelzx6hs.cloudfront.net${paymentSuccessPath}`,
      urlCancel: `https://dfe2tdelzx6hs.cloudfront.net${paymentErrorPath}`,
    },
  },
  production: {
    paytrail: {
      urlSuccess: `https://omam2.m2kodit.fi${paymentSuccessPath}`,
      urlCancel: `https://omam2.m2kodit.fi${paymentErrorPath}`,
    },
  },
};

export default PaytrailConfig;
