import React from 'react';
import { infoFilledIcon } from '../../assets';
import styled from 'styled-components/macro';
import { useMessageGetter } from 'react-message-context';
import { DeviceSize } from '../basic/DeviceSize';
import { scrollToElement } from '../../utils';
import { Body2, TextButton } from '../common';

const Wrapper = styled.div<{ showOnMobileOnly: boolean }>`
  display: ${({ showOnMobileOnly }) => (showOnMobileOnly ? 'none' : 'flex')};

  @media ${DeviceSize.mobileL} {
    display: ${({ showOnMobileOnly }) => (showOnMobileOnly ? 'flex' : 'none')};
  }
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;
  margin: 20px 0;

  img {
    margin-top: 16px;
  }
`;

const FormInstructions: React.FC<{ subcategory: string; showOnMobileOnly: boolean }> = ({
  subcategory,
  showOnMobileOnly,
}) => {
  const t = useMessageGetter('messages.new');

  return (
    <Wrapper showOnMobileOnly={showOnMobileOnly}>
      {subcategory === 'billing.latepayment' && (
        <InstructionContainer>
          <img src={infoFilledIcon} alt="" />
          <Body2>{t('instruction.latepayment')}</Body2>
        </InstructionContainer>
      )}

      {subcategory === 'customerservice.propertymanagement' && (
        <InstructionContainer>
          <img src={infoFilledIcon} alt="" />
          <Body2>{t('instruction.propertymanagement')}</Body2>
        </InstructionContainer>
      )}

      {subcategory === 'customerservice.faultreports' && (
        <InstructionContainer>
          <img src={infoFilledIcon} alt="" />
          <Body2>
            {t('instruction.faultreports')}
            <TextButton
              type="button"
              onClick={() => scrollToElement('maintenanceContactInfo')}
              aria-label={t('instruction.scrolltomaintenance')}
            >
              {t('instruction.toMaintenanceCompany')}.
            </TextButton>
            <br />
            <br />
            {t('instruction.faultreportsother')}
            <TextButton
              type="button"
              onClick={() => scrollToElement('notUrgentMaintenanceRequest')}
              aria-label={t('instruction.scrolltofaultreport')}
            >
              {t('instruction.linktofaultreport')}.
            </TextButton>
          </Body2>
        </InstructionContainer>
      )}
      {subcategory === 'customerservice.sauna_laundry_parking' && (
        <InstructionContainer>
          <img src={infoFilledIcon} alt="" />
          <Body2>
            {t('instruction.sauna_laundry_parking')}
            <TextButton
              type="button"
              onClick={() => scrollToElement('maintenanceContactInfo')}
              aria-label={t('instruction.scrolltomaintenance')}
            >
              {t('instruction.toMaintenanceCompany')}.
            </TextButton>
          </Body2>
        </InstructionContainer>
      )}
    </Wrapper>
  );
};

export default FormInstructions;
