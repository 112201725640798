import React, { useEffect } from 'react';
import { theme } from '../../theme';
import { useMessageGetter } from 'react-message-context';
import Card from '../../components/common/Card';
import styled from 'styled-components/macro';
import { NotificationType, useApp, useAppDispatch } from '../../context/appContext';
import { getMenuItemPathBySlug } from '../../utils/wordpress';
import { Slugs } from '../../constants/knownSlugs';
import { FlexColumn, FlexRow } from '../../components/basic/Flex';
import { LinkButton } from '../../components/common';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loadCurrentBill } from '../../context/kennoTenantActions';
import { LOAD_STATES } from '../../context/common';
import { showNotification } from '../../context/appActions';
import Loader from '../../components/basic/Loader';
import { formatPrice, shouldHideFinancialInfo } from '../../utils';
import { MaintenanceNote } from '../../components/FinancialInfoMaintenanceNote';

const Cell = styled(FlexColumn)`
  width: 50%;
  height: min-content;
`;
const CellTitle = styled.p`
  font-size: 16px;
  margin-bottom: 12px;
  font-family: ${(props) => props.theme.text.primaryFont};
`;
const CellValue = styled.p`
  font-size: 36px;
  margin: 0;
`;
const SmallCellValue = styled(CellValue)`
  font-size: 21px;
`;
const Footer = styled.p`
  margin-top: 1rem;
  font-size: 14px;
  font-family: ${(props) => props.theme.text.primaryFont};
`;

const RentCard = () => {
  const t = useMessageGetter('home.grid.rent');
  const [{ currentBill }, dispatch] = useKennoTenant();
  const [{ menu }] = useApp();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    loadCurrentBill(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (currentBill.loadState === LOAD_STATES.ERROR) {
      showNotification(appDispatch, t('loadfailure'), NotificationType.ERROR);
    }
  }, [currentBill.loadState]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardContent = () => {
    if (shouldHideFinancialInfo()) {
      return <MaintenanceNote translationKey="financial.rentCard" />;
    }
    if (currentBill.loadState !== LOAD_STATES.READY) {
      return <Loader />;
    }

    const { totalRent, debt, difference, dueDate } = currentBill;
    const formattedRent = formatPrice(totalRent);
    const formattedDebt = formatPrice(debt);
    const formattedFullBill = difference !== undefined ? formatPrice(-difference) : formatPrice();
    const formattedDay = dueDate ? dueDate.getDate() : '-';

    const debtIsNegative = debt && debt < 0;
    const fullBillIsNegative = difference && difference > 0; // Difference is negative if there's something to pay

    return (
      <FlexRow wrap="wrap">
        <Cell>
          <CellTitle>{t('monthly_rent')}</CellTitle>
          <CellValue>{formattedRent}</CellValue>
        </Cell>
        <Cell>
          <CellTitle>{debtIsNegative ? t('debt_negative') : t('debt_positive')}</CellTitle>
          <CellValue>{formattedDebt}</CellValue>
        </Cell>
        <Cell>
          <CellTitle>{fullBillIsNegative ? t('total_paid') : t('total_to_pay')}</CellTitle>
          <SmallCellValue>{formattedFullBill}</SmallCellValue>
        </Cell>
        <Cell>
          <CellTitle>{t('due_date_label')}</CellTitle>
          <SmallCellValue>{t('due_date', { day: formattedDay })}</SmallCellValue>
        </Cell>
        <Footer>{t('payment_delay_description')}</Footer>
      </FlexRow>
    );
  };

  return (
    <Card
      title={t('title')}
      background={theme().colors.strawberry}
      mainButton={
        <LinkButton
          size="small"
          background="transparent"
          to={getMenuItemPathBySlug(menu.items, Slugs.PAYMENT)}
          iconRight
          icon="arrowForward"
        >
          {t('action.label')}
        </LinkButton>
      }
    >
      {cardContent()}
    </Card>
  );
};

export default RentCard;
