import React, { useRef } from 'react';
import { useMessageGetter } from 'react-message-context';
import { Button } from '..';

export type FileInputValue = FileList | null;

type InputProps = {
  label?: string;
  onChange: (files: FileInputValue) => void;
};

export const FileInput: React.FunctionComponent<InputProps> = (props) => {
  const t = useMessageGetter('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOnClick = () => {
    fileInputRef.current?.click();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.files);
  };

  return (
    <Button icon="plus" type="button" onClick={handleOnClick}>
      <input
        ref={(e) => {
          fileInputRef.current = e;
        }}
        onChange={handleOnChange}
        type="file"
        style={{ display: 'none' }}
      />
      {props.label ?? t('input.addAttachment')}
    </Button>
  );
};
