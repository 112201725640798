import React from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { XCloseIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { Row } from '../layoutComponents';
import { H3 } from '../Text';

const InfoPopupContainer = styled.div<{ width?: string; top?: string; right?: string; bottom?: string; left?: string }>`
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.colors.apila};
  border-radius: 24px;
  width: ${({ width }) => width};
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
`;

const CloseIconButton = styled(XCloseIcon)`
  cursor: pointer;
`;

export const InfoPopup = ({
  subject,
  description,
  width,
  top,
  right,
  bottom,
  left,
  onClose,
}: {
  subject: string;
  description: string;
  visible: boolean;
  width?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  onClose: () => void;
}) => {
  const t = useMessageGetter('modal');
  return (
    <InfoPopupContainer width={width} top={top} right={right} bottom={bottom} left={left}>
      <Row style={{ justifyContent: 'space-between' }}>
        <H3>{subject}</H3>
        <CloseIconButton onClick={onClose} />
      </Row>
      {description}
      <Row>
        <Button onClick={onClose}>{t('close')}</Button>
      </Row>
    </InfoPopupContainer>
  );
};
