import React from 'react';
import { Dropdown, DropdownProps } from '../Dropdown';
import { useMessageGetter } from 'react-message-context';

export const MonthDropdown = ({ onChange, value, id, width, hasError }: Omit<DropdownProps, 'options'>) => {
  const t = useMessageGetter('months');

  const options = [
    { label: t('january'), value: 1 },
    { label: t('february'), value: 2 },
    { label: t('march'), value: 3 },
    { label: t('april'), value: 4 },
    { label: t('may'), value: 5 },
    { label: t('june'), value: 6 },
    { label: t('july'), value: 7 },
    { label: t('august'), value: 8 },
    { label: t('september'), value: 9 },
    { label: t('october'), value: 10 },
    { label: t('november'), value: 11 },
    { label: t('december'), value: 12 },
  ];

  return <Dropdown onChange={onChange} value={value} id={id} options={options} width={width} hasError={hasError} />;
};
