import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components/macro';
import { FileIcon } from '../../../assets';
import { Button } from '../../../components/common';
import { FormPreviewActions } from '../../../components/common/forms/FormPreviewActions';
import { formatISODate } from '../../../utils';
import { Column, GridContainer, PreviewContentBlock, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { ExpenseReportFormState } from './schema';

const PreviewContent = styled(PreviewContentBlock)`
  gap: 40px;
`;

const PurchaseReportsContainer = styled(PreviewContentBlock)``;

const PurchaseReportContainer = styled(GridContainer)`
  padding-bottom: 20px;
  gap: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`;

const FileLink = styled.a`
  display: inline-flex;
  text-decoration: underline;
  color: black;
`;

const TotalAmount = styled.span`
  font-size: 24px;
  line-height: 36px;
  font-family: ${(props) => props.theme.text.secondaryFont};
`;

type Props = { closePreview: () => void };

export const ExpenseReportPreview = ({ closePreview }: Props): React.ReactElement => {
  const { watch } = useFormContext<ExpenseReportFormState>();
  const tForms = useMessageGetter('forms');
  const t = useMessageGetter('forms.expenseReport');

  const formData = watch();

  const formattedDate = formData.event.date ? formatISODate(formData.event.date, true) : '';

  const totalExpensesAmount = formData.purchaseReports.reduce((sum, obj) => sum + obj.amount, 0).toFixed(2);

  return (
    <PreviewContent>
      <Row>
        <Button icon="xClose" iconRight onClick={closePreview}>
          {tForms('closePreview')}
        </Button>
      </Row>
      <H2>{t('title')}</H2>
      <H3>{t('contactInfo.title')}</H3>
      <GridContainer rowGap="24px">
        <Column>
          <span>{t('contactInfo.name')}</span>
          <span>{formData.contactInfo.name}</span>
        </Column>
        <Column>
          <span>{t('contactInfo.ssn')}</span>
          <span>{formData.contactInfo.ssn}</span>
        </Column>
        <Column span={2}>
          <span>{t('contactInfo.address')}</span>
          <span>{formData.contactInfo.address}</span>
          <span>
            {formData.contactInfo.zip} {formData.contactInfo.city}
          </span>
        </Column>
        <Column>
          <span>{t('contactInfo.phone')}</span>
          <span>{formData.contactInfo.phone}</span>
        </Column>
        <Column>
          <span>{t('contactInfo.email')}</span>
          <span>{formData.contactInfo.email}</span>
        </Column>
        <Column span={2}>
          <span>{t('contactInfo.iban')}</span>
          <span>{formData.contactInfo.iban}</span>
        </Column>
      </GridContainer>
      <H3>{t('event.title')}</H3>
      <GridContainer rowGap="24px">
        <Column span={2}>
          <span>{t('event.name')}</span>
          <span>{formData.event.name}</span>
        </Column>
        <Column span={2}>
          <span>{t('event.additionalInformation')}</span>
          <TextBlock>{formData.event.additionalInformation}</TextBlock>
        </Column>
        <Column>
          <span>{t('event.date')}</span>
          <span>{formattedDate}</span>
        </Column>
      </GridContainer>
      <H3>{t('purchaseReports.title')}</H3>
      <PurchaseReportsContainer>
        {formData.purchaseReports.map((purchaseReport, index) => {
          return (
            <PurchaseReportContainer key={index} columns={5}>
              <Column span={4}>
                <span>
                  {t('purchaseReports.report')} {index + 1}
                </span>
                <span>{purchaseReport.report}</span>
              </Column>
              <Column>
                <span>{t('purchaseReports.amount')}</span>
                <span>{purchaseReport.amount.toFixed(2)}</span>
              </Column>
              {purchaseReport.receipt && (
                <Column span={5}>
                  <FileLink href={URL.createObjectURL(purchaseReport?.receipt[0])} download>
                    <Row>
                      <FileIcon style={{ flexShrink: 0 }} />
                      <TextBlock>{purchaseReport.receipt[0].name}</TextBlock>
                    </Row>
                  </FileLink>
                </Column>
              )}
            </PurchaseReportContainer>
          );
        })}
      </PurchaseReportsContainer>
      <TotalAmount>{t('purchaseReports.total', { amount: totalExpensesAmount })}</TotalAmount>

      <FormPreviewActions handleEdit={closePreview} handleSubmit={() => console.log('wip')} actionButtonType="send" />
    </PreviewContent>
  );
};
