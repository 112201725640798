import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Footer from '../components/footer/Footer';
import { History } from 'history';
import Header from '../components/header/Header';
//import { illustration1, illustration2, illustration3, illustration4 } from '../assets/index';
//import { useMessageGetter } from 'react-message-context';
//import Slider from '../components/slider/Slider';
import { DeviceSize } from '../components/basic/DeviceSize';
//import { hasExpiringAgreement } from '../utils';
import Breadcrumbs from '../components/Breadcrumbs';
import { useLocation } from 'react-router';
import { useApp } from '../context/appContext';
import { MenuItem } from '../types/wordpress';
//import { Slugs } from '../constants/knownSlugs';
import { theme } from '../theme';
import Notifications from '../components/Notifications';
import Bulletins from '../components/bulletin/Bulletins';

const Container = styled.div`
  position: relative;
  background: ${theme().colors.lightYellow};

  @media print {
    background: none;
  }
`;

const Content = styled.div`
  min-height: 50vh;
  width: 100%;
  position: relative;

  @media ${DeviceSize.desktopS} {
    margin-top: 20px;
  }

  @media print {
    margin-top: 0px;
  }
`;

const PageContainer: FunctionComponent<{ history: History }> = ({ history, children }) => {
  const [{ menu }] = useApp();
  //const [showOnboard, setShowOnboard] = useState(false);
  //const t = useMessageGetter('onboarding');
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  // const hideOnExpiredAgreement = [Slugs.BOOKINGS];
  // const hideOnExpiredAgreementSubMenu = [
  //   // My Building
  //   Slugs.BUILDING_INFO,
  //   Slugs.GUIDES_DOCUMENTS,
  //   Slugs.NOTICES,
  //   Slugs.EVENTS,
  //   // Living
  //   Slugs.WORK,
  //   Slugs.INFLUENCERS,
  //   Slugs.EVENTS_FOR_ALL,
  //   Slugs.APARTMENT_CHANGE,
  //   Slugs.COMMITTEE,
  //   Slugs.CHAIRMAN,
  // ];

  const shouldShowSubMenuItem = (slug: string) => {
    // const isHiddenDueToMissingWatermeter =
    //   slug === Slugs.WATER_METER && apartment.info.waterMeter !== 'Huoneistokohtainen vesimittari';

    // const isHiddenDueToExpiredAgreement =
    //   hideOnExpiredAgreementSubMenu.some((item) => item === slug) && hasExpiringAgreement(user);

    // return !(isHiddenDueToMissingWatermeter || isHiddenDueToExpiredAgreement);
    return true; // This is temporary, until we get water meter and expiring agreement info from Kenno
  };

  const shouldShowMenuItem = (slug: string) => {
    // const isHiddenDueToMissingCalendar = slug === Slugs.BOOKINGS && !modules.modules?.length;

    // const isHiddenDueToExpiredAgreement =
    //   hideOnExpiredAgreement.some((item) => item === slug) && hasExpiringAgreement(user);

    // return !(isHiddenDueToMissingCalendar || isHiddenDueToExpiredAgreement);
    return true; // This is temporary, until we get bookings and expiring agreement info from Kenno
  };

  const filteredMenuItems: MenuItem[] = [];
  // Apply filtering to the menu items and their children
  menu.items.forEach((menuItem) => {
    if (shouldShowMenuItem(menuItem.slug)) {
      // Apply child item filtering
      const filteredChildren = menuItem.children.filter((child) => shouldShowSubMenuItem(child.slug));
      filteredMenuItems.push({
        ...menuItem,
        children: filteredChildren,
      });
    }
  });

  // const carouselContent = [
  //   {
  //     id: 0,
  //     title: `${t('welcome')} ${user.attributes.given_name}!`,
  //     text: `${t('info1')}`,
  //     index: 1,
  //     image: `${illustration1}`,
  //   },
  //   { id: 1, title: `${t('uptodate')}`, text: `${t('info2')}`, index: 2, image: `${illustration2}` },
  //   { id: 2, title: `${t('rental')}`, text: `${t('info3')}`, index: 3, image: `${illustration3}` },
  //   { id: 3, title: `${t('developing')}`, text: `${t('info4')}`, index: 4, image: `${illustration4}` },
  // ];

  // useEffect(() => {
  //   const visited = user.attributes.visited;
  //   if (visited === Visited.NO) {
  //     setShowOnboard(true);
  //   }
  // }, [dispatch, user.attributes.visited]);

  // const closeElement = () => {
  //   setShowOnboard(false);
  //   updateVisitedStatus(dispatch, Visited.YES);
  // };

  return (
    <Container className="pageContent">
      <Bulletins />
      <Header history={history} menuItems={filteredMenuItems} />
      {!isHome && <Breadcrumbs />}
      {/* TODO: return this when we have the visited status from Kenno
      {showOnboard && <Slider content={carouselContent} hideCarousel={closeElement} />} */}
      <div className="content">
        <Content>{children}</Content>
        <Footer menuItems={filteredMenuItems} />
        <Notifications />
      </div>
    </Container>
  );
};

export default PageContainer;
