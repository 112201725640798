import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMessageGetter } from 'react-message-context';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { FileIcon } from '../../../assets';
import { DropdownOptionValue } from '../../../components/common/Dropdown';
import {
  FileInput,
  FileInputValue,
  FormActions,
  InputField,
  PublicationDurationDropdown,
  RepeatDropdown,
  RepeatOption,
  TextAreaElement,
  TimeDropdown,
} from '../../../components/common/forms';
import { scrollToTop } from '../../../utils';
import { FormPageContainer } from '../FormPageContainer';
import { Column, ContentBlock, Form, FormRow, GridContainer, RequiredIndicator, Row } from '../layoutComponents';
import { H2, H3, TextBlock } from '../Text';
import { CoverImageDropdown, CoverImageType } from './CoverImageDropdown';
import { EventInvitePreview } from './EventInvitePreview';
import { EventType, EventTypeDropdown } from './EventTypeDropdown';
import { EventInviteFormState, schema } from './schema';

const EventInviteContent = styled(ContentBlock)`
  gap: 0px;
`;
const PublicationContent = styled(ContentBlock)``;

export const EventInvite = ({ history }: RouteComponentProps) => {
  const formMethods = useForm<EventInviteFormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      eventType: EventType.CommunityWorkDay,
      coverImage: CoverImageType.NoImage,
      publication: { publicationDuration: 12, publicationRepeat: RepeatOption.Never },
    },
  });

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const t = useMessageGetter('forms.eventInvite');

  const handleSubmit = async (e: any) => {
    console.log('WIP: onSubmit', e);
  };

  const handleEmptyForm = () => {
    formMethods.reset();
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
    scrollToTop();
  };

  const handleSave = () => {
    console.log('WIP: save');
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const translationPrefix = 'forms.eventInvite';

  const eventType = formMethods.watch('eventType');
  const attachment = formMethods.watch('attachment');
  const publicationRepeat = formMethods.watch('publication.publicationRepeat');

  const attachmentFilename = attachment?.[0]?.name;

  return (
    <FormPageContainer<EventInviteFormState> history={history} formMethods={formMethods}>
      {previewOpen ? (
        <EventInvitePreview closePreview={handleClosePreview} />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <H2>{t('title')}</H2>
          <span>
            {t('description.part1')} (<RequiredIndicator>*</RequiredIndicator>) {t('description.part2')}
          </span>
          <EventInviteContent>
            <InputField<EventInviteFormState, DropdownOptionValue>
              fieldPath="eventType"
              translationPrefix={translationPrefix}
              required
              inputElementRenderer={(props) => <EventTypeDropdown {...props} />}
            />

            <InputField<EventInviteFormState, string>
              fieldPath="name"
              overrideLabel={t(eventType === EventType.CommunityMeeting ? 'subject' : 'name')}
              placeholder={t(eventType === EventType.CommunityMeeting ? 'subject' : 'name')}
              translationPrefix={translationPrefix}
              type="text"
              required
            />

            <GridContainer columnGap="20px" columns={2} mdColumns={1}>
              <InputField<EventInviteFormState, string>
                fieldPath="date"
                translationPrefix={translationPrefix}
                type="date"
                required
              />
              <InputField<EventInviteFormState, DropdownOptionValue>
                translationPrefix={translationPrefix}
                fieldPath={`time`}
                inputElementRenderer={(props) => <TimeDropdown {...props} />}
              />
            </GridContainer>
            {eventType === EventType.CommunityMeeting && (
              <InputField<EventInviteFormState, string>
                fieldPath="location"
                placeholder={t('locationPlaceholder')}
                translationPrefix={translationPrefix}
                type="text"
                required
              />
            )}

            <InputField<EventInviteFormState, string>
              translationPrefix={translationPrefix}
              fieldPath="description"
              overrideLabel={t(eventType === EventType.CommunityMeeting ? 'agenda' : 'eventDescription')}
              required
              inputElementRenderer={(props) => (
                <TextAreaElement
                  placeholder={eventType === EventType.CommunityMeeting ? '' : t('eventDescriptionPlaceholder')}
                  {...props}
                />
              )}
            />
            {eventType === EventType.CommunityMeeting && (
              <>
                <span style={{ marginBottom: '10px' }}>{t('agendaNote')}</span>
                <InputField<EventInviteFormState, string>
                  translationPrefix={translationPrefix}
                  fieldPath="regards"
                  inputElementRenderer={(props) => <TextAreaElement placeholder={t('regardsPlaceholder')} {...props} />}
                />
              </>
            )}
            {eventType !== EventType.CommunityMeeting && (
              <InputField<EventInviteFormState, string>
                translationPrefix={translationPrefix}
                fieldPath="contactPerson"
                placeholder={t('contactPersonPlaceholder')}
                type="text"
              />
            )}
            <InputField<EventInviteFormState, string>
              fieldPath="property"
              placeholder={t('propertyPlaceholder')}
              translationPrefix={translationPrefix}
              type="text"
              required
            />
            <InputField<EventInviteFormState, FileInputValue>
              translationPrefix={translationPrefix}
              hideLabel
              fieldPath="attachment"
              inputElementRenderer={(props) => (
                <FormRow>
                  <FileInput onChange={props.onChange} label={t('attachment')} />
                  <Column gap="4px">
                    {attachmentFilename && (
                      <Row style={{ marginTop: '10px' }}>
                        <FileIcon style={{ flexShrink: 0 }} />
                        <TextBlock>{attachmentFilename}</TextBlock>
                      </Row>
                    )}
                  </Column>
                </FormRow>
              )}
            />
            <GridContainer columns={3}>
              <Column>
                <InputField<EventInviteFormState, DropdownOptionValue>
                  translationPrefix={translationPrefix}
                  fieldPath="coverImage"
                  inputElementRenderer={(props) => <CoverImageDropdown {...props} />}
                />
              </Column>
            </GridContainer>
          </EventInviteContent>

          <PublicationContent>
            <H3>{t('publication.title')}</H3>
            <div>
              <FormRow>
                <InputField<EventInviteFormState, DropdownOptionValue>
                  translationPrefix={translationPrefix}
                  fieldPath="publication.publicationRepeat"
                  width="230px"
                  inputElementRenderer={(props) => <RepeatDropdown {...props} />}
                />
                {publicationRepeat && publicationRepeat !== RepeatOption.Never && (
                  <InputField<EventInviteFormState, string>
                    fieldPath="publication.repeatEndDate"
                    translationPrefix={translationPrefix}
                    type="date"
                    width="230px"
                    required
                  />
                )}
              </FormRow>
              <InputField<EventInviteFormState, DropdownOptionValue>
                fieldPath="publication.publicationDuration"
                translationPrefix={translationPrefix}
                inputElementRenderer={(props) => <PublicationDurationDropdown {...props} />}
              />
            </div>
          </PublicationContent>
          <FormActions
            handleEmptyForm={handleEmptyForm}
            handleOpenPreview={handleOpenPreview}
            handleSave={handleSave}
          />
        </Form>
      )}
    </FormPageContainer>
  );
};
