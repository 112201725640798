import React, { useState, useEffect, FunctionComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { useMessageGetter } from 'react-message-context';
import { theme } from '../../theme';
import ReactGA from 'react-ga';
import PaddedContent from '../../components/housing/PaddedContent';
import { BasicParagraph, H2 } from '../../components/basic/Text';
import { Input } from '../../components/input/Input';
import { DeviceSize } from '../../components/basic/DeviceSize';
import ButtonLegacy from '../../components/button/ButtonLegacy';
import { useKennoTenant } from '../../context/kennoTenantContext';

const Newsletter: FunctionComponent = () => {
  const t = useMessageGetter('profile');
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [{ user }] = useKennoTenant();

  const email = user.attributes?.email || '';
  const givenName = user.attributes?.firstName || '';
  const lastName = user.attributes?.lastName || '';

  useEffect(() => {
    setNewsletterEmail(email);
  }, [email]);

  const submitEmail = () => {
    ReactGA.event({
      category: 'Newsletter',
      action: 'Subscribe to newsletter',
      label: 'M2-Kodit newsletter',
    });
  };

  return (
    <PaddedContent paddingTop="16px" mobilePaddingTop="16px">
      <NewsletterContainer action="https://asukastoiminta.ysaatio.fi/" method="POST" target="_blank">
        <input name="etunimi" type="hidden" value={givenName} />
        <input name="sukunimi" type="hidden" value={lastName} />
        <NewsContent>
          <NewsletterTitle>
            <H2>{t('newsletter')}</H2>
            <BasicParagraph>{t('newsletterSubtitle')}</BasicParagraph>
          </NewsletterTitle>
          <Inputs>
            <Input
              name="email"
              placeholder={t('emailplaceholder')}
              value={newsletterEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewsletterEmail(event.target.value)}
              maxWidth="260px"
              labelHidden={true}
            />
            <ThemeProvider theme={theme('lemon')}>
              <SubmitButton
                id="orderBtn"
                style={{ minWidth: '0', margin: '0 auto' }}
                type="submit"
                role="button"
                aria-pressed="false"
                onClick={submitEmail}
              >
                {t('newsletterBtn')}
              </SubmitButton>
            </ThemeProvider>
          </Inputs>
        </NewsContent>
      </NewsletterContainer>
    </PaddedContent>
  );
};

const NewsletterContainer = styled.form`
  border-radius: 24px;
  padding: 20px 30px;
  background-color: ${(props) => props.theme.colors.apila};
  margin-left: auto;
  margin-right: auto;
`;

const NewsContent = styled.div`
  text-align: center;
`;

const NewsletterTitle = styled.div`
  margin: auto 15px auto 0;
  padding: 10px 0 20px 0;
  flex: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${DeviceSize.desktopS} {
    text-align: left;
    margin: auto auto auto 0;
  }
`;

const Inputs = styled.div`
  > div {
    max-width: 400px;
    margin: 0 auto;
  }

  > button {
    margin-left: 5px;
  }

  @media ${DeviceSize.desktopS} {
    width: 100%;

    > div {
      max-width: 100%;
      margin: 0 auto;
    }

    > button {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
`;

const SubmitButton = styled(ButtonLegacy)`
  @media ${DeviceSize.mobileL} {
    width: 100%;
  }
`;

export default Newsletter;
