import styled, { css, ThemedStyledProps } from 'styled-components/macro';
import { baseFontStyle } from '../basic/Text';
import { Link } from 'react-router-dom';

const BaseButton = styled.button<{ active?: boolean }>`
  ${baseFontStyle};
  font-weight: 700;
  font-size: 14px;

  color: ${({ active = true, theme }) => (active ? '#ffffff' : theme.colors.charcoal)};
  background: ${({ active = true, theme }) => (active ? theme.colors.charcoal : 'transparent')};

  border-radius: 40px;
  border: 2px solid ${({ theme }) => theme.colors.charcoal};
  cursor: pointer;
`;

/**
 * This button is marked as deprecated, since there is now a new button component added.
 * @deprecated Please use the new Button component instead.
 */
const ButtonLegacy = styled.button`
  ${baseFontStyle};
  font-family: ${(props) => props.theme.text.secondaryFont};
  color: ${(props) => props.theme.colors.m2White};
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.05px;
  border-radius: 25px;
  padding: 0 25px 0 25px;
  height: 50px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 0;
  min-width: 200px;
  align-items: center;

  background-color: ${(props) => props.theme.colors.charcoal};

  .hide-focus &:focus {
    outline: none;
  }

  :hover {
    background-color: ${(props) => !props.disabled && props.theme.colors.coal};
    color: ${(props) => !props.disabled && props.theme.colors.m2White};
    cursor: ${(props) => !props.disabled && 'pointer'};
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }

  :active {
    background-color: ${(props) => !props.disabled && props.theme.colors.blueGray};
  }

  :disabled {
    background-color: ${(props) => props.theme.colors.lightGray};
    cursor: default;
    box-shadow: none;
  }
`;

const getButtonColor = (props: ThemedStyledProps<{ disabled?: boolean }, any>) => {
  return props.disabled ? props.theme.colors.gray : props.theme.colors.charcoal;
};

const linkButtonStyles = css`
  ${baseFontStyle};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.text.secondaryFont};
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.05px;
  border-radius: 25px;
  padding: 0 25px 0 25px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 0;
  min-width: 200px;

  background-color: ${(props) => getButtonColor(props)};

  .hide-focus &:focus {
    outline: none;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.coal};
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    box-shadow: 4px 4px 4px ${(props) => props.theme.colors.shadow};
  }

  :active {
    background-color: ${(props) => props.theme.colors.black};
  }

  line-height: 50px;
`;

const LinkButton = styled.a`
  ${linkButtonStyles};
`;

const InternalLinkButton = styled(Link)`
  ${linkButtonStyles};
`;

const RadioButton = styled.button`
  height: 40px;
  width: 40px;
  display: block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const RadioButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export { BaseButton, ButtonLegacy, InternalLinkButton, LinkButton, RadioButton, RadioButtonRow };
export default ButtonLegacy;
