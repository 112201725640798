import dayjs from 'dayjs';
import { useMessageGetter } from 'react-message-context';

export const useFormattedClockTime = () => {
  const t = useMessageGetter('time');
  dayjs.locale(sessionStorage.getItem('currentLanguage') ?? 'fi');

  const formatClockTime = (time: string | undefined) =>
    time ? `${t('clock')} ${dayjs(time, 'HH:mm').format('HH.mm')}` : '';

  return { formatClockTime };
};
