import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import PaddedContent from '../housing/PaddedContent';
import { BasicText } from '../common/Text';
import { DeviceSize } from '../basic/DeviceSize';
import ReactGA from 'react-ga';
import { useMessageGetter } from 'react-message-context';
import { theme } from '../../theme';

const FooterBottomRow: FunctionComponent = () => {
  const t = useMessageGetter('footer');

  return (
    <BottomRowContainer>
      <CopyrightText>{t('copyright')}</CopyrightText>
      <FooterLinks>
        <FooterLink
          href="https://m2kodit.fi/tietosuoja/"
          onClick={() =>
            ReactGA.event({
              category: 'Quick links',
              action: 'Click external link',
              label: 'https://m2kodit.fi/tietosuoja/',
            })
          }
        >
          {`${t('dataprotection')}`}
        </FooterLink>
        <FooterLink
          href="https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/"
          onClick={() =>
            ReactGA.event({
              category: 'Quick links',
              action: 'Click external link',
              label: 'https://m2kodit.fi/kayttoehdot-ja-henkilotietosuoja/',
            })
          }
        >
          {`${t('terms')}`}
        </FooterLink>
      </FooterLinks>
    </BottomRowContainer>
  );
};

const BottomRowContainer = styled(PaddedContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  padding: 20px 0;
  gap: 16px;

  @media print {
    display: none;
  }

  @media ${DeviceSize.mobileL} {
    padding: 34px 10px;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

const CopyrightText = styled(BasicText)`
  text-align: left;

  @media ${DeviceSize.mobileL} {
    font-size: 14px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 18px;

  @media ${DeviceSize.mobileL} {
    flex-direction: column;
    text-align: left;
  }
`;

const FooterLink = styled.a`
  font-weight: 700;
  color: ${theme().colors.black};
  font-family: ${theme().text.secondaryFont};
  text-transform: uppercase;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }

  @media ${DeviceSize.mobileL} {
    font-size: 12px;
  }
`;

export default FooterBottomRow;
