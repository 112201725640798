import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { H2 } from '../forms';
import { Body2, Button, LinkButton } from '../../components/common';
import { useMessageGetter } from 'react-message-context';
import { InputField } from '../../components/common/forms';
import { ButtonContainer, Form, FormContainer } from '../layoutComponents';
import { Routes } from '../../constants/routes';
import FormError from '../../components/common/forms/FormError';
import { OneTimePasswordFormState, oneTimePasswordSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { submitRegistrationConfirmationCode } from '../../apis_kenno';

type Props = {
  username: string;
  onSuccess: () => void;
};

const OneTimePasswordStep: React.FC<Props> = ({ username, onSuccess }) => {
  const translationPrefix = 'signup';
  const t = useMessageGetter(translationPrefix);
  const [stepFailed, setStepFailed] = useState(false);
  const formMethods = useForm<OneTimePasswordFormState>({ resolver: zodResolver(oneTimePasswordSchema) });

  const onSubmit: SubmitHandler<OneTimePasswordFormState> = async (data) => {
    const { code } = data;
    try {
      await submitRegistrationConfirmationCode(code, username);
      setStepFailed(false);
      onSuccess();
    } catch (error) {
      setStepFailed(true);
    }
  };

  return (
    <FormContainer>
      <FormProvider {...formMethods}>
        <H2>{t('title')}</H2>
        <Body2>{t('codeInstructions')}</Body2>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputField
            fieldPath="code"
            placeholder={t('code')}
            translationPrefix={translationPrefix}
            type="text"
            required
            dark
          />
          {stepFailed && <FormError message={t('generalError')} />}
          <ButtonContainer>
            <Button type="submit" id="submit" primary>
              {t('signup')}
            </Button>
            <LinkButton to={Routes.SIGN_IN}>{t('cancel')}</LinkButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </FormContainer>
  );
};

export default OneTimePasswordStep;
