import { Language } from '../context/appContext';

// In our WordPress, English content blocks and pages use the slug postfix "-eng" to differentiate them.
// All page slugs are fetched directly from WordPress, along with menu items.
// However, for content blocks, the correct English slug must be generated dynamically.
// This is done by appending the "-eng" postfix to the hardcoded Finnish slug, based on the currently selected language.
export const getContentBlockSlug = (finnishSlug: string, language: string) => {
  if (language === Language.EN) return `${finnishSlug}-eng`;
  return finnishSlug;
};

export const removeLanguagePostfix = (slug: string) => {
  return slug.endsWith('-eng') ? slug.slice(0, -4) : slug;
};
