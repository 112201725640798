import dayjs from 'dayjs';
import { KennoUserState } from './context/kennoTenantContext';
import { UserState } from './context/tenantContext';

export const isSignupComplete = (user: UserState) => {
  return user && user.isLoggedIn && user.isLoaded && user.attributes.preferred_username && user.attributes.given_name;
};

export const isLoggedIn = (user: KennoUserState) => {
  return user && user.isLoggedIn && user.isLoaded;
};

export const hasExpiringAgreement = (user: UserState) => {
  return user && user.attributes.expiring_agreement && user.attributes.expiring_agreement === 'true';
};

export const depositCategories = [218, 223, 245, 260, 318];

export const sanitizePhone = (phoneNumber: string) => {
  if (!phoneNumber) {
    throw new Error('Empty phone number');
  }
  const onlyNumbers = phoneNumber.replace(/[^0-9]/g, '');
  if (onlyNumbers.length < 7 || onlyNumbers.length > 13) {
    throw new Error('Invalid phone number');
  }

  if (onlyNumbers.charAt(0) === '0') {
    return `+358${onlyNumbers.substring(1)}`;
  } else {
    return `+${onlyNumbers}`;
  }
};

export const validatePhone = (phone: string, message: string) => {
  try {
    if (!/^\+?[0-9\s]+$/.test(phone)) return message;
    sanitizePhone(phone);
    return;
  } catch {
    return message;
  }
};

export const checkPhone = (phone: string) => {
  if (!/^\+?[0-9\s]+$/.test(phone)) return false;
  return !(phone.length < 7 || phone.length > 14);
};

export const validatePhoneRegex = /^\+?[0-9\s]+$/;

export const validateEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const activeElements =
  'input:not(.modal-input), button:not(.modal-input), a:not(.modal-link), textarea, select, #mainlocalizations';

export const modalFocusableElements = '.modal-input';

export const setAttributes = (element: Element, attrs: Record<string, any>) => {
  for (const key in attrs) {
    element.setAttribute(key, attrs[key]);
  }
};

export const removeAttributes = (element: Element, attrs: Record<string, any>) => {
  for (const key in attrs) {
    element.removeAttribute(key);
  }
};

export const formatMoney = (num?: string, nullString?: string) => {
  if (num) return parseFloat(num).toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
  else return nullString;
};

export const formatDate = (dateText?: string, nullString?: string) => {
  if (dateText) {
    return new Date(Date.parse(dateText.toString())).toLocaleDateString('fi-FI');
  } else {
    return nullString;
  }
};

export const formatISODate = (date: string, showWeekDay?: boolean) => {
  if (showWeekDay) {
    return dayjs(date)
      .format('ddd D.M.YYYY')
      .replace(/^\w/, (c) => c.toUpperCase());
  }
  return dayjs(date).format('D.M.YYYY');
};

export const formatTime = (dateText: string) => {
  const date = new Date(Date.parse(dateText.toString()));
  const time = date.toLocaleTimeString('fi-FI', { minute: '2-digit', hour: '2-digit' });
  return `${time}`;
};

export const getOne4allLanguageKey = () => {
  const lang = sessionStorage.getItem('currentLanguage');
  switch (lang) {
    case 'en':
      return 'en_EN';
    default:
      return 'fi_FI';
  }
};

export const scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

export const capitalizeFirstLetter = (text: string | undefined): string => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const htmlDecode = (text: string) =>
  new DOMParser().parseFromString(text, 'text/html').documentElement.textContent;

export const replaceDotWithComma = (text?: string | null) => {
  if (!text) return '';
  return text.replace('.', ',');
};

export function formatEuros(amount: number, showDecimals = true) {
  let sign = '';

  if (amount < 0) {
    sign = '-';
    amount = -amount;
  }

  amount = Math.round(amount * 100);
  const cents = amount % 100;
  const euros = (amount - cents) / 100;

  if (showDecimals) return sign + euros + ',' + (cents + 100 + '').substr(1) + ' €';

  return sign + euros + ' €';
}

export const formatPrice = (price?: number) => {
  if (price === undefined) {
    return '-';
  }
  return price % 1 === 0 ? formatEuros(Math.abs(price), false) : formatEuros(Math.abs(price));
};

export const shouldHideFinancialInfo = () => process.env.REACT_APP_DISABLE_FINANCIAL_INFO === '1';
