import React from 'react';
import { Dropdown, DropdownProps } from '../Dropdown';
import { useMessageGetter } from 'react-message-context';

export enum RepeatOption {
  Never = 'never',
  Weekly = 'weekly',
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export const RepeatDropdown = ({ onChange, value, id, hasError }: Omit<DropdownProps, 'options' | 'width'>) => {
  const t = useMessageGetter('input.dropdown.repeatOptions');
  const options = [
    { label: t('never'), value: RepeatOption.Never },
    { label: t('weekly'), value: RepeatOption.Weekly },
    { label: t('biweekly'), value: RepeatOption.Biweekly },
    { label: t('monthly'), value: RepeatOption.Monthly },
    { label: t('yearly'), value: RepeatOption.Yearly },
  ];
  return <Dropdown width="230px" onChange={onChange} value={value} id={id} options={options} hasError={hasError} />;
};
