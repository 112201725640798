import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import Card from '../../components/common/Card';
import { theme } from '../../theme';
import styled from 'styled-components/macro';
import { DeviceSize } from '../../components/basic/DeviceSize';
import { BasicText, Body2, H6 } from '../../components/common/Text';
import { useKennoTenant } from '../../context/kennoTenantContext';
import { loadBuilding } from '../../context/kennoTenantActions';

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 10px;

  @media ${DeviceSize.desktopS} {
    display: flex;
    flex-direction: column;
  }
`;

const ContactColumn = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  p {
    margin: 0;
  }
  a {
    font-family: ${theme().text.primaryFont};
    color: black;
    line-height: 1.5;
  }
  a:hover {
    text-decoration: underline;
  }

  @media ${DeviceSize.desktopS} {
    :not(:first-of-type) {
      margin-top: 16px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 210px;

  @media ${DeviceSize.desktopS} {
    height: auto;
    margin-bottom: 12px;
  }
`;

const DisplayOnDesktop = styled(Body2)`
  display: block;
  @media ${DeviceSize.desktopM} {
    display: none;
  }
`;

const DisplayOnMobile = styled(Body2)`
  display: none;
  @media ${DeviceSize.desktopM} {
    display: block;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #000000;
  margin: 8px 0;
  width: 100%;
`;

const ContactInfo: React.FunctionComponent = () => {
  const t = useMessageGetter('');
  const [{ building }, dispatch] = useKennoTenant();

  useEffect(() => {
    loadBuilding(dispatch);
  }, [dispatch]);

  const emergencyNumber = building.maintenanceCompany?.emergencyNumber;

  return (
    <Card title={t('home.contact.title')} background={theme().colors.apila}>
      <ContactContainer>
        <ContactColumn>
          <Wrapper>
            <H6>{t('housing.contacts.customerservice')}</H6>
            <Divider />
            <BasicText>{t('housing.contacts.customerservicename')}</BasicText>
            <a
              href={`mailto: ${t('footer.housing.customerservice.email')}`}
              aria-label={t('housing.contacts.email.customerservice.aria')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.housing.customerservice.email')}
            </a>
            <a
              href={`tel: ${t('footer.housing.customerservice.phone')}`}
              aria-label={t('housing.contacts.phone.customerservice.aria')}
            >
              {`${t('housing.contacts.phonenumber')} ${t('footer.housing.customerservice.phone')}`}
            </a>
            <BasicText>{t('housing.contacts.customerserviceopen')}</BasicText>
          </Wrapper>
          <Body2>{t('housing.contacts.customerserviceInfo')}</Body2>
        </ContactColumn>
        <ContactColumn id="maintenanceContactInfo">
          <Wrapper>
            <H6>{t('housing.contacts.maintenance')}</H6>
            <Divider />
            {/*             {building && <BasicText>{building.maintenance.companyName}</BasicText>}
            {building?.maintenance.companyPhone && (
              <a
                href={`tel: ${building.maintenance.companyPhone}`}
                aria-label={t('housing.contacts.phone.maintenance.aria')}
              >
                {`${t('housing.contacts.customerservice')}: ${t('housing.contacts.phonenumber')} ${
                  building.maintenance.companyPhone
                }`}
              </a>
            )} */}
            {emergencyNumber && (
              <a href={`tel: ${emergencyNumber}`} aria-label={t('housing.contacts.emergencyphone.maintenance.aria')}>
                {`${t('housing.contacts.emergency')}: ${t('housing.contacts.phonenumber')} ${emergencyNumber}`}
              </a>
            )}
          </Wrapper>
          <Body2>{t('housing.contacts.maintenanceInfo')}</Body2>
        </ContactColumn>
        <ContactColumn>
          <Wrapper>
            <H6>{t('footer.housing.manager')}</H6>
            <Divider />
            <DisplayOnDesktop>{t('housing.contacts.descriptionLeft')}</DisplayOnDesktop>
            <DisplayOnMobile>{t('housing.contacts.descriptionAbove')}</DisplayOnMobile>
          </Wrapper>
          <Body2>{t('housing.contacts.managerInfo')}</Body2>
        </ContactColumn>
      </ContactContainer>
    </Card>
  );
};

export default ContactInfo;
